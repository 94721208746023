import * as MuiIcon from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';

export function isValidMuiIcon(
  variation: string
): variation is keyof typeof MuiIcon {
  const findResult = Object.keys(MuiIcon).find(
    (iconName) => iconName === variation
  );
  if (findResult) {
    return true;
  }
  return false;
}

export interface DynamicMaterialIconProps extends SvgIconProps {
  variation: string;
}

export const DynamicMaterialIcon = (props: DynamicMaterialIconProps) => {
  const { variation, ...iconProps } = props;

  const defaultIcon: keyof typeof MuiIcon = 'Circle';
  // Reason for disable this Lint:
  // https://github.com/import-js/eslint-plugin-import/blob/main/docs/rules/namespace.md
  // eslint-disable-next-line import/namespace
  const IconName = MuiIcon[isValidMuiIcon(variation) ? variation : defaultIcon];
  return <IconName {...iconProps} />;
};
