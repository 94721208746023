interface Qualification {
  name: string;
  status: string;
  value: string;
}

export enum OrganizationStatus {
  BEFORE_REVIEW = 'beforeReview',
  CONFIRMED = 'confirmed',
  IN_REVIEW = 'inReview',
  REJECTED = 'rejected',
}

export enum OrganizationType {
  INDIVIDUAL = '個人',
  ORGANIZATION = '法人',
}

export interface Organization {
  addressByCity?: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  branchName?: string;
  certifiedQualifications?: Qualification[];
  customFields: OrganizationCustomFields;
  description?: string;
  iconImageUri?: string;
  id: string;
  name: string;
  numberOfProjects?: number;
  ownerId?: string;
  phoneNumber: string;
  pictureUrl?: string;
  postalCode: string;
  qualifications?: Qualification[];
  size?: number;
  status?: OrganizationStatus;
  typeId?: OrganizationType;
}

export interface OrganizationCustomFields {
  access: string;
  addressByCity?: string;
  addressLine4: string;
  bookMarkedPlan?: string;
  chatContact: string;
  clinicUrl: string;
  department: string;
  electronicChartMaker: string;
  electronicCharts: string[];
  equipmentOther: string;
  equipments: string[];
  genre: string[];
  mainImages: string;
  ownerName: string;
  ownerUserId: string;
  phoneNumber: string;
}

/**
 * follow
 */
export const FollowerType = {
  ORGANIZATION: 'organization',
  PRODUCT: 'product',
  USER: 'user',
} as const;
export type FollowerTypeKey = (typeof FollowerType)[keyof typeof FollowerType];

export interface FollowCreate {
  followerId: string;
  followerType: FollowerTypeKey;
}
