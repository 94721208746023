export const PREFECTURE_OPTIONS = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
] as const;

export const BOTTOM_MENU_ITEMS = [
  // { key: 3, text: 'Tieupについて', to: '/' },
  // { key: 1, text: 'エリアから探す', to: '/areas' },
  // { key: 2, text: 'カテゴリーから探す', to: '/categories' },
  { key: 3, text: 'Tieupについて', to: 'https://f-tieup.com/customer/' },
  { key: 4, text: '運営会社', to: 'https://flabinc.com/' },
  { key: 5, text: '掲載したい店舗の方', to: 'https://f-tieup.com/shop/' },
  { key: 6, text: '利用規約', to: '/terms' },
  { key: 7, text: 'お問い合わせ', to: 'https://forms.gle/Sh65HGJqsAG2N1YL6' },
  { key: 8, text: 'プライバシーポリシー', to: '/privacy-policy' },
];

export const SIDE_BAR_JOB_MENU_ITEMS = [
  { isDisabled: false, key: 1, path: '/orders', text: '予約一覧' },
  { isDisabled: false, key: 2, path: '/chats', text: 'メッセージ一覧' },
  { isDisabled: true, key: 3, path: '/', text: '通知一覧' },
];

export const SIDE_BAR_SEARCH_MENU_ITEMS = [
  { isDisabled: false, key: 1, path: '/', text: '日付・時間から探す' },
  { isDisabled: false, key: 2, path: '/', text: '曜日・時間から探す' },
  { isDisabled: false, key: 3, path: '/areas', text: 'エリアから探す' },
  {
    isDisabled: false,
    key: 4,
    path: '/categories',
    text: 'ジャンルから探す',
  },
  { isDisabled: false, key: 5, path: '/', text: '詳細条件から探す' },
];

export const SIDE_BAR_SYSTEM_MENU_ITEMS = [
  {
    isDisabled: false,
    key: 1,
    path: 'https://f-tieup.com/customer',
    text: 'Tieupについて',
  },
  {
    isDisabled: false,
    key: 2,
    path: 'https://forms.gle/Sh65HGJqsAG2N1YL6',
    text: 'お問い合わせ',
  },
];
