import { Box, Typography, useTheme, Grid, Button } from '@mui/material';
import { isSameDay, format } from 'date-fns';
import React, { forwardRef, useState } from 'react';

interface ProductCalendarProps {
  markedDate?: string[];
  month: string;
  onChange: (newDate: string | null) => void;
  value: string | null;
}

export const ProductCalendar = forwardRef(function (
  { month, value, onChange, markedDate }: ProductCalendarProps,
  ref
) {
  const theme = useTheme();
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  // 現在の月の初日を取得
  const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);

  // 現在の月を追跡するためのState
  const [currentMonth, setCurrentMonth] = useState(currentMonthStart);

  // 前月に移動
  const goToPreviousMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1)
    );
  };

  // 次月に移動
  const goToNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
    );
  };

  // 現在の月とその月の最終日を計算
  const currentMonthDate = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth(),
    1
  );
  const currentMonthLastDate = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() + 1,
    0
  );

  /** 日付のリスト */
  const dayList: number[] = [
    ...Array.from(Array(currentMonthDate.getDay()).keys()).map(() => 0),
    ...Array.from(Array(currentMonthLastDate.getDate()).keys(), (x) => x + 1),
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="25rem"
      width="100%"
      bgcolor="white"
      ref={ref}
    >
      <Grid display="flex" alignItems="center" justifyContent="center">
        <Button onClick={goToPreviousMonth}>前月</Button>
        <Typography>{format(currentMonth, 'yyyy年MM月')}</Typography>
        <Button onClick={goToNextMonth}>次月</Button>
      </Grid>
      <Grid container alignItems="center" columns={7} spacing={0.5}>
        {['日', '月', '火', '水', '木', '金', '土'].map((item) => (
          <Grid
            item
            xs={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="3.5rem"
            key={item}
          >
            <Typography fontSize="1.2rem" color={theme.palette.grey[500]}>
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container alignItems="center" columns={7} spacing={0.5}>
        {dayList.map((day, idx) => {
          const currentDate = new Date(
            currentMonth.getFullYear(),
            currentMonth.getMonth(),
            day
          );
          const isSelect =
            day && value && isSameDay(currentDate, new Date(value));

          const isMarked =
            day &&
            markedDate &&
            markedDate.includes(format(currentDate, 'yyyy-MM-dd'));

          const isPastDate = currentDate < today;

          const isDisabled = isPastDate || !isMarked || !day;

          return (
            <Grid
              item
              xs={1}
              height="3.5rem"
              key={idx}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ cursor: isDisabled ? undefined : 'pointer' }}
              tabIndex={isDisabled ? undefined : 0}
              onClick={
                !isDisabled
                  ? async (e) => {
                      if (isPastDate) {
                        return;
                      }
                      let newValue: string[];
                      if (isSelect && value) {
                        newValue = isSameDay(currentDate, new Date(value))
                          ? []
                          : [value];
                      } else {
                        newValue = [format(currentDate, 'yyyy/M/d')];
                      }
                      const sortedNewValue = newValue.sort(
                        (a: string, b: string) => {
                          const dateA = new Date(a);
                          const dateB = new Date(b);
                          return dateA.getTime() - dateB.getTime();
                        }
                      );
                      await onChange?.(
                        sortedNewValue.length > 0 ? sortedNewValue[0] : null
                      );
                    }
                  : undefined
              }
            >
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  height="80%"
                  width="150%"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                  bgcolor={isSelect ? theme.palette.primary.main : undefined}
                >
                  <Typography
                    fontSize="1.2rem"
                    color={
                      isDisabled
                        ? theme.palette.grey[500]
                        : isSelect
                        ? theme.palette.common.white
                        : undefined
                    }
                  >
                    {/* TODO:カレンダー上に謎の'0'が表示されるので修正 */}
                    {/* {day !== '0' || day !== 0 ? day.toString() : ''} */}
                    {day ? day.toString() : ''}
                  </Typography>
                </Box>
                {isMarked && !isSelect && (
                  <Box
                    mt={0.5}
                    height="0.5rem"
                    width="0.5rem"
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <span role="img" aria-label="marked">
                      {isPastDate ? '-' : '⭕️'}
                    </span>
                  </Box>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
});
