import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Collapse,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { ReactElement, useEffect, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  getAttributes,
  getLocationTree,
  getCategoryTree,
} from '@app/adapter/catalog-service';
import {
  AttributesModal,
  ConditionProps as AttributesCondition,
  tabs as AttributesModalTabs,
} from '@app/components/Search/AttributesModal';
import {
  DateTimeModal,
  ConditionProps as DateTimeCondition,
} from '@app/components/Search/DateTimeModal';
import {
  KeywordModal,
  ConditionProps as KeywordCondition,
} from '@app/components/Search/KeywordModal';
import {
  LocationsModal,
  ConditionProps as LocationsCondition,
} from '@app/components/Search/LocationsModal';
import {
  attributesState,
  locationRegionsState,
  categoryState,
} from '@app/domain/search';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import {
  CategoryName,
  CategoryParentName,
  ProductLocationType,
  SearchCondition as Conditions,
  AttributeGroupName,
} from '@app/types/catalog';
import { getConvertTimeToJp, weeks } from '@app/utils/date';

interface SearchConditionProps {
  condition?: Conditions;
  onSearch: (conditions: Conditions) => void;
}

export function SearchCondition({
  condition,
  onSearch,
}: SearchConditionProps): ReactElement {
  const theme = useTheme();
  const navigate = useNavigate();
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const [categoriesSharedState, setCategoriesSharedState] =
    useRecoilState(categoryState);
  const [regionsSharedState, setRegionsSharedState] =
    useRecoilState(locationRegionsState);
  const [attributesSharedState, setAttributesSharedState] =
    useRecoilState(attributesState);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLocationModal, setIsLocationModal] = useState<boolean>(false);
  const [isAttributeModal, setIsAttributeModal] = useState<boolean>(false);
  const [isDateTimeModal, setIsDateTimeModal] = useState<boolean>(false);
  const [categoryName, setCategoryName] = useState<string>('');
  const [attributesModalTab, setAttributesModalTab] = useState<string>('');
  const [isKeywordModal, setIsKeywordModal] = useState<boolean>(false);

  const fetchCategories = useCallback(async () => {
    if (categoriesSharedState.length) return;
    try {
      const result = await getCategoryTree();
      setCategoriesSharedState(result.data.value);
    } catch (err) {
      setErrorSnackbarText('タイプの取得に失敗しました');
      setErrorSnackbarOpen(true);
    }
  }, [
    categoriesSharedState,
    setCategoriesSharedState,
    setErrorSnackbarText,
    setErrorSnackbarOpen,
  ]);

  const fetchLocations = useCallback(async () => {
    if (regionsSharedState.length) return;
    try {
      const result = await getLocationTree({
        type: ProductLocationType.REGION,
      });
      setRegionsSharedState(result.data.value);
    } catch (err) {
      setErrorSnackbarText('エリアの取得に失敗しました');
      setErrorSnackbarOpen(true);
    }
  }, [
    regionsSharedState,
    setErrorSnackbarOpen,
    setErrorSnackbarText,
    setRegionsSharedState,
  ]);

  const fetchAttributes = useCallback(async () => {
    if (attributesSharedState.length) return;
    try {
      const result = await getAttributes();
      setAttributesSharedState(result.data.value);
    } catch (err) {
      setErrorSnackbarText('ジャンルの取得に失敗しました');
      setErrorSnackbarOpen(true);
    }
  }, [
    attributesSharedState,
    setAttributesSharedState,
    setErrorSnackbarOpen,
    setErrorSnackbarText,
  ]);

  useEffect(() => {
    if (isOpen) {
      void fetchCategories();
      void fetchLocations();
      void fetchAttributes();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const tabName = useMemo(() => {
    return (
      categoriesSharedState
        .find((v) => v.name === CategoryParentName.JOB_TYPE)
        ?.children.find((c) => c.id === condition?.categoryId)?.name ||
      CategoryName.SPOT
    );
  }, [categoriesSharedState, condition?.categoryId]);

  const openDateTimeModal = useCallback(() => {
    setCategoryName(tabName);
    setIsDateTimeModal(true);
  }, [tabName]);

  const openAttributeModal = useCallback((tabName: string) => {
    setAttributesModalTab(tabName);
    setIsAttributeModal(true);
  }, []);

  const GenreTypeItems = useMemo(() => {
    return attributesSharedState.filter(
      (a) => a.groupName === AttributeGroupName.GENRE_TYPE
    );
  }, [attributesSharedState]);

  const workDaysLabel = useMemo(() => {
    return (
      condition?.work?.days
        ?.map((d) =>
          format(new Date(d), 'yyyy/M/d(E)', {
            locale: ja,
          })
        )
        .join() || '選択なし'
    );
  }, [condition?.work]);

  const workWeeksLabel = useMemo(() => {
    return (
      weeks
        .filter((w) => condition?.work?.weeks?.includes(w.value.toString()))
        .map((w) => w.label)
        .join() || '選択なし'
    );
  }, [condition?.work]);

  const workTimeLabel = useMemo(() => {
    const timeFrom = condition?.work?.timeFrom || '';
    const timeTo = getConvertTimeToJp(condition?.work?.timeTo || '');
    return timeFrom || timeTo
      ? [timeFrom, '~', timeTo].filter((i) => i).join('')
      : '指定なし';
  }, [condition?.work]);

  const locationsLabel = useMemo(() => {
    const labels: string[] = [];
    regionsSharedState.forEach((r) => {
      r.children?.forEach((c) => {
        if (condition?.locationIds?.includes(c.id)) {
          labels.push(c.name);
        }
      });
    });
    return labels.join() || '選択なし';
  }, [condition?.locationIds, regionsSharedState]);

  const AttributeTypesLabel = useMemo(() => {
    const labels: string[] = [];
    GenreTypeItems.forEach((i) => {
      if (condition?.attributeIds?.includes(i.id)) {
        labels.push(i.name);
      }
    });
    return labels.join() || '選択なし';
  }, [condition?.attributeIds, GenreTypeItems]);

  const searchAttributes = useCallback(
    (data?: AttributesCondition) => {
      onSearch({
        ...condition,
        attributeIds: data?.attributes || [],
        variant: {
          max: data?.max || '',
          min: data?.min || '',
          type: data?.type || '',
        },
      });
      setIsAttributeModal(false);
    },
    [condition, onSearch, setIsAttributeModal]
  );

  const searchDateTime = useCallback(
    (data?: DateTimeCondition) => {
      onSearch({
        ...condition,
        work: {
          days: data?.days || [],
          timeFrom: data?.timeFrom || '',
          timeTo: data?.timeTo || '',
          weeks: data?.weeks || [],
        },
      });
      setIsDateTimeModal(false);
    },
    [condition, onSearch, setIsDateTimeModal]
  );

  const searchLocations = useCallback(
    (data?: LocationsCondition) => {
      onSearch({
        ...condition,
        locationIds: data?.locationIds || [],
      });
      setIsLocationModal(false);
    },
    [condition, onSearch, setIsLocationModal]
  );

  const actionButton = (label: string, onClick: () => void): ReactElement => {
    return (
      <Button
        variant="outlined"
        size="small"
        sx={{ backgroundColor: 'white', minHeight: '26px' }}
        onClick={onClick}
      >
        <Typography variant="body2" color={theme.palette.text.primary}>
          {label}
        </Typography>
      </Button>
    );
  };

  const searchKeyword = useCallback(
    (data?: KeywordCondition) => {
      onSearch({
        ...condition,
        keyword: data?.keyword || '',
      });
      setIsKeywordModal(false);
    },
    [condition, onSearch, setIsKeywordModal]
  );

  return (
    <Stack>
      <Box textAlign="center" py={2}>
        <Link
          component="button"
          color={theme.palette.text.primary}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Typography display="flex">
            検索条件を変更する
            <ExpandMoreIcon />
          </Typography>
        </Link>
      </Box>
      <Collapse in={isOpen}>
        <Stack
          spacing={2}
          sx={{ backgroundColor: theme.palette.secondary.main, px: 2, py: 1 }}
        >
          <Stack spacing={1}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography component="span">
                {tabName === CategoryName.SPOT ? '日付' : '曜日'}・時間
              </Typography>
              {actionButton('変更する', openDateTimeModal)}
            </Stack>
            <Typography
              variant="body3"
              component="div"
              color={theme.palette.text.secondary}
              fontWeight={500}
            >
              {tabName === CategoryName.SPOT && (
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {workDaysLabel}
                </Box>
              )}
              <Box>曜日：{workWeeksLabel}</Box>
              <Box>時間：{workTimeLabel}</Box>
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography component="span">エリア</Typography>
              {actionButton('変更する', () => setIsLocationModal(true))}
            </Stack>
            <Typography
              variant="body3"
              component="div"
              color={theme.palette.text.secondary}
              fontWeight={500}
            >
              {locationsLabel}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography component="span">詳細条件</Typography>
              {actionButton('変更する', () =>
                openAttributeModal(AttributesModalTabs[0].value)
              )}
            </Stack>
            <Typography
              variant="body3"
              component="div"
              color={theme.palette.text.secondary}
              fontWeight={500}
            >
              <Box>ジャンル：{AttributeTypesLabel}</Box>
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography component="span">キーワード</Typography>
              {actionButton('変更する', () => setIsKeywordModal(true))}
            </Stack>
            <Typography
              variant="body3"
              component="div"
              color={theme.palette.text.secondary}
              fontWeight={500}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {condition?.keyword || '選択なし'}
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            size="small"
            sx={{
              backgroundColor: 'gainsboro',
              minHeight: '30px',
            }}
            onClick={() => navigate('/home')}
          >
            <Typography variant="body2" color={theme.palette.text.primary}>
              検索し直す（TOPへ）
            </Typography>
          </Button>
        </Stack>
      </Collapse>
      <AttributesModal
        category={categoryName}
        condition={{
          attributes: condition?.attributeIds || [],
          max: condition?.variant?.max || '',
          min: condition?.variant?.min || '',
          type: condition?.variant?.type || '',
        }}
        tabValue={attributesModalTab}
        open={isAttributeModal}
        onClose={() => setIsAttributeModal(false)}
        onClickSearch={searchAttributes}
      />
      <DateTimeModal
        category={categoryName}
        condition={condition?.work}
        open={isDateTimeModal}
        onClose={() => setIsDateTimeModal(false)}
        onClickSearch={searchDateTime}
      />
      <LocationsModal
        category={categoryName}
        condition={{ locationIds: condition?.locationIds || [] }}
        open={isLocationModal}
        onClose={() => setIsLocationModal(false)}
        onClickSearch={searchLocations}
        isLocationModal={isLocationModal}
      />
      <KeywordModal
        category={categoryName}
        condition={{ keyword: condition?.keyword || '' }}
        open={isKeywordModal}
        onClose={() => setIsKeywordModal(false)}
        onClickSearch={searchKeyword}
      />
    </Stack>
  );
}
