export interface PasswordRule {
  lowercaseChar?: 'ALLOWED' | 'REQUIRED';
  maxLength: number;
  minLength: number;
  numberChar?: 'ALLOWED' | 'REQUIRED';
  symbolChar?: 'ALLOWED' | 'REQUIRED';
  uppercaseChar?: 'ALLOWED' | 'REQUIRED';
}

export const DEFAULT_PASSWORD_RULE: PasswordRule = {
  lowercaseChar: 'REQUIRED',
  maxLength: 24,
  minLength: 8,
  numberChar: 'REQUIRED',
  uppercaseChar: 'ALLOWED',
};

/**
 * return regex for password validation
 * @param passwordRule password rule options
 * @returns regex for password validation
 */
export function generatePasswordPattern(
  passwordRule: PasswordRule = DEFAULT_PASSWORD_RULE
): RegExp {
  const { uppercaseChar, lowercaseChar, numberChar, symbolChar } = passwordRule;

  return new RegExp(
    `^${uppercaseChar === 'REQUIRED' ? '(?=.*[A-Z])' : ''}${
      lowercaseChar === 'REQUIRED' ? '(?=.*[a-z])' : ''
    }${numberChar === 'REQUIRED' ? '(?=.*[0-9])' : ''}${
      symbolChar === 'REQUIRED' ? '(?=.*[.?/-])' : ''
    }[${uppercaseChar ? 'A-Z' : ''}${lowercaseChar ? 'a-z' : ''}${
      numberChar ? '0-9' : ''
    }${symbolChar ? '.?/-_' : ''}]{${passwordRule.minLength},${
      passwordRule.maxLength
    }}$`,
    'g'
  );
}

export function unescapeHtml(value: string): string {
  return value
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&');
}

export function unescapeHtmlAndConvertNewLinesToHtml(
  value: string | undefined
): string {
  if (!value) {
    return '';
  }
  const unescapedValue = unescapeHtml(value);
  return unescapedValue.replace(/\\r\\n|\\r|\\n|\r\n|\n|\r/g, '\n');
}
