import { AxiosPromise, CanceledError } from 'axios';

import { authInstance } from '@app/adapter/axios';
import { generateFingerPrint } from '@app/domain/fingerprint';

/**
 * Auth user via social network
 * @param type
 * @returns
 */
export function authViaSocialNetwork(
  type: string
): AxiosPromise<{ redirectUrl: string }> {
  return authInstance.get(`/auth/${type}`).catch((error) => {
    if (error instanceof CanceledError) {
      throw error;
    } else if ('message' in error.response.data) {
      throw new Error(error.response?.data.message);
    } else {
      throw new Error(error.message);
    }
  });
}

/**
 * Register user use user-service
 * @param code
 * @param socialType
 * @returns
 */
export async function sendCodeCallback(
  code: string | null,
  socialType: string | undefined
): Promise<AxiosPromise> {
  const fingerprint = await generateFingerPrint();
  return authInstance
    .get(`auth/${socialType}/callback`, { params: { code, fingerprint } })
    .catch((error) => {
      if (error instanceof CanceledError) {
        throw error;
      } else if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
