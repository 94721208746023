import { AxiosPromise, AxiosResponse } from 'axios';
import _ from 'lodash';

import { chatInstance } from '@app/adapter/axios';
import {
  Attachment,
  AttachmentCreation,
  MessageCategory,
  MessageCategoryKey,
  MessageCreation,
  MessageReadStatusKey,
  MessageSenderType,
  MessageType,
  MessageTypeIdKey,
  SubscriptionCreationRequest,
  SubscriptionCreationResponse,
  SubscriptionResponse,
  Template,
  Topic,
  TopicList,
  TopicOwnerType,
  PaginatedTemplates,
} from '@app/types/chat';
import { Pagination } from '@app/types/common';
import { getAuthorizationHeader } from '@app/utils/authorization';
import { getUploadedFileUrl } from '@app/utils/fileUpload';

const defaultHeaders = (token: string, fingerPrint: string) => ({
  'x-nb-fingerprint': fingerPrint,
  ...getAuthorizationHeader(token),
});

/**
 * Topic
 */
export function createPrivateChannel(
  userIds: string[],
  fingerPrint: string,
  token: string
): AxiosPromise<Topic> {
  return chatInstance.post<Topic>(
    'topics',
    {
      name: _.join(_.sortBy(userIds), '-'),
      ownerIds: userIds,
      ownerType: 'user' as const,
      typeId: 'private',
    },
    {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    }
  );
}

export async function createNewTopic(
  token: string,
  fingerPrint: string,
  userId: string,
  supplyUserId: string,
  name: string
): Promise<AxiosResponse<Topic>> {
  const ownerIds = [userId, supplyUserId];
  const requestBody = {
    app: 'user',
    isPublic: false,
    name,
    ownerIds,
    ownerType: TopicOwnerType.USER,
    typeId: 'channel',
  };

  return chatInstance.post('topics', requestBody, {
    headers: defaultHeaders(token, fingerPrint),
  });
}

export async function getPrivateTopic(
  userId: string,
  orgUserId: string,
  fingerPrint: string,
  token: string
): Promise<Topic | undefined> {
  const urlParams = [
    [
      '$filter',
      `typeId eq 'private' and ownerIds eq '${userId}' and ownerIds eq '${orgUserId}'`,
    ],
  ];
  const privateTopicResponse = await chatInstance.get<Pagination<Topic[]>>(
    `users/${userId}/topics?${new URLSearchParams(urlParams).toString()}`,
    {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    }
  );
  if (privateTopicResponse.data.count === 0) {
    return undefined;
  }
  return privateTopicResponse.data.value[0];
}

export function getUserChatTopicListWithFilter(
  token: string,
  fingerPrint: string,
  userId: string,
  options?: {
    expand?: string;
    limit?: number;
    nextLink?: string;
    orderBy?: string;
  }
): AxiosPromise<Pagination<Topic[]>> {
  const requestConfig = {
    headers: {
      'x-nb-fingerprint': fingerPrint,
      ...getAuthorizationHeader(token),
    },
  };
  if (options?.nextLink) {
    return chatInstance.get(options?.nextLink, requestConfig);
  }
  const urlParams = [['$top', String(options?.limit || 20)]];

  if (options?.orderBy) {
    urlParams.push(['$orderBy', options.orderBy]);
  }
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  return chatInstance.get(
    `users/${userId}/topics?${new URLSearchParams(urlParams).toString()}`,
    requestConfig
  );
}

/**
 * Subscription
 */
export const createSubscription = async (
  token: string,
  fingerPrint: string,
  requestData: SubscriptionCreationRequest
): Promise<SubscriptionCreationResponse> => {
  try {
    const response = await chatInstance.post<SubscriptionCreationResponse>(
      'subscriptions',
      requestData,
      {
        headers: defaultHeaders(token, fingerPrint),
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

export const getTopicSubscriptions = async (
  token: string,
  fingerPrint: string,
  topicId: string
): Promise<SubscriptionResponse> => {
  try {
    const response = await chatInstance.get(
      `topics/${topicId}/subscriptions?$expand=user`,
      {
        headers: {
          'x-nb-fingerprint': fingerPrint,
          ...getAuthorizationHeader(token),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching topic subscriptions:', error);
    throw error;
  }
};

/**
 * Message
 */
export function postChatMessage(
  token: string,
  fingerPrint: string,
  userId: string,
  option: MessageCreation
) {
  const payload = {
    ...option,
    category: MessageCategory.DEMAND,
    messageType: MessageType.CHANNEL,
    senderId: userId,
    senderType: MessageSenderType.NORMAL_USER,
  };
  return chatInstance.post('messages', payload, {
    headers: defaultHeaders(token, fingerPrint),
  });
}

export function getMessagesByTopic(
  token: string,
  fingerPrint: string,
  topicId: string,
  options?: {
    expand?: string;
    limit?: number;
    nextLink?: string;
    orderBy?: string;
  }
): AxiosPromise<TopicList> {
  const requestConfig = {
    headers: defaultHeaders(token, fingerPrint),
  };
  if (options?.nextLink) {
    return chatInstance.get(options?.nextLink, requestConfig);
  }
  const urlParams = [['$top', String(options?.limit || 5)]];

  if (options?.orderBy) {
    urlParams.push(['$orderBy', options.orderBy]);
  }
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  return chatInstance.get(
    `topics/${topicId}/messages?${new URLSearchParams(urlParams).toString()}`,
    requestConfig
  );
}

export function getAllMessagesToOneUser(
  token: string,
  fingerPrint: string,
  userId: string,
  options?: {
    category?: MessageCategoryKey;
    expand?: string;
    limit?: number;
    nextLink?: string;
    orderBy?: string;
    readStatus?: MessageReadStatusKey;
  }
): AxiosPromise<TopicList> {
  const requestConfig = {
    headers: defaultHeaders(token, fingerPrint),
  };
  if (options?.nextLink) {
    return chatInstance.get(options.nextLink, requestConfig);
  }

  const urlParams = [['$top', String(options?.limit ?? 50)]];

  const filterParams = [];
  if (options?.category) {
    filterParams.push(`category eq '${options.category}'`);
  }
  if (options?.readStatus) {
    filterParams.push(`readStatus eq '${options.readStatus}'`);
  }

  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  if (options?.orderBy) {
    urlParams.push(['$orderBy', options.orderBy]);
  }

  return chatInstance.get(
    `users/${userId}/messages?${new URLSearchParams(urlParams).toString()}`,
    requestConfig
  );
}

export function deleteMessage(
  token: string,
  fingerPrint: string,
  messageId: string,
  option?: {
    content?: string;
    typeId?: MessageTypeIdKey;
  }
): AxiosPromise<unknown> {
  return chatInstance.patch(`messages/${messageId}`, option, {
    headers: defaultHeaders(token, fingerPrint),
  });
}

export function markMessageAsRead(
  token: string,
  fingerPrint: string,
  messageId: string,
  userId: string
): AxiosPromise<unknown> {
  const requestData = {
    messageId,
    userId,
  };
  return chatInstance.post('messages:mark-read', requestData, {
    headers: defaultHeaders(token, fingerPrint),
  });
}

/**
 * Message Template
 */
export function getMessageTemplatesById(
  token: string,
  fingerPrint: string,
  messageTemplateId: string
): AxiosPromise<Template> {
  return chatInstance.get(`message-templates/${messageTemplateId}`, {
    headers: defaultHeaders(token, fingerPrint),
  });
}

export function postMessageTemplates(
  token: string,
  fingerPrint: string,
  title: string,
  content: string,
  orgId: string
): AxiosPromise<PaginatedTemplates> {
  return chatInstance.post(
    'message-templates',
    {
      content,
      orgId,
      permissions: [],
      title,
    },
    {
      headers: defaultHeaders(token, fingerPrint),
    }
  );
}

export function updateMessageTemplates(
  token: string,
  fingerPrint: string,
  messageTemplateId: string,
  title: string,
  content: string
): AxiosPromise<PaginatedTemplates> {
  return chatInstance.patch(
    `message-templates/${messageTemplateId}`,
    {
      content,
      title,
    },
    {
      headers: defaultHeaders(token, fingerPrint),
    }
  );
}

export function deleteMessageTemplate(
  token: string,
  fingerPrint: string,
  messageTemplateId: string
): AxiosPromise<PaginatedTemplates> {
  return chatInstance.delete(`message-templates/${messageTemplateId}`, {
    headers: defaultHeaders(token, fingerPrint),
  });
}

/**
 * attachment
 */
export function postAttachment(
  topicId: string,
  token: string,
  fingerPrint: string,
  payload: AttachmentCreation
): AxiosPromise<{ id: string; url: string }> {
  return chatInstance.post(`topics/${topicId}/attachments`, payload, {
    headers: {
      'x-nb-fingerprint': fingerPrint,
      ...getAuthorizationHeader(token),
    },
  });
}

export function deleteAttachment(
  topicId: string,
  token: string,
  fingerPrint: string,
  id: string
): AxiosPromise<Pagination<Attachment[]>> {
  return chatInstance
    .delete(`topics/${topicId}/attachments/${id}`, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export async function uploadBlob(
  topicId: string,
  token: string,
  fingerPrint: string,
  file: Blob
): Promise<Attachment> {
  if (!file) {
    throw new Error('blob is not defined');
  }
  const signedUrl = await getTopicUploadSignedUrl(
    topicId,
    token,
    fingerPrint,
    file
  );
  const uploadedUrl = await getUploadedFileUrl(file, signedUrl);

  const objectId = new URL(uploadedUrl).pathname
    .split('?')[0]
    .replace(/^\/[^/]+\//, '');
  if (!objectId) {
    throw new Error('objectId is not undefined, upload may got error');
  }

  const objectSplits = objectId?.split('.');
  let extension = 'jpg';
  if (objectSplits && objectSplits.length > 1) {
    extension = objectSplits[objectSplits.length - 1];
  }
  const response = await postAttachment(topicId, token, fingerPrint, {
    objectId,
    type: extension,
  });
  return response.data;
}

export function getTopicUploadSignedUrl(
  topicId: string,
  token: string,
  fingerPrint: string,
  blob: Blob
): Promise<string> {
  return chatInstance
    .get(
      `topics/${topicId}/attachment-upload-url?contentLength=${blob.size}&contentType=${blob.type}`,
      {
        headers: {
          'x-nb-fingerprint': fingerPrint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .then((response) => response.data);
}
