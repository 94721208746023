import { Box, Stack, Tab, Tabs } from '@mui/material';
import { format } from 'date-fns';
import { isEmpty, get } from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getAttributes } from '@app/adapter/catalog-service';
import { OrderList } from '@app/components/Order/OrderList';
import { SearchCondition } from '@app/components/Order/SearchCondition';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { ScrollThreshold } from '@app/components/Shared/ScrollThreshold';
import { TopNavPortal } from '@app/components/TopNav/TopNavPortal';
import { userAuthInfoSelector } from '@app/domain/app';
import { getUserOrders } from '@app/domain/network-actions';
import { attributesState } from '@app/domain/search';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { LoadableState } from '@app/types/common';
import { Order, OrderStatus } from '@app/types/order';

const tabs = [
  { label: '予約履歴', value: 'history' },
  { label: '来店予定', value: 'beforeWork' },
  { label: '投稿待ち', value: 'afterWork' },
  { label: '過去の来店', value: 'result' },
] as const;

export function Orders(): ReactElement {
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const [attributesSharedState, setAttributesSharedState] =
    useRecoilState(attributesState);
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const [nextLink, setNextLink] = useState<string>('');
  const [orders, setOrders] = useState<Order[]>([]);
  const [searchCondition, setSearchCondition] = useState<{
    addCancel: boolean;
    month: string;
    orderBy: string;
    tab: string;
  }>({
    addCancel: false,
    month: format(new Date(), 'yyyy-MM'),
    orderBy: 'createdAt desc',
    tab: tabs[0].value,
  });

  const isLoading = useMemo(() => {
    return loadable === LoadableState.LOADING;
  }, [loadable]);

  const fetchOrders = useCallback(
    async (options?: { '@nextLink'?: string }) => {
      if (!authInfo?.accessToken || isLoading) {
        return;
      }
      setLoadable(LoadableState.LOADING);
      try {
        const userId = get(authInfo, 'userId', '');

        const option = {
          ...options,
          isAfterWork: false,
          isBeforeWork: false,
          isResult: false,
          orderBy: '',
          statuses: Array<string>(),
          workResultMonth: '',
        };
        if (searchCondition.tab === 'history') {
          option.statuses.push(OrderStatus.PENDING);
        } else if (searchCondition.tab === 'beforeWork') {
          option.isBeforeWork = true;
        } else if (searchCondition.tab === 'afterWork') {
          option.isAfterWork = true;
        } else if (searchCondition.tab === 'result') {
          option.isResult = true;
          // option.workResultMonth = searchCondition.month;
        }

        if (['history', 'beforeWork'].includes(searchCondition.tab)) {
          option.orderBy = searchCondition.orderBy;
          if (searchCondition.addCancel) {
            option.statuses.push(OrderStatus.CANCELED);
          }
        }

        const data = await getUserOrders(
          authInfo.accessToken,
          userId,
          option
        ).then((v) => v.data);
        setOrders(
          get(options, '@nextLink') ? [...orders, ...data.value] : data.value
        );
        setNextLink(data['@nextLink']);
        setLoadable(LoadableState.HAS_VALUE);
      } catch (e) {
        setLoadable(LoadableState.HAS_ERROR);
        setErrorSnackbarText('予約情報が取得できませんでした');
        setErrorSnackbarOpen(true);
      }
    },
    [
      authInfo,
      orders,
      isLoading,
      searchCondition,
      setErrorSnackbarOpen,
      setErrorSnackbarText,
    ]
  );

  const fetchAttributes = useCallback(async () => {
    if (attributesSharedState.length) return;
    try {
      const result = await getAttributes();
      setAttributesSharedState(result.data.value);
    } catch (err) {
      setErrorSnackbarText('ジャンルの取得に失敗しました');
      setErrorSnackbarOpen(true);
    }
  }, [
    setAttributesSharedState,
    attributesSharedState,
    setErrorSnackbarText,
    setErrorSnackbarOpen,
  ]);

  useEffect(() => {
    const execute = async () => {
      await fetchOrders();
    };
    void execute();
    void fetchAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition]);

  const handleScrollThresholdReached = useCallback(async () => {
    if (!nextLink) {
      return;
    }
    await fetchOrders({
      '@nextLink': nextLink,
    });
  }, [fetchOrders, nextLink]);
  const changeSearchCondition = useCallback(
    (key: string, value: string | boolean) => {
      setSearchCondition({ ...searchCondition, [key]: value });
    },
    [searchCondition]
  );
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, value: string) => {
      changeSearchCondition('tab', value);
    },
    [changeSearchCondition]
  );

  return (
    <Box>
      <TopNavPortal title="予約一覧" />
      <Stack spacing={3} p={2}>
        <Tabs
          value={searchCondition.tab}
          onChange={handleChangeTab}
          variant="fullWidth"
        >
          {tabs.map(({ label, value }, index) => (
            <Tab key={index} label={label} value={value} disabled={isLoading} />
          ))}
        </Tabs>
        {searchCondition.tab !== 'result' && (
          <SearchCondition
            filter={searchCondition}
            disabled={isLoading}
            onChange={changeSearchCondition}
          />
        )}
      </Stack>
      <Box>
        <OrderList items={orders} attributes={attributesSharedState} />
        <ScrollThreshold
          disabled={isEmpty(nextLink)}
          thresholdReached={handleScrollThresholdReached}
        />
      </Box>
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
    </Box>
  );
}
