import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { deleteAttachment, deleteMessage } from '@app/adapter/chat-service';
import { userAuthInfoSelector } from '@app/domain/app';
import { Message, MessageCategory, MessageTypeId } from '@app/types/chat';
import { jsonParse } from '@app/utils/format';

type PopoverPosition = { left: number; top: number } | undefined;

export const useDeleteLogic = (
  chatTopicMessages: Message[],
  setChatTopicMessages: (messages: Message[]) => void
) => {
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const [messageToDelete, setMessageToDelete] = useState<string | null>(null);
  const [anchorPosition, setAnchorPosition] =
    useState<PopoverPosition>(undefined);

  let longPressTimer: NodeJS.Timeout;

  const handleTouchStart = (event: React.TouchEvent, messageId: string) => {
    longPressTimer = setTimeout(() => {
      handleContextMenu(event, messageId);
    }, 500);
  };

  const handleContextMenu = (
    event: React.MouseEvent | React.TouchEvent,
    messageId: string
  ) => {
    event.preventDefault();
    let clientX = 0;
    let clientY = 0;

    if ('clientX' in event) {
      clientX = event.clientX;
      clientY = event.clientY;
    } else {
      const touch = event.touches[0] || event.changedTouches[0];
      clientX = touch.clientX;
      clientY = touch.clientY;
    }

    setAnchorPosition({
      left: clientX,
      top: clientY,
    });
    setMessageToDelete(messageId);
  };

  const handleTouchEnd = () => {
    clearTimeout(longPressTimer);
  };

  const handleCloseContextMenu = () => {
    setAnchorPosition(undefined);
  };

  const handleDeleteMessage = async (messageId: string) => {
    if (!authInfo) return;
    try {
      const targetMessage = chatTopicMessages.find(
        (message) => message.id === messageId
      );

      if (targetMessage && targetMessage.category === MessageCategory.DEMAND) {
        const payload = {
          content: 'メッセージは削除されました',
          typeId: MessageTypeId.MESSAGE,
        };
        await deleteMessage(
          authInfo.accessToken,
          authInfo.fingerPrint,
          messageId,
          payload
        );

        const updatedMessages = chatTopicMessages.map((message) => {
          return message.id === messageId
            ? { ...message, ...payload }
            : message;
        });
        setChatTopicMessages(updatedMessages);

        if (
          targetMessage.typeId === MessageTypeId.IMAGE ||
          targetMessage.typeId === MessageTypeId.FILE
        ) {
          await deleteAttachment(
            targetMessage.topicId,
            authInfo.accessToken,
            authInfo.fingerPrint,
            jsonParse(targetMessage.title).id
          );
        }
      } else {
        console.warn(
          "The message is not of category 'demand' and will not be deleted."
        );
      }
    } catch (error) {
      console.error('Failed to delete the message:', error);
    }
  };

  return {
    anchorPosition,
    handleCloseContextMenu,
    handleContextMenu,
    handleDeleteMessage,
    handleTouchEnd,
    handleTouchStart,
    messageToDelete,
    setMessageToDelete,
  };
};
