import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Container,
  Stack,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Button,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { resetPassword } from '@app/adapter/user-service';
import { getVerifyToken } from '@app/domain/network-actions';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
  errorSnackbarSeverityState,
} from '@app/domain/top-nav';
import { generatePasswordPattern } from '@app/utils/pattern';

type ResetPasswordProps = {
  onSubmitCallBack: (newPassword: string) => Promise<void>;
  token: string;
};

interface ResetPasswordSchema {
  passwords: {
    confirmPassword: string;
    password: string;
  };
}

function ResetPassword({ token, onSubmitCallBack }: ResetPasswordProps) {
  const theme = useTheme();
  const { control, handleSubmit, formState } = useForm<ResetPasswordSchema>({
    defaultValues: { passwords: { confirmPassword: '', password: '' } },
    mode: 'all',
    resolver: zodResolver(
      z.object({
        passwords: z
          .object({
            confirmPassword: z
              .string()
              .min(
                8,
                'パスワードは記号を除く、英数字含む8文字以上を入力してください'
              )
              .max(24, '24文字以下のパスワードを入力してください')
              .regex(generatePasswordPattern(), {
                message:
                  '少なくとも1文字以上の小文字英字、数字が必要です。許可されている文字は、半角英数字です',
              }),
            password: z
              .string()
              .min(
                8,
                'パスワードは記号を除く、英数字含む8文字以上を入力してください'
              )
              .max(24, '24文字以下のパスワードを入力してください')
              .regex(generatePasswordPattern(), {
                message:
                  '少なくとも1文字以上の小文字英字、数字が必要です。許可されている文字は、半角英数字です',
              }),
          })
          .refine(
            ({ password, confirmPassword }) => password === confirmPassword,
            {
              message: 'パスワードが一致しません',
              path: ['confirmPassword'],
            }
          ),
      })
    ),
  });
  const onSubmit = useCallback(
    async ({ passwords }: ResetPasswordSchema) => {
      await onSubmitCallBack(passwords.password);
    },
    [onSubmitCallBack]
  );

  return token ? (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ mt: 4, pb: 2, textAlign: 'center' }}>
            <Typography fontWeight={600} variant="h4">
              パスワード再設定
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ py: 2 }}>
            <Typography>新しいパスワードを入力してください。</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={600}
                >
                  新しいパスワード
                </Typography>
              </FormLabel>
              <Controller
                name="passwords.password"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="password"
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="パスワードを入力"
                  />
                )}
              />
              <Typography
                sx={{ color: theme.palette.grey[600], textAlign: 'left' }}
              >
                8文字以上、英数字含む（記号を除く）
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={600}
                >
                  新しいパスワードの確認
                </Typography>
              </FormLabel>
              <Controller
                name="passwords.confirmPassword"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="password"
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="パスワードを再入力"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              color="orange"
              variant="contained"
              disabled={!formState.isValid || formState.isSubmitting}
              fullWidth
            >
              送信
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  ) : null;
}

function WaitingReset() {
  return (
    <Container>
      <Box sx={{ mt: 2 }}>
        <Stack>
          <Box sx={{ mt: 5, mx: 2, textAlign: 'center' }}>
            <Typography fontWeight={600} variant="h4">
              パスワード再設定手続きのメールをお送りしました
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography sx={{ mb: 4 }}>
              ご登録メールアドレス宛にパスワード再発行手続きのメールをお送りしました。
            </Typography>
            <Typography>
              メール本文に記述されているURLをクリックして頂き、パスワード再設定手続きを行なってください。
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
}

export function WaitingForResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setErrorSnackbarSeverity = useSetRecoilState(
    errorSnackbarSeverityState
  );
  const onSubmitCallBack = useCallback(
    async (newPassword: string) => {
      const token = getVerifyToken();
      await resetPassword(newPassword, token)
        .then((data) => {
          if (data.status !== 204) {
            setErrorSnackbarSeverity('error');
            setErrorSnackbarText('パスワードの登録に失敗しました');
            setErrorSnackbarOpen(true);
            return;
          }
          setErrorSnackbarSeverity('success');
          setErrorSnackbarText('パスワードを設定しました');
          setErrorSnackbarOpen(true);
          navigate('/login');
        })
        .catch(() => {
          setErrorSnackbarSeverity('error');
          setErrorSnackbarText('パスワードの登録に失敗しました');
          setErrorSnackbarOpen(true);
        });
    },
    [
      navigate,
      setErrorSnackbarOpen,
      setErrorSnackbarSeverity,
      setErrorSnackbarText,
    ]
  );
  const token = getVerifyToken();
  return location.state?.waiting && _.isEmpty(token) ? (
    <WaitingReset />
  ) : (
    <ResetPassword token={token} onSubmitCallBack={onSubmitCallBack} />
  );
}
