/*
    localStorage, sessionStorage を使う場合はここに key を追加
    重複しないように一元管理
  */
export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  AFTER_LOGIN_ROUTE: 'afterLoginRoute',

  // 予約
  FINGERPRINT: 'fingerprint',
  REFRESH_TOKEN: 'refreshToken',
  USER_ID: 'userId',
};

export const socialMediaOptions = [
  'Instagram',
  'Facebook',
  'X（旧Twitter）',
  'TikTok',
  'Google',
] as const;
