import { Tab, Tabs, useTheme } from '@mui/material';
import { ReactElement, useCallback, useMemo } from 'react';

import { Category, CategoryName } from '@app/types/catalog';

const tabs = [
  { label: 'レストラン', value: CategoryName.SPOT },
  // MEMO:ビューティー未対応のため非表示
  // { label: 'ビューティー', value: CategoryName.PART_TIME },
] as const;

interface SearchTypeTabProps {
  items: Category[];
  onChangeTab: (selectedTab: string) => void;
  value?: string | null;
}

export function SearchTypeTab({
  items,
  onChangeTab,
  value,
}: SearchTypeTabProps): ReactElement {
  const theme = useTheme();
  const selected = useMemo(() => {
    if (tabs.some((t) => t.value === value)) {
      return value;
    }
    return tabs[0].value;
  }, [value]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, selectedTab: string) => {
      onChangeTab(selectedTab);
    },
    [onChangeTab]
  );

  return (
    <Tabs
      value={selected}
      onChange={handleChange}
      variant="fullWidth"
      indicatorColor="secondary"
      textColor="primary"
      sx={{
        '& .MuiTab-textColorPrimary.Mui-selected': {
          color: theme.palette.neutral.green,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: theme.palette.neutral.green,
        },
      }}
    >
      {tabs.map(({ label, value }, index) => (
        <Tab key={index} label={label} value={value} />
      ))}
    </Tabs>
  );
}
