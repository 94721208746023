// src/utils/validators.ts
import { SocialPlatforms, socialMediaDomains } from '@app/types/user';
import { isValidUrlFormat } from '@app/utils/user';

const validateSocialMediaUrls = (
  urls: string[],
  selectedPlatforms: SocialPlatforms[]
) => {
  if (!selectedPlatforms || selectedPlatforms.length === 0) {
    return '利用SNSを選択してください。';
  }

  const validDomains: string[] = selectedPlatforms.reduce((acc, platform) => {
    const domains = socialMediaDomains[platform] || [];
    return acc.concat(domains);
  }, [] as string[]);

  const isAnyDomainPresent = selectedPlatforms.every((platform) => {
    const domains = socialMediaDomains[platform] || [];

    return domains.some((domain) =>
      urls.some((url) => isValidUrlFormat(url, domain))
    );
  });

  if (!isAnyDomainPresent) {
    return '入力されたURLに選択したSNSのドメインがありません、または形式が正しくありません。';
  }

  const isAllValid = urls.every((url) =>
    validDomains.some((domain) => isValidUrlFormat(url, domain))
  );

  return isAllValid
    ? ''
    : '入力されたURLが選択したSNSのドメインと一致しないか、形式が正しくありません。';
};

const getSocialPlatformKey = (value: string): SocialPlatforms | undefined => {
  const entries = Object.entries(SocialPlatforms).find(
    ([, val]) => val === value
  );
  return entries
    ? SocialPlatforms[entries[0] as keyof typeof SocialPlatforms]
    : undefined;
};

export const getValidateSocialMediaUrlsMessage = (
  urls: string[],
  selectedPlatforms: string[]
): string => {
  // 例: URLが選択されたプラットフォームに対応しているかどうかをチェック
  const selectedPlatformsEnum = selectedPlatforms
    .map((platform) => getSocialPlatformKey(platform))
    .filter((x): x is SocialPlatforms => x !== undefined);
  const validationMessage = validateSocialMediaUrls(
    urls,
    selectedPlatformsEnum
  );

  return validationMessage;
};
