import _ from 'lodash';
import { /* IO, */ Socket } from 'socket.io-client';

import { Message } from '../types/chat';

// import { getAuthorizationHeader } from '@app/utils/authorization';

// const { REACT_APP_CHAT_SERVICE: CHAT_SERVICE } = process.env;

// FIXME: improve the timing of chat client
export class ChatClient {
  socket: Socket | undefined;
  private static instance: ChatClient;
  private initialized: boolean;
  private constructor() {
    this.initialized = false;
  }
  static getInstance() {
    if (!ChatClient.instance) {
      ChatClient.instance = new ChatClient();
    }
    return ChatClient.instance;
  }

  async init(token: string, fingerprint: string) {
    if (this.initialized) {
      return;
    }
    if (!token || !fingerprint) {
      //logger.warn('token or fingerprint are not set');
      return false;
    }
    // this.socket = IO(`${CHAT_SERVICE}chats`, {
    //   query: {
    //     'x-nb-fingerprint': fingerprint,
    //     ...getAuthorizationHeader(token),
    //   },
    //   transports: ['websocket'],
    // });
    this.initialized = true;
    return true;
  }

  isConnected() {
    return this.socket && this.socket.connected;
  }
  isReady() {
    return !_.isNil(this.socket);
  }
  disconnect() {
    this.socket?.removeAllListeners();
    this.socket?.disconnect();
    this.socket = undefined;
    this.initialized = false;
  }

  setHandler(key: string, handler: (...args: unknown[]) => void) {
    if (!this.isReady()) {
      return;
    }
    this.socket?.on(key, handler);
  }
  emit(key: string, payload: unknown) {
    this.socket?.emit(key, payload);
  }
}

export const chatClient = ChatClient.getInstance();

export function attachListeners(
  chatClient: ChatClient,
  callback: (message: Message) => Promise<void>
) {
  chatClient.setHandler('message', async (notification: unknown) => {
    await callback(notification as Message);
  });
}

export function subscribeToStream(chatClient: ChatClient, userId: string) {
  chatClient.emit('subscribe', { userId });
}
