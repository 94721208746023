import { FormLabel, Typography } from '@mui/material';
import { ReactElement } from 'react';

interface FormRequiredLabelProps {
  label: string;
  require?: boolean;
}

const FormRequireLabel = ({
  label,
  require,
}: FormRequiredLabelProps): ReactElement => {
  return (
    <FormLabel>
      <Typography variant="body2" fontWeight={500}>
        {label}
        {require && (
          <Typography component="span" color="error">
            *
          </Typography>
        )}
      </Typography>
    </FormLabel>
  );
};

export { FormRequireLabel };
