import { Container, Grid, Stack } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  getAttributes,
  getCategoryTree,
  getLocationTree,
} from '@app/adapter/catalog-service';
import { CategoryIconButton } from '@app/components/Category/CategoryIconButton';
import { HomeCarousel } from '@app/components/Home/HomeCarousel';
import {
  ConditionProps as DateTimeConditionProps,
  DateTimeModal,
} from '@app/components/Search/DateTimeModal';
import {
  ConditionProps as LocationsConditionProps,
  LocationsModal,
} from '@app/components/Search/LocationsModal';
import { SearchBox } from '@app/components/Search/SearchBox';
import { SearchTypeTab } from '@app/components/Search/SearchTypeTab';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { Loading } from '@app/components/Shared/Loading';
import { TopNavPortal } from '@app/components/TopNav/TopNavPortal';
import { userAuthInfoSelector } from '@app/domain/app';
import { genresAtom } from '@app/domain/catalog';
import {
  categoryState,
  locationRegionsState,
  pickupAttributesState,
  searchConditionState,
} from '@app/domain/search';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import {
  CategoryName,
  CategoryParentName,
  Genre,
  ProductLocationType,
} from '@app/types/catalog';
import { getSearchResultUrl } from '@app/utils/catalog';

interface GenreConfiguration {
  [key: string]: {
    iconType: string;
    iconValue: string;
  };
}

export function Home(): ReactElement {
  const navigate = useNavigate();
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setConditionState = useSetRecoilState(searchConditionState);
  const [categoriesSharedState, setCategoriesSharedState] =
    useRecoilState(categoryState);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [isDateTimeModal, setIsDateTimeModal] = useState(false);
  const [tab, setTab] = useState<string>(CategoryName.SPOT);
  const [pickupAttributesSharedState, setPickupAttributesSharedState] =
    useRecoilState(pickupAttributesState);
  const [regionsSharedState, setRegionsSharedState] =
    useRecoilState(locationRegionsState);
  const [genres, setGenres] = useRecoilState(genresAtom);
  const [isLoading, setIsLoading] = useState(true);

  // すべてのfetch処理が終了したらisLoadingをfalseに
  const checkAllFetchesCompleted = useCallback(() => {
    if (
      !categoriesSharedState.length ||
      !pickupAttributesSharedState.length ||
      !regionsSharedState.length
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    categoriesSharedState.length,
    pickupAttributesSharedState.length,
    regionsSharedState.length,
  ]);

  const onChangeTab = useCallback(
    (selectedTab: string) => {
      setTab(selectedTab);
    },
    [setTab]
  );

  const tabId = useMemo(() => {
    return (
      categoriesSharedState
        .find((v) => v.name === CategoryParentName.JOB_TYPE)
        ?.children.find((c) => c.name === tab)?.id || ''
    );
  }, [categoriesSharedState, tab]);

  const fetchLocations = useCallback(async () => {
    if (regionsSharedState.length) {
      checkAllFetchesCompleted();
      return;
    }
    setIsLoading(true);
    try {
      const result = await getLocationTree({
        type: ProductLocationType.REGION,
      });
      setRegionsSharedState(result.data.value);
    } catch (err) {
      setErrorSnackbarText('エリアの取得に失敗しました');
      setErrorSnackbarOpen(true);
    } finally {
      checkAllFetchesCompleted();
    }
  }, [
    setRegionsSharedState,
    regionsSharedState,
    setErrorSnackbarText,
    setErrorSnackbarOpen,
    checkAllFetchesCompleted,
  ]);

  const fetchCategories = useCallback(async () => {
    if (categoriesSharedState.length) {
      checkAllFetchesCompleted();
      return;
    }
    setIsLoading(true);
    try {
      const result = await getCategoryTree();
      setCategoriesSharedState(result.data.value);
    } catch (err) {
      setErrorSnackbarText('ジャンルの取得に失敗しました');
      setErrorSnackbarOpen(true);
    } finally {
      checkAllFetchesCompleted();
    }
  }, [
    setCategoriesSharedState,
    categoriesSharedState,
    setErrorSnackbarText,
    setErrorSnackbarOpen,
    checkAllFetchesCompleted,
  ]);

  const fetchAttributes = useCallback(async () => {
    if (pickupAttributesSharedState.length) {
      checkAllFetchesCompleted();
      return;
    }
    setIsLoading(true);
    // ピックアップするジャンルをここで指定
    const genresToFetch = [
      '寿司・和食',
      '居酒屋',
      '食べ放題',
      '焼肉',
      'ビストロ',
      'アジア・エスニック',
      'のれん街・横丁',
    ];
    try {
      const genreResult = await getAttributes({ names: genresToFetch });
      setPickupAttributesSharedState(genreResult.data.value);
      setGenres(genreResult.data.value as unknown as Genre[]);
    } catch (err) {
      setErrorSnackbarText('ジャンルの取得に失敗しました');
      setErrorSnackbarOpen(true);
    } finally {
      checkAllFetchesCompleted();
    }
  }, [
    setPickupAttributesSharedState,
    pickupAttributesSharedState,
    setErrorSnackbarText,
    setErrorSnackbarOpen,
    setGenres,
    checkAllFetchesCompleted,
  ]);

  useEffect(() => {
    void fetchLocations();
    void fetchCategories();
    void fetchAttributes();
  }, [fetchLocations, fetchCategories, fetchAttributes]);

  const searchLocation = useCallback(
    (conditions: LocationsConditionProps) => {
      setConditionState(null);
      navigate(
        getSearchResultUrl({
          categoryId: tabId,
          locationIds: conditions.locationIds,
        })
      );
      setIsLocationModal(false);
    },
    [navigate, setConditionState, tabId]
  );

  const searchDateTime = useCallback(
    (conditions: DateTimeConditionProps) => {
      setConditionState(null);
      navigate(
        getSearchResultUrl({
          categoryId: tabId,
          work: {
            days: tab === CategoryName.SPOT ? conditions.days : [],
            timeFrom: conditions.timeFrom,
            timeTo: conditions.timeTo,
            weeks: conditions.weeks,
          },
        })
      );
      setIsLocationModal(false);
    },
    [navigate, setConditionState, tab, tabId]
  );

  // ジャンル検索以外
  const onKeywordSearch = useCallback(
    (keyword: string) => {
      navigate(`/search/services?keyword=${keyword}`);
    },
    [navigate]
  );

  // ジャンル検索のみ
  const searchByGenre = useCallback(
    (genreName: string) => {
      const genreAttribute = pickupAttributesSharedState.find(
        (attr) => attr.name === genreName
      );
      if (genreAttribute) {
        setConditionState(null);
        // 検索結果ページのURLを生成しそこに遷移
        navigate(
          getSearchResultUrl({
            attributeIds: [genreAttribute.id],
            orderBy: 'updatedAtDesc',
          })
        );
      } else {
        setErrorSnackbarText('指定されたジャンルのIDが見つかりません');
        setErrorSnackbarOpen(true);
      }
    },
    [
      navigate,
      setConditionState,
      pickupAttributesSharedState,
      setErrorSnackbarText,
      setErrorSnackbarOpen,
    ]
  );

  // genre.nameに応じてアイコンとnameを切り替えし、searchByGenreでジャンル検索。
  const genreConfigurations: GenreConfiguration = {
    'のれん街・横丁': {
      iconType: 'storeIcon',
      iconValue: 'storeIconValue',
    },
    'アジア・エスニック': {
      iconType: 'bistroIcon',
      iconValue: 'bistroIconValue',
    },
    ビストロ: { iconType: 'asiaIcon', iconValue: 'asiaIconValue' },
    '寿司・和食': { iconType: 'riceIcon', iconValue: 'riceIconValue' },
    居酒屋: { iconType: 'beerIcon', iconValue: 'beerIconValue' },
    洋食: { iconType: 'italianIcon', iconValue: 'italianIconValue' },
    焼肉: { iconType: 'diningIcon', iconValue: 'diningIconValue' },
    食べ放題: { iconType: 'restaurantIcon', iconValue: 'restaurantIconValue' },
  };

  const buttonsToDisplay = genres.map((genre) => {
    const defaultConfig = {
      iconType: 'defaultIcon',
      iconValue: 'defaultIconValue',
    };
    // ジャンル名が存在するかチェックし、なければデフォルト設定を使用
    const { iconType, iconValue } =
      genreConfigurations[genre.name] || defaultConfig;

    const buttonConfig = {
      iconType,
      iconValue,
      name: genre.name,
      onClick: () => searchByGenre(genre.name),
    };

    return buttonConfig;
  });

  return (
    <>
      <TopNavPortal />
      <HomeCarousel />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {categoriesSharedState[0] && (
            <SearchTypeTab
              items={categoriesSharedState[0].children}
              value={tab}
              onChangeTab={onChangeTab}
            />
          )}
          <Container
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              my: 3,
            }}
          >
            <Stack
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} style={{ display: 'flex' }}>
                  <Grid style={{ display: 'flex', flexGrow: 1 }}>
                    <CategoryIconButton
                      name={`日付・時間\r\nから探す`}
                      icon={{
                        size: 'large',
                        type: 'material',
                        value: 'EventNote',
                      }}
                      onClick={() => setIsDateTimeModal(true)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex' }}>
                  <Grid style={{ display: 'flex', flexGrow: 1 }}>
                    <CategoryIconButton
                      name={`エリアから\r\n探す`}
                      icon={{
                        size: 'large',
                        type: 'material',
                        value: 'FmdGoodOutlined',
                      }}
                      onClick={() => setIsLocationModal(true)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <SearchBox
                onSearch={onKeywordSearch}
                sx={{ mt: 2, width: '100%' }}
              />
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {buttonsToDisplay.map((button) => (
                  <Grid
                    item
                    xs={button.iconType === 'storeIcon' ? 12 : 6}
                    key={button.name}
                  >
                    <CategoryIconButton
                      name={button.name}
                      icon={{
                        size: 'large',
                        type: button.iconType,
                        value: button.iconValue,
                      }}
                      onClick={button.onClick}
                    />
                  </Grid>
                ))}
              </Grid>
              {authInfo && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', mt: 2, width: '100%' }}
                >
                  <Grid style={{ display: 'flex', flexGrow: 1, width: '100%' }}>
                    <CategoryIconButton
                      name={`予約一覧\r\nから探す`}
                      icon={{
                        size: 'large',
                        type: 'planListIcon',
                        value: 'planListIcon',
                      }}
                      onClick={() => navigate('/orders')}
                    />
                  </Grid>
                </Grid>
              )}
            </Stack>
          </Container>
        </>
      )}
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
      <LocationsModal
        category={tab}
        open={isLocationModal}
        onClose={() => setIsLocationModal(false)}
        onClickSearch={searchLocation}
        isLocationModal={isLocationModal}
      />
      <DateTimeModal
        category={tab}
        open={isDateTimeModal}
        onClose={() => setIsDateTimeModal(false)}
        onClickSearch={searchDateTime}
      />
    </>
  );
}
