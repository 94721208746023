import { format } from 'date-fns';

import {
  Attribute,
  AttributeGroupNameKey,
  SearchCondition,
} from '@app/types/catalog';

export const getSearchResultUrl = (condition?: SearchCondition | null) => {
  const options = [];
  if (condition?.categoryId) {
    options.push(`category=${condition.categoryId}`);
  }
  if (
    (condition?.variant?.min || condition?.variant?.max) &&
    condition?.variant?.type
  ) {
    options.push(`variantType=${condition.variant.type}`);
    if (condition.variant.max) {
      options.push(`variantMax=${condition.variant.max}`);
    }
    if (condition.variant.min) {
      options.push(`variantMin=${condition.variant.min}`);
    }
  }
  if (condition?.locationIds && condition?.locationIds.length > 0) {
    options.push(`locations=${condition.locationIds.join(',')}`);
  }
  if (condition?.attributeIds && condition.attributeIds.length > 0) {
    options.push(`attributes=${condition.attributeIds.join(',')}`);
  }
  if (condition?.work?.days && condition.work.days.length > 0) {
    options.push(
      `days=${condition.work.days
        .map((d) => format(new Date(d), 'yyyy-MM-dd'))
        .join(',')}`
    );
  }
  if (condition?.work?.weeks && condition.work.weeks.length > 0) {
    options.push(`weeks=${condition.work.weeks.join(',')}`);
  }
  if (condition?.work?.timeFrom) {
    options.push(`timeFrom=${condition.work.timeFrom}`);
  }
  if (condition?.work?.timeTo) {
    options.push(`timeTo=${condition.work.timeTo}`);
  }
  if (condition?.keyword) {
    options.push(`keyword=${encodeURIComponent(condition.keyword.trim())}`);
  }
  if (condition?.orderBy) {
    options.push(`orderBy=${condition.orderBy}`);
  }
  return `/search/services?${options.join('&')}`;
};

export const getAttributesByGroupName = (
  attributes: Attribute[],
  groupName: AttributeGroupNameKey
) => {
  return attributes.filter((a) => a.groupName === groupName);
};

export const getAttributesGroupByName = (
  attributes: Attribute[],
  groupName: AttributeGroupNameKey
): Attribute[] => {
  return attributes
    .filter((a) => a.groupName === groupName)
    .reduce((items: Attribute[], item: Attribute) => {
      const foundGroup = items.find((i) => i.name === item.name);
      if (foundGroup) {
        foundGroup.children?.push(item);
      } else {
        items.push({
          ...item,
          children: [item],
        });
      }
      return items;
    }, []);
};
