import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Avatar,
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
  MenuItem as MuiMenuItem,
  MenuList,
  styled,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
} from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { resetResponseInterceptor } from '@app/adapter/axios';
import {
  AttributesModal,
  tabs as AttributesModalTabs,
} from '@app/components/Search/AttributesModal';
import { DateTimeModal } from '@app/components/Search/DateTimeModal';
import { LocationsModal } from '@app/components/Search/LocationsModal';
import {
  loggedInUserState,
  useClearAuthStateAndStorage,
  userAuthInfoState,
} from '@app/domain/app';
import { sidebarOpenState } from '@app/domain/top-nav';
import {
  SIDE_BAR_JOB_MENU_ITEMS,
  SIDE_BAR_SYSTEM_MENU_ITEMS,
} from '@app/static/constants';
import { CategoryName } from '@app/types/catalog';

const CONTAINER_UNIT_PX = 2; //16
const MenuItem = styled(MuiMenuItem)({
  //TODO add to theme
  px: CONTAINER_UNIT_PX,
});

const ListItemIcon = styled(MuiListItemIcon)({
  justifyContent: 'right',
});

export function SideMenu(props: SwipeableDrawerProps): ReactElement {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shouldSidebarOpen, setShouldSidebarOpen] =
    useRecoilState(sidebarOpenState);
  const userInfo = useRecoilValue(userAuthInfoState);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const [isDateTimeModal, setIsDateTimeModal] = useState(false);
  const [isAttributeModal, setIsAttributeModal] = useState(false);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [categoryName, setCategoryName] = useState<string>('');
  const [attributesModalTab, setAttributesModalTab] = useState<string>('');

  const clearAuthStateAndStorage = useClearAuthStateAndStorage();

  const handleClickLogout = useCallback(() => {
    clearAuthStateAndStorage();
    resetResponseInterceptor();
    setShouldSidebarOpen(false);
    navigate('/login');
  }, [clearAuthStateAndStorage, navigate, setShouldSidebarOpen]);

  const openDateTimeModal = useCallback((category: string) => {
    setCategoryName(category);
    setIsDateTimeModal(true);
  }, []);
  const openAttributeModal = useCallback((tabName: string) => {
    setAttributesModalTab(tabName);
    setIsAttributeModal(true);
  }, []);

  return (
    <SwipeableDrawer {...props}>
      <Box sx={{ maxWidth: '80vw', width: '20rem' }}>
        {!userInfo && (
          <MenuList>
            <MenuItem
              onClick={() => {
                navigate('/login');
                setShouldSidebarOpen(false);
              }}
            >
              <ListItemText>ログイン</ListItemText>
              <ListItemIcon>
                <ChevronRightIcon color="neutral" />
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/register');
                setShouldSidebarOpen(false);
              }}
            >
              <ListItemText>新規会員登録</ListItemText>
              <ListItemIcon>
                <ChevronRightIcon color="neutral" />
              </ListItemIcon>
            </MenuItem>
          </MenuList>
        )}
        {loggedInUser && (
          <ListItem
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/profile/edit');
              setShouldSidebarOpen(false);
            }}
          >
            <ListItemAvatar>
              <Avatar src={loggedInUser.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                loggedInUser.customFields
                  ? `${loggedInUser.customFields?.familyName || '(未設定)'}${
                      loggedInUser.customFields?.firstName || ''
                    }`
                  : ''
              }
            />
          </ListItem>
        )}
        <Divider />
        {userInfo && (
          <>
            <MenuList>
              {SIDE_BAR_JOB_MENU_ITEMS.map(
                ({ key, text, path, isDisabled }) => (
                  <MenuItem
                    key={key}
                    disabled={isDisabled}
                    sx={{ px: 2 }}
                    onClick={() => {
                      navigate(path);
                      setShouldSidebarOpen(false);
                    }}
                  >
                    <ListItemText>{text}</ListItemText>
                    <ListItemIcon>
                      <ChevronRightIcon color="neutral" />
                    </ListItemIcon>
                  </MenuItem>
                )
              )}
            </MenuList>
            <Divider />
          </>
        )}
        <Box sx={{ mt: 2, px: CONTAINER_UNIT_PX }}>
          <Typography color="textSecondary" variant="subtitle2">
            プランを探す
          </Typography>
        </Box>
        <MenuList>
          <MenuItem
            sx={{ px: 2 }}
            onClick={() => openDateTimeModal(CategoryName.SPOT)}
          >
            <ListItemText>日付・時間から探す</ListItemText>
            <ListItemIcon>
              <ChevronRightIcon color="neutral" />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ px: 2 }}
            onClick={() => openDateTimeModal(CategoryName.PART_TIME)}
          >
            <ListItemText>曜日・時間から探す</ListItemText>
            <ListItemIcon>
              <ChevronRightIcon color="neutral" />
            </ListItemIcon>
          </MenuItem>
          <MenuItem sx={{ px: 2 }} onClick={() => setIsLocationModal(true)}>
            <ListItemText>エリアから探す</ListItemText>
            <ListItemIcon>
              <ChevronRightIcon color="neutral" />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ px: 2 }}
            onClick={() => openAttributeModal(AttributesModalTabs[0].value)}
          >
            <ListItemText>ジャンルから探す</ListItemText>
            <ListItemIcon>
              <ChevronRightIcon color="neutral" />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ px: 2 }}
            onClick={() => openAttributeModal(AttributesModalTabs[0].value)}
            disabled
          >
            <ListItemText>詳細条件から探す</ListItemText>
            <ListItemIcon>
              <ChevronRightIcon color="neutral" />
            </ListItemIcon>
          </MenuItem>
        </MenuList>
        <Divider />
        <MenuList>
          {SIDE_BAR_SYSTEM_MENU_ITEMS.map(({ key, text, isDisabled }) => (
            <MenuItem key={key} sx={{ px: 2 }} disabled={isDisabled}>
              <ListItemText>{text}</ListItemText>
              <ListItemIcon>
                <ChevronRightIcon color="neutral" />
              </ListItemIcon>
            </MenuItem>
          ))}
        </MenuList>
        {userInfo && (
          <>
            <Divider />
            <MenuItem sx={{ px: 2 }} onClick={handleClickLogout}>
              <ListItemText>ログアウト</ListItemText>
            </MenuItem>
          </>
        )}
      </Box>
      <DateTimeModal
        category={categoryName}
        open={isDateTimeModal}
        onClose={() => {
          setIsDateTimeModal(false);
          setShouldSidebarOpen(false);
        }}
      />
      <LocationsModal
        open={isLocationModal}
        onClose={() => {
          setIsLocationModal(false);
          setShouldSidebarOpen(false);
        }}
        isLocationModal={isLocationModal}
      />
      <AttributesModal
        tabValue={attributesModalTab}
        open={isAttributeModal}
        onClose={() => {
          setIsAttributeModal(false);
          setShouldSidebarOpen(false);
        }}
      />
    </SwipeableDrawer>
  );
}
