import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import { ReactElement, useCallback, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { getAttributes, getCategoryTree } from '@app/adapter/catalog-service';
import { Dialog } from '@app/components/Shared/Dialog';
import { Loading } from '@app/components/Shared/Loading';
import {
  attributesState,
  categoryState,
  searchConditionState,
} from '@app/domain/search';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { Attribute, CategoryParentName } from '@app/types/catalog';
import { LoadableState } from '@app/types/common';
import { getSearchResultUrl } from '@app/utils/catalog';

export const tabs = [{ label: 'ジャンル', value: 'genre' }] as const;
export const variantTypeOptions = [
  { label: '時給', value: 'hour' },
  { label: '日給', value: 'day' },
] as const;

export interface ConditionProps {
  attributes?: string[];
  max?: string;
  min?: string;
  type?: string;
}
interface AttributesModalProps {
  category?: string;
  condition?: ConditionProps;
  onClickSearch?: (conditions: ConditionProps) => void;
  onClose: () => void;
  open: boolean;
  tabValue?: string;
}

export function AttributesModal({
  category,
  condition,
  onClickSearch,
  onClose,
  open,
  tabValue,
}: AttributesModalProps): ReactElement {
  const navigate = useNavigate();
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setConditionState = useSetRecoilState(searchConditionState);
  const [attributesSharedState, setAttributesSharedState] =
    useRecoilState(attributesState);
  const [categoriesSharedState, setCategoriesSharedState] =
    useRecoilState(categoryState);
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].value);
  const [conditions, setConditions] = useState<ConditionProps>({
    attributes: condition?.attributes || [],
    max: condition?.max || '',
    min: condition?.min || '',
    type: condition?.type || variantTypeOptions[0].value,
  });

  const fetchAttributes = useCallback(async () => {
    if (attributesSharedState.length || loadable === LoadableState.LOADING)
      return;
    setLoadable(LoadableState.LOADING);
    try {
      const result = await getAttributes();
      setAttributesSharedState(result.data.value);
      setLoadable(LoadableState.HAS_VALUE);
    } catch (err) {
      setLoadable(LoadableState.HAS_ERROR);
      setErrorSnackbarText('ジャンルの取得に失敗しました');
      setErrorSnackbarOpen(true);
    }
  }, [
    attributesSharedState,
    loadable,
    setAttributesSharedState,
    setErrorSnackbarOpen,
    setErrorSnackbarText,
  ]);

  const fetchCategories = useCallback(async () => {
    if (categoriesSharedState.length || loadable === LoadableState.LOADING)
      return;
    setLoadable(LoadableState.LOADING);
    try {
      const result = await getCategoryTree();
      setCategoriesSharedState(result.data.value);
      setLoadable(LoadableState.HAS_VALUE);
    } catch (err) {
      setLoadable(LoadableState.HAS_ERROR);
      setErrorSnackbarText('タイプの取得に失敗しました');
      setErrorSnackbarOpen(true);
    }
  }, [
    categoriesSharedState,
    loadable,
    setCategoriesSharedState,
    setErrorSnackbarOpen,
    setErrorSnackbarText,
  ]);

  useEffect(() => {
    if (open) {
      void fetchAttributes();
      void fetchCategories();
    }
    // eslint-disable-next-line
  }, [open]);

  const jobTypeItems = useMemo(() => {
    return attributesSharedState.filter((a) => a.groupName === 'genre');
  }, [attributesSharedState]);

  const categoryId = useMemo(() => {
    return (
      categoriesSharedState
        .find((v) => v.name === CategoryParentName.JOB_TYPE)
        ?.children.find((c) => c.name === category)?.id || ''
    );
  }, [categoriesSharedState, category]);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, value: string) => {
      setSelectedTab(value);
    },
    [setSelectedTab]
  );

  const handleChangeChecked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, name?: string) => {
      const targetIds: string[] = [event.target.value];
      const data = conditions.attributes || [];
      setConditions({
        ...conditions,
        attributes: event.target.checked
          ? [...data, ...targetIds]
          : data.filter((c) => !targetIds.includes(c)),
      });
    },
    [conditions]
  );

  const handleClose = useCallback((): void => {
    onClose();
    setSelectedTab(tabValue || tabs[0].value);
  }, [onClose, tabValue]);

  useEffect(() => {
    setSelectedTab(tabValue || tabs[0].value);
  }, [tabValue]);

  const handleClickSearch = useCallback(() => {
    if (onClickSearch) {
      onClickSearch(conditions);
      return;
    }
    setConditionState(null);
    navigate(
      getSearchResultUrl({
        attributeIds: conditions.attributes,
        categoryId,
        variant: {
          max: conditions.max,
          min: conditions.min,
          type: conditions.type,
        },
      })
    );
    handleClose();
  }, [
    categoryId,
    conditions,
    handleClose,
    navigate,
    onClickSearch,
    setConditionState,
  ]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title="詳細条件を追加・変更"
      content={
        <>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            variant="fullWidth"
          >
            {tabs.map(({ label, value }, index) => (
              <Tab key={index} label={label} value={value} />
            ))}
          </Tabs>
          <>
            {loadable === LoadableState.LOADING ? (
              <Loading />
            ) : (
              <Box sx={{ px: 2, py: 1 }}>
                <Grid container spacing={1}>
                  {jobTypeItems.map((item: Attribute) => (
                    <Grid item xs={6} key={item.id}>
                      <FormControlLabel
                        label={item.name}
                        control={
                          <Checkbox
                            value={item.id}
                            checked={conditions.attributes?.includes(item.id)}
                            onChange={(e) => handleChangeChecked(e)}
                          />
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </>
        </>
      }
      action={
        <Stack direction="row" spacing={2} width={1}>
          <Button
            color="inherit"
            variant="outlined"
            fullWidth
            onClick={handleClose}
          >
            キャンセル
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            fullWidth
            onClick={handleClickSearch}
          >
            検索
          </Button>
        </Stack>
      }
    />
  );
}
