/**
 * convert url to Blob object
 * @param url
 * @returns blob
 */
export async function urlToBlob(url: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function (e) {
      if (this.status === 200) {
        resolve(this.response as Blob);
      } else {
        reject(new Error('urlToBlob: load image failure'));
      }
    };
    xhr.onerror = (err) => reject(err);
    xhr.onabort = () => reject(new Error('urlToBlob: load image abort'));
    xhr.send();
  });
}

/**
 * convert file to base64 string
 * @param blobUrl - blobs url
 * @returns - base64 string
 */
export async function urlToBase64(blobUrl: string): Promise<string> {
  if (!blobUrl) {
    return '';
  }
  const blob = await urlToBlob(blobUrl);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () =>
      reader.result ? resolve(reader.result as string) : resolve('');
    reader.readAsDataURL(blob);
    reader.onerror = (err) => reject(err);
    reader.onabort = () => reject(new Error('urlToBase64: convert failure'));
  });
}
