import { Organization } from './organization';

/**
 * Category
 */
export interface Category {
  children: Category[];
  description: string;
  icon?: {
    type: string;
    value: string;
  };
  id: string;
  name: string;
  numberOfProducts: number;
  parent: null | {
    uri: string;
  };
  status: CategoryStatusKey;
}

export interface CategoryWithCount extends Category {
  children: (Category & { count: number })[];
}

export interface LocationWithCount extends ProductLocation {
  count: number;
}

export interface CategoryProductCount {
  count: number;
  id: string;
}

export interface LocationProductCount {
  count: number;
  id: string;
}

export type CategoryState = {
  id: string;
  name: string;
};

export const CategoryParentName = {
  JOB_TYPE: 'プラン区分',
} as const;
export type CategoryParentNameKey =
  (typeof CategoryParentName)[keyof typeof CategoryParentName];

export const CategoryName = {
  PART_TIME: '定期非常勤',
  SPOT: 'スポット',
} as const;
export type CategoryNameKey = (typeof CategoryName)[keyof typeof CategoryName];

export const CategoryStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
} as const;
export type CategoryStatusKey =
  (typeof CategoryStatus)[keyof typeof CategoryStatus];

export const CategoryIconType = {
  ASIA_ICON: 'asiaIcon',
  BEER_ICON: 'beerIcon',
  BISTRO_ICON: 'bistroIcon',
  HAIR_ICON: 'hairSalonIcon',
  LOCAL_DINING_ICON: 'diningIcon',
  MASSAGE_ICON: 'massageIcon',
  MATERIAL_ICON: 'materialIcon',
  MATERIAL_SYMBOLS: 'materialSymbols',
  NAIL_ICON: 'nailSalonIcon',
  PLAN_LIST: 'planListIcon',
  RESTAURANT_ICON: 'restaurantIcon',
  RICE_ICON: 'riceIcon',
  SPA_ICON: 'spaIcon',
  STORE_ICON: 'storeIcon',
} as const;

export type CategoryIconTypeKey =
  (typeof CategoryIconType)[keyof typeof CategoryIconType];

/**
 * Product
 */
export interface Product {
  additionalInformation: string;
  attributes: Attribute[];
  category?: { id: string; name: string };
  categoryId: string;
  customFields: ProductCustomFields;
  description: string;
  dimensions: string;
  favorite: boolean;
  features: ProductFeature[];
  id: string;
  images: ProductImage[];
  isPromoted?: boolean;
  locationIds: string[];
  locations: ProductLocation[];
  name: string;
  organization?: Organization | string;
  organizationId: string;
  publication: ProductPublication;
  tags: string[];
  variantNote: string;
  variants: ProductVariant[];
}

export interface ProductCustomFields {
  access: string;
  breakTime: string;
  breakTimeNoParticular: boolean;
  calculatedDairyPay: number;
  calculatedHourlyPay: number;
  canBookToday: boolean;
  commuting: ProductCommutingKey;
  commutingCost: number;
  count: number;
  days: string[];
  endTime: string;
  maxBookingPeople: number;
  maxReservations: number;
  minBookingPeople: number;
  orderConditions: string;
  overWorkConditions: string[];
  prRules: string;
  price: string;
  repeatDetail: string;
  repeatWeek: number[];
  reservationApproval: boolean;
  reservationNotes: string;
  selection: string;
  startTime: string;
  workAddress1: string;
  workAddress2: string;
  workPostalCode: string;
  workPublicHoliday: boolean;
}

export interface ProductPublication {
  publishedAt?: string;
  publishedBy?: string;
  since?: string;
  status: ProductPublicationStatusKey;
  until?: string;
}

export interface Top {
  _id: string;
  name: string;
  organizationId: string;
  top: Product[];
}

export interface SearchResultProducts {
  data: Top[];
  nextLink: string;
  scrollY?: number;
  total: number;
}

export interface SearchCondition {
  attributeIds?: string[];
  categoryId?: string | null;
  keyword?: string;
  locationIds?: string[];
  orderBy?: string;
  organizationId?: string;
  variant?: {
    max?: string;
    min?: string;
    type?: string;
  };
  work?: {
    days?: string[];
    timeFrom?: string;
    timeTo?: string;
    weeks?: string[];
  };
}

export interface ProductPrice {
  amount: number;
  taxable: boolean;
}

export interface ProductImage {
  alt: string;
  createdAt: string;
  id: string;
  productId: string;
  src: string;
  updatedAt: string;
  url?: string;
  variantIds: string[];
}

export interface ProductVariant {
  createdAt: string;
  description: string;
  id: string;
  price: ProductPrice;
  sku: string;
  title: string;
  updatedAt: string;
}

export interface ProductFeature {
  name: string;
  value: string;
}

export const ProductType = {
  REGULAR_PART_TIME: 'regularPartTime',
  SPOT: 'spot',
} as const;
export type ProductTypeKey = (typeof ProductType)[keyof typeof ProductType];

export const ProductPublicationStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
} as const;
export type ProductPublicationStatusKey =
  (typeof ProductPublicationStatus)[keyof typeof ProductPublicationStatus];

export const ProductCommuting = {
  FIXED_FEE: 'FixedFee',
  INCLUDE: 'Include',
  NO_FEE: 'NoFee',
  NO_UPPER_LIMIT: 'NoUpperLimit',
  UPPER_LIMIT: 'UpperLimit',
} as const;
export type ProductCommutingKey =
  (typeof ProductCommuting)[keyof typeof ProductCommuting];
export const ProductCommutingLabel = (
  key: ProductCommutingKey | ''
): string => {
  switch (key) {
    case ProductCommuting.FIXED_FEE:
      return '別途支給(定額)';
    case ProductCommuting.INCLUDE:
      return '交通費込み(所定金額)';
    case ProductCommuting.NO_FEE:
      return 'なし';
    case ProductCommuting.NO_UPPER_LIMIT:
      return '別途支給(上限なし)';
    case ProductCommuting.UPPER_LIMIT:
      return '別途支給(上限金額)';
  }
  return '';
};

/**
 * Attribute
 */
export interface Attribute {
  attributeId?: string;
  categoryIds: string[];
  children?: Attribute[];
  customFields?: AttributeCustomFields;
  groupName: string;
  id: string;
  items: AttributeItem[];
  name: string;
  order: number;
  type: string;
  value: string;
}

export interface AttributeCustomFields {
  icon?: {
    type: string;
    value: string;
  };
}

export interface AttributeItem {
  groupName: string;
  key: string;
  value: string;
}

export const AttributeGroupName = {
  GENRE_TYPE: 'genre',
} as const;
export type AttributeGroupNameKey =
  (typeof AttributeGroupName)[keyof typeof AttributeGroupName];

export type Genre = {
  id: number;
  name: string;
};

/**
 * Location
 */
export interface City {
  city: string;
  cityCode: string;
}

export interface ProductLocation {
  children?: ProductLocation[];
  code: string | null;
  createdAt?: string;
  id: string;
  name: string;
  numberOfProducts?: number;
  type: ProductLocationTypeKey;
  updatedAt?: string;
}

export const ProductLocationType = {
  CITY: 'city',
  PREFECTURE: 'prefecture',
  REGION: 'region',
} as const;
export type ProductLocationTypeKey =
  (typeof ProductLocationType)[keyof typeof ProductLocationType];
