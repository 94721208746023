import { AxiosPromise } from 'axios';
import { get } from 'lodash';

import { orderInstance } from './axios';

import { Product } from '@app/types/catalog';
import { Pagination } from '@app/types/common';
import {
  Order,
  OrderCreation,
  OrderStatus,
  OrderUpdate,
} from '@app/types/order';
import { getAuthorizationHeader } from '@app/utils/authorization';
import { filterSyntaxGen } from '@app/utils/network';

type CreateOrderOptions = {
  lineItemExpand?: boolean;
  organizationExpand?: boolean;
  userExpand?: boolean;
};

/**
 * Create order use order-service
 * @param token
 * @param fingerprint
 * @param organizationId
 * @param payload
 * @param options
 * @returns
 */
export function createOrder(
  token: string,
  fingerprint: string,
  organizationId: string,
  product: Product,
  payload: OrderCreation,
  options?: CreateOrderOptions
): AxiosPromise<Order> {
  // MEMO:reservationApprovalがtrueの場合は「承認制」。falseの場合は「即予約」
  payload.status =
    product.customFields?.reservationApproval === true ? 'PENDING' : 'ACCEPTED';

  const urlParams = [];
  const expandParams = [];
  if (options?.organizationExpand) {
    expandParams.push('organization');
  }
  if (options?.userExpand) {
    expandParams.push('customer.user');
  }
  if (options?.lineItemExpand) {
    expandParams.push('lineItems.product');
    expandParams.push('lineItems.variant');
  }
  if (options?.organizationExpand) {
    urlParams.push(['$expand', expandParams.join(',')]);
  }
  const query =
    urlParams.length > 0 ? `?${new URLSearchParams(urlParams).toString()}` : '';
  const url = `/orgs/${organizationId}/orders${query}`;
  return orderInstance
    .post(url, payload, {
      headers: {
        'x-nb-fingerprint': fingerprint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * update order use order-service
 * @param token
 * @param fingerprint
 * @param organizationId
 * @param orderId
 * @param payload
 * @returns
 */
export function updateOrder(
  token: string,
  fingerprint: string,
  organizationId: string,
  orderId: string,
  payload: OrderUpdate
): AxiosPromise<Order> {
  const urlParams = [];
  urlParams.push(['$expand', 'organization,lineItems.product']);
  return orderInstance
    .patch(
      `/orgs/${organizationId}/orders/${orderId}?${new URLSearchParams(
        urlParams
      ).toString()}`,
      payload,
      {
        headers: {
          'x-nb-fingerprint': fingerprint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Get order list of a user use orderService
 * @param token
 * @param fingerprint
 * @param userId
 * @param options
 * @returns
 */
export function getUserOrders(
  token: string,
  fingerprint: string,
  userId: string,
  options?: {
    $expand?: string;
    '@nextLink'?: string;
    isAfterWork?: boolean;
    isBeforeWork?: boolean;
    isResult?: boolean;
    limit?: number;
    orderBy?: string | 'createdAt desc';
    productId?: string;
    statuses?: string[];
    workResultMonth?: string;
  }
): AxiosPromise<Pagination<Order[]>> {
  const urlParams = [['$top', String(options?.limit || 20)]];
  const filterParams = [];
  if (options?.['@nextLink']) {
    const nextToken = new URLSearchParams(
      get(options?.['@nextLink'].split('?'), '[1]', '')
    ).get('$nextToken');
    if (nextToken) {
      urlParams.push(['$nextToken', nextToken]);
    }
  }

  // 来店予定条件
  if (options?.isBeforeWork) {
    options.statuses?.push(OrderStatus.ACCEPTED);
  }
  // 投稿待ち条件
  if (options?.isAfterWork) {
    options.statuses?.push(OrderStatus.PROCESSING);
    // TODO: カスタムフィールドで年月指定が可能になったら以下のコメントアウトを外す
    // filterParams.push(
    //   `ymd(customFields.workResultAt) ge '${options?.workResultMonth}-01'`
    // );
    // filterParams.push(
    //   `ymd(customFields.workResultAt) le '${options?.workResultMonth}-31'`
    // );
  }
  // 過去の来店条件
  if (options?.isResult) {
    options.statuses?.push(OrderStatus.WAITING) ||
      options.statuses?.push(OrderStatus.CLOSED);
  }
  if (options?.statuses?.length) {
    filterParams.push(`status in ${filterSyntaxGen(options?.statuses)}`);
  }
  if (options?.productId) {
    filterParams.push(`lineItems.productId eq '${options.productId}'`);
  }
  if (options?.orderBy) {
    urlParams.push(['$orderBy', options.orderBy]);
  }
  if (options?.$expand) {
    urlParams.push(['$expand', options.$expand]);
  }
  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }

  return orderInstance
    .get(
      `/users/${userId}/orders?${new URLSearchParams(urlParams).toString()}`,
      {
        headers: {
          'x-nb-fingerprint': fingerprint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch(
      (error: {
        message: string | undefined;
        response: { data: { message: string | undefined } };
      }) => {
        if (error.response && 'message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      }
    );
}

/**
 * Get an order
 * APIがない為、一覧にIDを指定して取得する
 * @param token
 * @param fingerprint
 * @param userId
 * @param id
 * @returns
 */
export function getOrder(
  token: string,
  fingerprint: string,
  userId: string,
  id: string,
  options?: {
    $expand?: string;
  }
): AxiosPromise<Order> {
  const urlParams = [['$top', String(1)]];
  if (options?.$expand) {
    urlParams.push(['$expand', options.$expand]);
  }
  urlParams.push(['$filter', `id eq '${id}'`]);

  return orderInstance
    .get(
      `/users/${userId}/orders?${new URLSearchParams(urlParams).toString()}`,
      {
        headers: {
          'x-nb-fingerprint': fingerprint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .then((response) => {
      return { ...response, data: response.data.value[0] };
    })
    .catch(
      (error: {
        message: string | undefined;
        response: { data: { message: string | undefined } };
      }) => {
        if (error.response && 'message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      }
    );
}
