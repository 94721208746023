import { Avatar, Box, Typography, Stack } from '@mui/material';
import { ReactElement, useMemo } from 'react';

import { Organization } from '@app/types/organization';
import { unescapeHtml } from '@app/utils/pattern';

interface CardPartOrganizationProps {
  organization: Organization;
}

export function CardPartOrganization({
  organization,
}: CardPartOrganizationProps): ReactElement {
  const addressFull = useMemo(() => {
    return [
      organization.customFields?.addressByCity,
      organization.addressLine3,
      organization.customFields?.addressLine4,
    ]
      .filter((i) => i)
      .join('');
  }, [organization]);
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Avatar
        variant="square"
        sx={{ borderRadius: '8px', height: 50, width: 40 }}
      >
        {organization.name ? organization.name.substring(0, 1) : ''}
      </Avatar>
      <Box>
        <Typography variant="body1" color="text" fontSize={'1.2rem'}>
          {unescapeHtml(organization.name)}
        </Typography>
        <Typography variant="body2" fontSize="0.9em">
          {unescapeHtml(addressFull)}
        </Typography>
      </Box>
    </Stack>
  );
}
