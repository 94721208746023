import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
} from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  getLocationPrefectures,
  getLocations,
} from '@app/adapter/catalog-service';
import { Dialog } from '@app/components/Shared/Dialog';
import { Loading } from '@app/components/Shared/Loading';
import {
  locationPrefectureState,
  searchConditionState,
} from '@app/domain/search';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { ProductLocation, ProductLocationType } from '@app/types/catalog';
import { LoadableState } from '@app/types/common';
import { getSearchResultUrl } from '@app/utils/catalog';

export interface ConditionProps {
  locationIds?: string[];
}
interface LocationsModalProps {
  category?: string;
  condition?: ConditionProps;
  isLocationModal: boolean;
  onClickSearch?: (conditions: ConditionProps) => void;
  onClose: () => void;
  open: boolean;
}

const TOKYO_PREFECTURE_ID = '64d1379cdc643bb7ae679bc0';
const KANAGAWA_PREFECTURE_ID = '64d137aedc643bb7ae679bff';
const SAITAMA_PREFECTURE_ID = '64d13778dc643bb7ae679b49';
const CHIBA_PREFECTURE_ID = '64d1378cdc643bb7ae679b89';

export function LocationsModal({
  category,
  condition,
  onClickSearch,
  onClose,
  open,
  isLocationModal,
}: LocationsModalProps): ReactElement {
  const navigate = useNavigate();
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setConditionState = useSetRecoilState(searchConditionState);
  const [prefectureSharedState, setPrefectureSharedState] = useRecoilState(
    locationPrefectureState
  );
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const [conditions, setConditions] = useState<ConditionProps>({
    locationIds: condition?.locationIds || [],
  });
  const [cities, setCities] = useState<ProductLocation[]>([]);

  const requiredPrefectureIds = useMemo(
    () => [
      TOKYO_PREFECTURE_ID,
      KANAGAWA_PREFECTURE_ID,
      SAITAMA_PREFECTURE_ID,
      CHIBA_PREFECTURE_ID,
    ],
    []
  );

  const fetchPrefectures = useCallback(async () => {
    if (prefectureSharedState.length || loadable === LoadableState.LOADING)
      return;
    setLoadable(LoadableState.LOADING);
    try {
      const response = await getLocationPrefectures({
        ids: requiredPrefectureIds,
        type: ProductLocationType.PREFECTURE,
      });
      setPrefectureSharedState(response.data.value);
      setLoadable(LoadableState.HAS_VALUE);
    } catch (error) {
      setLoadable(LoadableState.HAS_ERROR);
      setErrorSnackbarText('都道府県データの取得に失敗しました');
      setErrorSnackbarOpen(true);
    }
  }, [
    prefectureSharedState.length,
    loadable,
    requiredPrefectureIds,
    setPrefectureSharedState,
    setErrorSnackbarText,
    setErrorSnackbarOpen,
  ]);

  useEffect(() => {
    if (open && isLocationModal) {
      void fetchPrefectures();
    }
    // eslint-disable-next-line
  }, [open, isLocationModal]);

  const fetchCities = useCallback(async () => {
    if (!isLocationModal) return;
    try {
      const response = await getLocations({
        ids: [TOKYO_PREFECTURE_ID],
        type: ProductLocationType.CITY,
      });
      setCities(response.data.value);
    } catch (error) {
      console.error('市町村データの取得に失敗しました', error);
    }
  }, [isLocationModal]);

  useEffect(() => {
    if (isLocationModal) {
      void fetchCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocationModal]);

  const handleChangeChecked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id?: string) => {
      event.stopPropagation();
      const targetIds: string[] = [];
      if (id) {
        const selectedPrefecture = prefectureSharedState.find(
          (p) => p.id === id
        );
        if (selectedPrefecture) {
          targetIds.push(id);
          if (selectedPrefecture.id === TOKYO_PREFECTURE_ID) {
            cities.forEach((city) => targetIds.push(city.id));
          } else if (selectedPrefecture.children) {
            selectedPrefecture.children.forEach((child) =>
              targetIds.push(child.id)
            );
          }
        }
      } else {
        targetIds.push(event.target.value);
      }
      const data = conditions.locationIds || [];
      setConditions({
        ...conditions,
        locationIds: event.target.checked
          ? Array.from(new Set([...data, ...targetIds]))
          : data.filter((c) => !targetIds.includes(c)),
      });
    },
    [conditions, prefectureSharedState, cities]
  );

  const handleClose = useCallback((): void => {
    onClose();
  }, [onClose]);

  const handleClickSearch = useCallback(() => {
    if (onClickSearch) {
      onClickSearch(conditions);
      return;
    }
    setConditionState(null);
    navigate(
      getSearchResultUrl({
        locationIds: conditions.locationIds,
      })
    );
    handleClose();
  }, [conditions, handleClose, navigate, onClickSearch, setConditionState]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title="エリアから探す"
      content={
        <Box>
          {loadable === LoadableState.LOADING ? (
            <Loading />
          ) : (
            <>
              {prefectureSharedState &&
                prefectureSharedState.map((prefecture) => (
                  <Accordion
                    key={prefecture.id}
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <FormControlLabel
                        label={prefecture.name}
                        control={
                          <Checkbox
                            value={prefecture.id}
                            checked={conditions.locationIds?.includes(
                              prefecture.id
                            )}
                            onChange={(e) =>
                              handleChangeChecked(e, prefecture.id)
                            }
                          />
                        }
                      />
                    </AccordionSummary>
                    {prefecture.id === TOKYO_PREFECTURE_ID && (
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          {cities.map((city) => (
                            <Grid item xs={12} key={city.id}>
                              <FormControlLabel
                                label={city.name}
                                control={
                                  <Checkbox
                                    value={city.id}
                                    checked={conditions.locationIds?.includes(
                                      city.id
                                    )}
                                    onChange={(e) => handleChangeChecked(e)}
                                  />
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    )}
                  </Accordion>
                ))}
            </>
          )}
        </Box>
      }
      action={
        <Stack direction="row" spacing={2} width={1}>
          <Button
            color="inherit"
            variant="outlined"
            fullWidth
            onClick={handleClose}
          >
            キャンセル
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            fullWidth
            onClick={handleClickSearch}
          >
            検索
          </Button>
        </Stack>
      }
    />
  );
}
