import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';

const formatTime = (timeString: string): string => {
  return timeString.substring(0, 5);
};

// 30分間隔で時間のリストを生成(選択された日付が今日の場合のみ現在時刻の3時間後の時間をセット)
const generateTimeSlots = (
  startTime: string,
  endTime: string,
  currentDate: string,
  selectedDate: string
) => {
  const slots: string[] = [];
  const isToday = currentDate === selectedDate;
  const oneHourBeforeNow = new Date(new Date().getTime() + 180 * 60 * 1000); // 3時間後

  let start = new Date(`${selectedDate}T${startTime}`);
  const end = new Date(`${selectedDate}T${endTime}`);

  while (start <= end) {
    if (!isToday || (isToday && start > oneHourBeforeNow)) {
      slots.push(
        formatTime(
          start.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            hour12: false,
            minute: '2-digit',
          })
        )
      );
    }
    start = new Date(start.getTime() + 30 * 60 * 1000);
  }

  return slots;
};

interface TimeSelectionProps {
  currentDate: string;
  endTime: string;
  onChange: (newTime: string) => void;
  selectedDate: string;
  startTime: string;
}

export const TimeSelection = ({
  startTime,
  endTime,
  onChange,
  currentDate,
  selectedDate,
}: TimeSelectionProps) => {
  const [selectedTime, setSelectedTime] = useState<string>('');
  const timeSlots = selectedDate
    ? generateTimeSlots(startTime, endTime, currentDate, selectedDate)
    : [];

  const handleTimeChange = (event: SelectChangeEvent<string>) => {
    const newTime = event.target.value;
    setSelectedTime(newTime);
    onChange(newTime);
  };

  let menuItems = [];
  if (selectedDate) {
    menuItems.push(
      <MenuItem key="prompt" value="" disabled>
        時間を選択してください
      </MenuItem>
    );
    if (timeSlots.length > 0) {
      menuItems = menuItems.concat(
        timeSlots.map((time, index) => (
          <MenuItem key={index} value={time}>
            {time}
          </MenuItem>
        ))
      );
    } else {
      menuItems.push(
        <MenuItem key="unavailable" value="" disabled>
          予約可能な時間がありません
        </MenuItem>
      );
    }
  } else {
    menuItems.push(
      <MenuItem key="no-date" value="" disabled>
        日付を選択してください
      </MenuItem>
    );
  }

  return (
    <Select
      value={selectedTime}
      onChange={handleTimeChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {menuItems}
    </Select>
  );
};
