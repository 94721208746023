import { TextField, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { theme } from '@app/theme';

interface SocialMediaURLProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function SocialMediaURL<T extends FieldValues>({
  name,
  control,
}: SocialMediaURLProps<T>): ReactElement {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            value={(field.value && field.value.join(', ')) || ''}
            onChange={(e) => {
              // 入力された値をカンマで分割し配列に変換
              const urls = e.target.value.split(',').map((url) => url.trim());
              field.onChange(urls);
            }}
            onBlur={(e) => {
              // 空白のみを削除し、カンマで分割して配列に変換
              const cleanedUrls = e.target.value
                .split(',')
                .map((url) => url.trim())
                .filter((url) => url);
              field.onChange(cleanedUrls);
              field.onBlur();
            }}
            error={!!error}
            helperText={
              error?.message || 'URLをカンマ区切りで入力してください。'
            }
            margin="dense"
            placeholder="例：https://example.com, https://example.org"
            fullWidth
          />
        )}
      />
      <Typography
        sx={{
          color: theme.palette.error.main,
          fontSize: '0.875rem',
          pl: 2,
        }}
      >
        ※ご自身のプロフィールページのURLをご登録ください
      </Typography>
    </>
  );
}
