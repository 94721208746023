import { isError } from 'util';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { ReactElement, useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { sendResetPasswordEmail } from '@app/adapter/user-service';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';

interface SendResetPasswordEmailSchema {
  email: string;
}

export function SendResetPasswordEmail(): ReactElement {
  const navigate = useNavigate();
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const [emailNotFoundError, setEmailNotFoundError] = useState<string | null>(
    null
  );

  const { control, handleSubmit, formState } =
    useForm<SendResetPasswordEmailSchema>({
      defaultValues: { email: '' },
      mode: 'all',
      resolver: zodResolver(
        z.object({
          email: z
            .string()
            .email({ message: 'メールアドレスを入力してください' }),
        })
      ),
    });

  const onSubmit = useCallback(
    async (data: SendResetPasswordEmailSchema) => {
      const { email } = data;
      try {
        await sendResetPasswordEmail(email);
        navigate('/waiting_reset_password', { state: { waiting: true } });
      } catch (error: unknown) {
        if (isError(error)) {
          if (error.message === 'email not found') {
            setEmailNotFoundError(error.message);
          } else {
            setErrorSnackbarText('処理できませんでした');
            setErrorSnackbarOpen(true);
          }
        }
      }
    },
    [
      navigate,
      setErrorSnackbarOpen,
      setErrorSnackbarText,
      setEmailNotFoundError,
    ]
  );

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
            <Typography fontWeight={600} variant="h4">
              パスワード再設定手続き
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ my: 3 }}>
            <Typography>
              会員登録時にご登録して頂いたメールアドレスを入力してください。パスワード再発行手続きのメールをお送りします。
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={600}
                >
                  メールアドレス
                </Typography>
              </FormLabel>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="メールアドレスを入力"
                  />
                )}
              />
            </FormControl>
            {emailNotFoundError && (
              <Typography
                color="error"
                sx={{ pb: 3, pt: 3, textAlign: 'left' }}
              >
                入力されたメールアドレスの会員情報が見つかりません。間違ったメールアドレスを入力されていないかご確認ください。
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              color="orange"
              variant="contained"
              disabled={!formState.isValid || formState.isSubmitting}
              fullWidth
            >
              送信する
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
