import { Button, Container, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { getCategoryTree } from '@app/adapter/catalog-service';
import { Dialog } from '@app/components/Shared/Dialog';
import { Loading } from '@app/components/Shared/Loading';
import { categoryState, searchConditionState } from '@app/domain/search';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { CategoryParentName } from '@app/types/catalog';
import { LoadableState } from '@app/types/common';
import { getSearchResultUrl } from '@app/utils/catalog';

export interface ConditionProps {
  keyword?: string;
}
interface KeywordModalProps {
  category: string;
  condition?: ConditionProps;
  onClickSearch?: (conditions: ConditionProps) => void;
  onClose: () => void;
  open: boolean;
}

export function KeywordModal({
  category,
  condition,
  onClickSearch,
  onClose,
  open,
}: KeywordModalProps): ReactElement {
  const navigate = useNavigate();
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setConditionState = useSetRecoilState(searchConditionState);
  const [categoriesSharedState, setCategoriesSharedState] =
    useRecoilState(categoryState);
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const { control, getValues, setValue } = useForm<ConditionProps>({
    defaultValues: {
      keyword: '',
    },
  });

  const fetchCategories = useCallback(async () => {
    if (categoriesSharedState.length || loadable === LoadableState.LOADING)
      return;
    setLoadable(LoadableState.LOADING);
    try {
      const result = await getCategoryTree();
      setCategoriesSharedState(result.data.value);
      setLoadable(LoadableState.HAS_VALUE);
    } catch (err) {
      setLoadable(LoadableState.HAS_ERROR);
      setErrorSnackbarText('タイプの取得に失敗しました');
      setErrorSnackbarOpen(true);
    }
  }, [
    categoriesSharedState,
    loadable,
    setCategoriesSharedState,
    setErrorSnackbarOpen,
    setErrorSnackbarText,
  ]);

  useEffect(() => {
    if (open) {
      (Object.keys(getValues()) as Array<keyof ConditionProps>).forEach(
        (key) => {
          setValue(key, _.get(condition, key) || getValues(key));
        }
      );
      void fetchCategories();
    }
    // eslint-disable-next-line
  }, [open]);

  const categoryId = useMemo(() => {
    return (
      categoriesSharedState
        .find((v) => v.name === CategoryParentName.JOB_TYPE)
        ?.children.find((c) => c.name === category)?.id || ''
    );
  }, [categoriesSharedState, category]);

  const handleClose = useCallback((): void => {
    onClose();
  }, [onClose]);

  const handleClickSearch = useCallback(() => {
    const conditions = getValues();
    if (onClickSearch) {
      onClickSearch(conditions);
      return;
    }
    setConditionState(null);
    navigate(
      getSearchResultUrl({
        categoryId,
        keyword: conditions.keyword,
      })
    );
    handleClose();
  }, [
    categoryId,
    getValues,
    handleClose,
    navigate,
    onClickSearch,
    setConditionState,
  ]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title="キーワード検索"
      content={
        <Container>
          {loadable === LoadableState.LOADING ? (
            <Loading />
          ) : (
            <Stack spacing={1}>
              <Typography variant="body2">検索キーワード</Typography>
              <Controller
                name="keyword"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="email"
                    error={!!error}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="キーワードで検索"
                    fullWidth
                  />
                )}
              />
            </Stack>
          )}
        </Container>
      }
      action={
        <Stack direction="row" spacing={2} width={1}>
          <Button
            color="inherit"
            variant="outlined"
            fullWidth
            onClick={handleClose}
          >
            キャンセル
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            fullWidth
            onClick={handleClickSearch}
          >
            検索
          </Button>
        </Stack>
      }
    />
  );
}
