import { Box, Button, Card, Stack, Typography, useTheme } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ReviewModals } from './ReviewModal';

import { updateOrder } from '@app/adapter/order-service';
import { createFollow } from '@app/adapter/organization-service';
import { WorkResultModal } from '@app/components/Order/WorkResultModal';
import { CardPartOrganization } from '@app/components/Product/CardPartOrganization';
import { CardPartTitle } from '@app/components/Product/CardPartTitle';
import { userAuthInfoSelector } from '@app/domain/app';
import { generateFingerPrint } from '@app/domain/fingerprint';
import {
  errorSnackbarOpenState,
  errorSnackbarSeverityState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { Attribute } from '@app/types/catalog';
import { Order, OrderUpdate } from '@app/types/order';
import { FollowerType } from '@app/types/organization';
import { canOrderWorkResult, canOrderWorkWaiting } from '@app/utils/order';
import { unescapeHtml } from '@app/utils/pattern';

interface OrderListProps {
  attributes: Attribute[];
  items: Order[];
}

export function OrderList({ attributes, items }: OrderListProps): ReactElement {
  const theme = useTheme();
  const navigate = useNavigate();
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setErrorSnackbarSeverity = useSetRecoilState(
    errorSnackbarSeverityState
  );
  const [isWorkResultModal, setIsWorkResultModal] = useState<boolean>(false);
  const [isReviewModal, setIsReviewModal] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    setOrders(items);
  }, [items]);

  const getProduct = useCallback((order: Order) => {
    return order.lineItems &&
      order.lineItems.length > 0 &&
      typeof order.lineItems[0].product === 'object'
      ? order.lineItems[0].product
      : null;
  }, []);

  const handleClickWorkResult = useCallback(
    (event: React.MouseEvent<HTMLElement>, order: Order) => {
      event.stopPropagation();
      setSelectedOrder(order);
      setIsWorkResultModal(true);
    },
    []
  );

  const handleClickWorkWaiting = useCallback(
    (event: React.MouseEvent<HTMLElement>, order: Order) => {
      event.stopPropagation();
      setSelectedOrder(order);
      setIsReviewModal(true);
    },
    []
  );

  // 来店報告
  const updateOrderToWorkResult = useCallback(
    async (
      organizationId: string,
      orderId: string,
      payload: OrderUpdate,
      isFollow?: boolean
    ) => {
      const userId = authInfo?.userId || '';
      const token = authInfo?.accessToken || '';
      const fingerprint = await generateFingerPrint();
      if (!userId || !token || !fingerprint) {
        return;
      }

      try {
        await updateOrder(token, fingerprint, organizationId, orderId, payload);
        if (isFollow) {
          await createFollow(organizationId, token, fingerprint, {
            followerId: userId,
            followerType: FollowerType.USER,
          });
        }
        setErrorSnackbarText('来店完了しました');
        setErrorSnackbarSeverity('success');
        setErrorSnackbarOpen(true);
        setOrders(
          orders.map((order) => {
            return order.id === orderId ? { ...order, ...payload } : order;
          })
        );
        setIsWorkResultModal(false);
      } catch (err) {
        setErrorSnackbarText('更新に失敗しました');
        setErrorSnackbarOpen(true);
      }
    },
    [
      authInfo,
      orders,
      setErrorSnackbarOpen,
      setErrorSnackbarSeverity,
      setErrorSnackbarText,
    ]
  );

  // 投稿完了報告
  const updateOrderToWorkClose = useCallback(
    async (
      organizationId: string,
      orderId: string,
      payload: OrderUpdate,
      isFollow?: boolean
    ) => {
      const userId = authInfo?.userId || '';
      const token = authInfo?.accessToken || '';
      const fingerprint = await generateFingerPrint();
      if (!userId || !token || !fingerprint) {
        return;
      }

      try {
        await updateOrder(token, fingerprint, organizationId, orderId, payload);
        if (isFollow) {
          await createFollow(organizationId, token, fingerprint, {
            followerId: userId,
            followerType: FollowerType.USER,
          });
        }
        setErrorSnackbarText('投稿完了しました');
        setErrorSnackbarSeverity('success');
        setErrorSnackbarOpen(true);
        setOrders(
          orders.map((order) => {
            return order.id === orderId ? { ...order, ...payload } : order;
          })
        );
        setIsReviewModal(false);
      } catch (err) {
        setErrorSnackbarText('更新に失敗しました');
        setErrorSnackbarOpen(true);
      }
    },
    [
      authInfo,
      orders,
      setErrorSnackbarOpen,
      setErrorSnackbarSeverity,
      setErrorSnackbarText,
    ]
  );

  return (
    <Stack
      spacing={{ md: 2, xs: 1 }}
      sx={{ backgroundColor: theme.palette.neutral.greyLight }}
    >
      <Box></Box>
      {orders.map((item) => {
        const product = getProduct(item);
        if (!product) {
          return null;
        }
        return (
          <Card
            key={item.id}
            sx={{ borderRadius: 0, cursor: 'pointer', p: 2 }}
            onClick={() => navigate(`/orders/${item.id}`)}
          >
            <Stack spacing={1}>
              <Typography variant="h5">{unescapeHtml(product.name)}</Typography>
              {product && <CardPartTitle product={product} />}
              {typeof item.organization === 'object' && (
                <CardPartOrganization organization={item.organization} />
              )}
              {/* 来店予定 */}
              {canOrderWorkResult(item) && (
                <Button
                  color="orange"
                  type="submit"
                  variant="contained"
                  fullWidth
                  onClick={(e) => handleClickWorkResult(e, item)}
                >
                  来店しました
                </Button>
              )}
              {/* 投稿待ち */}
              {canOrderWorkWaiting(item) && (
                <Button
                  color="orange"
                  type="submit"
                  variant="contained"
                  fullWidth
                  onClick={(e) => handleClickWorkWaiting(e, item)}
                >
                  投稿報告
                </Button>
              )}
            </Stack>
          </Card>
        );
      })}
      <ReviewModals
        order={selectedOrder}
        open={isReviewModal}
        onClose={() => setIsReviewModal(false)}
        onSubmit={updateOrderToWorkClose}
      />
      <WorkResultModal
        order={selectedOrder}
        open={isWorkResultModal}
        onClose={() => setIsWorkResultModal(false)}
        onSubmit={updateOrderToWorkResult}
      />
    </Stack>
  );
}
