import React, { ReactElement, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { checkToken } from '@app/adapter/auth-service';
import { getUser } from '@app/adapter/user-service';
import { loggedInUserState, userAuthInfoSelector } from '@app/domain/app';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { chatClient, subscribeToStream } from '@app/socket/chat-socket';
import { isError } from '@app/utils/error';
import { isDemanderUser } from '@app/utils/user';

export function LoginCallback(): ReactElement {
  const navigate = useNavigate();
  const setUserAuthInfoState = useSetRecoilState(userAuthInfoSelector);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);

  const location = useLocation();
  const { provider } = useParams<{ provider: string }>();

  useEffect(() => {
    const login = async () => {
      const { search } = location;
      const url = new URLSearchParams(search);
      const code = url.get('code');

      if (code) {
        const {
          data: { userId, accessToken, fp },
        } = await checkToken<{
          accessToken: string;
          fp: string;
          userId: string;
        }>(code, `${provider}Oauth`);

        const user = await getUser(userId, accessToken, fp);
        if (!isDemanderUser(user.data.typeId)) {
          throw new Error('このユーザータイプがログイン出来ません。');
        }

        setLoggedInUser(user.data);
        setUserAuthInfoState({
          accessToken,
          fingerPrint: fp,
          userId,
        });

        // Initialize chat client
        await chatClient.init(accessToken, fp);
        subscribeToStream(chatClient, userId);

        navigate('/home');
      }
    };

    login().catch((error: unknown) => {
      if (isError(error)) {
        setErrorSnackbarText(`ログインに失敗しました, ${error.message}`);
      } else {
        setErrorSnackbarText(`ログインに失敗しました`);
      }
      setErrorSnackbarOpen(true);
      navigate('/login');
    });
  }, [
    provider,
    location,
    navigate,
    setLoggedInUser,
    setUserAuthInfoState,
    setErrorSnackbarOpen,
    setErrorSnackbarText,
  ]);

  return <></>;
}
