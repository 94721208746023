import { atom } from 'recoil';

import {
  Topic,
  TopicOwnerType,
  Message,
  PaginatedTemplates,
} from '@app/types/chat';
import { Pagination } from '@app/types/common';

export const chatTopicsState = atom<Pagination<Topic[]>>({
  default: {
    '@nextLink': '',
    count: 0,
    total: 0,
    value: [
      {
        app: 'user',
        content: '',
        createdAt: '',
        id: '',
        isPublic: true,
        messageTypeId: 'message',
        name: '',
        ownerId: '',
        ownerType: TopicOwnerType.USER,
        typeId: 'channel',
        unreadCount: 0,
        updatedAt: '',
      },
    ],
  },
  key: 'chatTopicsState',
});

export const chatTopicMessageState = atom<Partial<Pagination<Message[]>>>({
  default: {
    '@nextLink': '',
    count: 0,
    total: 0,
    value: [],
  },
  key: 'chatTopicMessageState',
});

export const messagesState = atom<Message[]>({
  default: [],
  key: 'chatMessagesState',
});

export const templatesState = atom<Partial<PaginatedTemplates>>({
  default: {
    '@nextLink': '',
    '@previousLink': '',
    count: 0,
    total: 0,
    value: [],
  },
  key: 'templatesState',
});

export const totalUnreadCountState = atom<number>({
  default: 0,
  key: 'totalUnreadCountState',
});
