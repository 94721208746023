import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Badge,
  Button,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getUserChatTopicListWithFilter } from '@app/adapter/chat-service';
import { TopNavPortal } from '@app/components/TopNav/TopNavPortal';
import { userAuthInfoSelector } from '@app/domain/app';
import { chatTopicsState } from '@app/domain/chat';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { MessageTypeId, Topic } from '@app/types/chat';
import { getDateToDiffJp } from '@app/utils/date';
import { isError } from '@app/utils/error';
import {
  unescapeHtml,
  unescapeHtmlAndConvertNewLinesToHtml,
} from '@app/utils/pattern';

export function Index() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [chatTopics, setChatTopics] = useRecoilState(chatTopicsState);
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const fetchChatTopics = useCallback(
    async (nextLink?: string) => {
      if (!userAuthInfoState || !authInfo) {
        return;
      }
      try {
        setIsLoading(true);
        const result = await getUserChatTopicListWithFilter(
          authInfo.accessToken,
          authInfo.fingerPrint,
          userAuthInfoState.userId,
          { expand: 'message', nextLink, orderBy: 'lastMessageAt desc' }
        );
        // 既存トピックとの重複を排除
        const topicsValue = nextLink
          ? [
              ...chatTopics.value,
              ...result.data.value.filter(
                (newTopic) =>
                  !chatTopics.value.some((topic) => topic.id === newTopic.id)
              ),
            ]
          : result.data.value;

        setChatTopics({
          ...result.data,
          value: topicsValue,
        });
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
        setErrorSnackbarText(`チャットの取得に失敗しました`);
        setErrorSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    },
    [
      authInfo,
      chatTopics.value,
      setChatTopics,
      setErrorSnackbarOpen,
      setErrorSnackbarText,
      userAuthInfoState,
    ]
  );

  const handleClickMoreTopics = useCallback(async () => {
    await fetchChatTopics(chatTopics['@nextLink']);
  }, [fetchChatTopics, chatTopics]);

  const showChatTopics = useMemo(() => {
    return (
      (searchKeyword
        ? chatTopics?.value.filter((topic: Topic) => {
            const includesSearchKeyword = (value: string) => {
              return value ? value.includes(searchKeyword) : false;
            };

            if (!includesSearchKeyword(topic.name)) {
              return false;
            }
            return true;
          })
        : chatTopics?.value) || []
    );
  }, [searchKeyword, chatTopics]);

  useEffect(() => {
    void fetchChatTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopNavPortal title="メッセージ" />
      <Box sx={{ p: 1 }}>
        <TextField
          variant="outlined"
          placeholder="キーワードで検索"
          fullWidth
          onChange={(e) => setSearchKeyword(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Divider />
      <List sx={{ pt: 0 }}>
        {showChatTopics.map((chatTopic: Topic) => {
          const message = chatTopic.lastMessage;
          return (
            <Box key={chatTopic.id} sx={{ minHeight: '66px' }}>
              <ListItem
                sx={{ cursor: 'pointer', pl: 0 }}
                onClick={() => {
                  navigate(`/chats/${chatTopic.id}`);
                }}
              >
                <Checkbox
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: 'lightblue' }}>
                    <PersonOutlineIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="div">
                      <Box>{unescapeHtml(chatTopic.name)}</Box>
                      <Box
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {message?.typeId === MessageTypeId.IMAGE
                          ? '画像'
                          : message?.typeId === MessageTypeId.FILE
                          ? 'ファイル'
                          : unescapeHtmlAndConvertNewLinesToHtml(
                              message?.content || ''
                            )}
                      </Box>
                    </Typography>
                  }
                />
                <Stack alignItems="center">
                  <Typography
                    variant="body3"
                    component="p"
                    sx={{
                      color: theme.palette.neutral.greyDark,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {getDateToDiffJp(chatTopic.updatedAt)}
                  </Typography>
                  <Box>
                    <Badge
                      badgeContent={chatTopic.unreadCount}
                      invisible={chatTopic.unreadCount < 1}
                      color="error"
                    />
                  </Box>
                </Stack>
              </ListItem>
              <Divider />
            </Box>
          );
        })}
        {chatTopics['@nextLink'] && (
          <Box textAlign="center">
            <Button
              disabled={isLoading}
              onClick={handleClickMoreTopics}
              style={{ marginTop: '10px' }}
            >
              さらに読み込み
            </Button>
          </Box>
        )}
      </List>
      {isLoading && (
        <Box textAlign="center" sx={{ pt: 2 }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
