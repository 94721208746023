import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { format, sub, startOfMonth } from 'date-fns';
import { ChangeEvent, ReactElement, useCallback, useMemo } from 'react';

const sortItems = [{ label: '新着順', value: 'createdAt desc' }] as const;

interface SearchConditionProps {
  disabled?: boolean;
  filter: {
    addCancel: boolean;
    month: string;
    orderBy: string;
    tab: string;
  };
  onChange?: (key: string, value: string | boolean) => void;
}

export function SearchCondition({
  disabled,
  filter,
  onChange,
}: SearchConditionProps): ReactElement {
  const monthItems = useMemo(() => {// eslint-disable-line
    const currentDate = new Date();
    const items: { label: string; value: string }[] = [];
    for (let i = 0; i < 12; i++) {
      const monthDate = startOfMonth(sub(currentDate, { months: i }));
      items.push({
        label: format(monthDate, 'yyyy年MM月'),
        value: format(monthDate, 'yyyy-MM'),
      });
    }
    return items;
  }, []);

  const handleChangeMonth = useCallback(// eslint-disable-line
    ({ target }: SelectChangeEvent) => {
      if (onChange) {
        onChange('month', target.value);
      }
    },
    [onChange]
  );

  const handleChangeOrderBy = useCallback(
    ({ target }: SelectChangeEvent) => {
      if (onChange) {
        onChange('orderBy', target.value);
      }
    },
    [onChange]
  );

  const handleChangeAddCancel = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange('addCancel', target.checked);
      }
    },
    [onChange]
  );

  return (
    <Stack spacing={2}>
      {filter.tab === 'afterWork' ? (
        <>
          {/* TODO: カスタムフィールドで年月指定が可能になったら以下のコメントアウトを外す
        <FormControl size="small">
          <Select
            value={filter.month || monthItems[0].value}
            disabled={disabled}
            onChange={handleChangeMonth}
          >
            {monthItems.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
          */}
        </>
      ) : (
        <>
          <FormControl size="small">
            <Select
              value={filter.orderBy || sortItems[0].value}
              disabled={disabled}
              onChange={handleChangeOrderBy}
            >
              {sortItems.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <FormControlLabel
              label="採用見送りを表示"
              control={
                <Checkbox
                  checked={filter.addCancel || false}
                  disabled={disabled}
                  onChange={handleChangeAddCancel}
                />
              }
            />
          </Box>
        </>
      )}
    </Stack>
  );
}
