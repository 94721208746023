import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Order,
  OrderCustomFields,
  OrderStatus,
  OrderUpdate,
} from '@app/types/order';
import { unescapeHtml } from '@app/utils/pattern';

interface WorkResultModalProps {
  onClose: () => void;
  onSubmit?: (
    organizationId: string,
    orderId: string,
    payload: OrderUpdate,
    isFollow: boolean
  ) => void;
  open: boolean;
  order?: Order;
}

export function WorkResultModal({
  onClose,
  onSubmit,
  open,
  order,
}: WorkResultModalProps): ReactElement {
  const [isFollow] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { handleSubmit, reset } = useForm<OrderUpdate>({
    defaultValues: {
      customFields: order?.customFields,
      status: order?.status,
    },
    mode: 'onSubmit',
  });

  const organizationName = useMemo(() => {
    return `${_.get(order?.organization, 'name') || '店舗'}に来店しました`;
  }, [order?.organization]);
  const handleClose = useCallback((): void => {
    onClose();
    reset();
    setIsChecked(false);
  }, [onClose, reset]);

  const onFormSubmit = useCallback(
    async (data: OrderUpdate) => {
      const organizationId = _.get(order?.organization, 'id');
      const orderId = order?.id;
      if (!organizationId || !orderId) {
        return;
      }

      const payload: OrderUpdate = {
        customFields: {
          ...(data.customFields as OrderCustomFields),
          workResultAt: new Date().toISOString(),
        },
        status: OrderStatus.PROCESSING,
      };

      if (onSubmit) {
        onSubmit(organizationId, orderId, payload, isFollow);
      }
    },
    [isFollow, onSubmit, order]
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Typography align="center">来店の確認</Typography>
        <Stack spacing={1} sx={{ mt: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
            }
            label={
              <Typography sx={{ fontWeight: 500 }}>
                {unescapeHtml(organizationName)}
              </Typography>
            }
            sx={{ justifyContent: 'center' }}
          />
          <Button
            color="black"
            variant="contained"
            fullWidth
            disabled={!isChecked}
            onClick={handleSubmit(onFormSubmit)}
          >
            送信
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
