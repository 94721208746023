import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import { createOrder } from '@app/adapter/order-service';
import { ProductDetailInfo } from '@app/components/Product/ProductDetailInfo';
import { BottomAppBar } from '@app/components/Shared/BottomAppBar';
import { TopNavPortal } from '@app/components/TopNav/TopNavPortal';
import {
  afterLoginRouteSelector,
  loggedInUserState,
  userAuthInfoSelector,
} from '@app/domain/app';
import { generateFingerPrint } from '@app/domain/fingerprint';
import { getProduct, getUserOrders } from '@app/domain/network-actions';
import { searchConditionState } from '@app/domain/search';
import {
  errorSnackbarOpenState,
  errorSnackbarSeverityState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { CategoryName, Product, ProductType } from '@app/types/catalog';
import {
  OrderCancelType,
  OrderCreation,
  OrderCustomerCreation,
  OrderCustomFields,
  OrderItemCreation,
  Order,
  OrderStatus,
} from '@app/types/order';
import { getSearchResultUrl } from '@app/utils/catalog';
import { isError } from '@app/utils/error';
import { isUserInfoRegistered } from '@app/utils/user';

export function ProductDetail(): ReactElement {
  const { productId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const setAfterLoginRoute = useSetRecoilState(afterLoginRouteSelector);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setErrorSnackbarSeverity = useSetRecoilState(
    errorSnackbarSeverityState
  );
  const conditionState = useRecoilValue(searchConditionState);
  const [organizationId, setOrganizationId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [order, setOrder] = useState<Order | null | undefined>();
  // カレンダーに選択された日付と時間
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  // 選択された予約人数の状態
  const [selectedPeople, setSelectedPeople] = useState<number | null>(null);

  const orderButtonLabel = useMemo(() => {
    if (!authInfo) return 'ログイン・会員登録して予約する';
    if (!isUserInfoRegistered(loggedInUser))
      return '会員情報を登録して予約する';
    if (order) return '予約済み';
    return '予約する';
  }, [authInfo, loggedInUser, order]);

  const isDisabledOrder = useMemo(() => {
    return !!(
      !isUserInfoRegistered(loggedInUser) ||
      (!isLoading && product && organizationId && order === null)
    );
  }, [isLoading, loggedInUser, order, organizationId, product]);

  const fetchProduct = useCallback(async () => {
    if (!productId) return;
    try {
      const result = await getProduct(productId);
      setProduct(result.data);
      setOrganizationId(result.data.organizationId);
    } catch (e) {
      setErrorSnackbarText('プランが見つかりません');
      setErrorSnackbarOpen(true);
      if (isError(e)) {
        throw new Error(e.message);
      }
    }
  }, [productId, setErrorSnackbarOpen, setErrorSnackbarText]);

  const fetchOrders = useCallback(async () => {
    if (!authInfo?.accessToken || !productId) return;
    try {
      const data = await getUserOrders(authInfo.accessToken, authInfo.userId, {
        productId,
      }).then((v) => v.data);
      setOrder(
        data.value.filter((o) => o.status !== OrderStatus.CANCELED)[0] || null
      );
    } catch (e) {
      setErrorSnackbarText('予約情報が取得できませんでした');
      setErrorSnackbarOpen(true);
    }
  }, [authInfo, productId, setErrorSnackbarOpen, setErrorSnackbarText]);

  useEffect(() => {
    void fetchProduct();
    void fetchOrders();
    // eslint-disable-next-line
  }, []);

  const handleGoBack = useCallback(() => {
    navigate(getSearchResultUrl(conditionState));
  }, [conditionState, navigate]);

  // 日付と予約時間を監視
  const handleDateAndTimeSelected = (
    date: string | null,
    time: string | null
  ) => {
    setSelectedDate(date);
    setSelectedTime(time);
  };

  const handlePeopleChange = (newPeopleCount: number) => {
    setSelectedPeople(newPeopleCount);
  };

  const validateSelectedDateTime = (
    selectedDate: string | null,
    selectedTime: string | null,
    setErrorSnackbarText: (text: string) => void,
    setErrorSnackbarSeverity: (severity: 'error' | 'info' | 'success') => void,
    setErrorSnackbarOpen: (isOpen: boolean) => void,
    setIsLoading: (isLoading: boolean) => void
  ) => {
    const now = new Date();

    if (!selectedDate || !selectedTime || selectedTime.length < 5) {
      console.error('Invalid date or time format');
      return false;
    }

    const [year, month, day] = selectedDate
      .split('/')
      .map((num) => num.padStart(2, '0'));
    const selectedDateTimeString = `${year}-${month}-${day}T${selectedTime}:00`;
    const selectedDateTime = new Date(selectedDateTimeString);

    const diffMs = selectedDateTime.getTime() - now.getTime();
    const diffHours = diffMs / (1000 * 60 * 60);

    if (diffHours < 3) {
      setErrorSnackbarText('予約可能時間が既に過ぎております');
      setErrorSnackbarSeverity('error');
      setErrorSnackbarOpen(true);
      setIsLoading(false);
      return false;
    }
    return true;
  };

  const handleOrderCreate = useCallback(async () => {
    if (!authInfo) {
      setAfterLoginRoute(location.pathname);
      navigate('/login');
      return;
    }
    if (!loggedInUser?.customFields?.familyName) {
      setAfterLoginRoute(location.pathname);
      navigate('/profile/edit');
      return;
    }
    if (!isDisabledOrder) return;
    setIsLoading(true);

    if (!selectedDate || !selectedTime || !selectedPeople) {
      setErrorSnackbarText(
        '選択された日付、時間、または予約人数が入力されていません。'
      );
      setErrorSnackbarSeverity('error');
      setErrorSnackbarOpen(true);
      setIsLoading(false);
      return;
    }

    if (!product) {
      setErrorSnackbarText('プラン情報が取得できませんでした。');
      setErrorSnackbarOpen(true);
      setIsLoading(false);
      return;
    }

    if (
      !validateSelectedDateTime(
        selectedDate,
        selectedTime,
        setErrorSnackbarText,
        setErrorSnackbarSeverity,
        setErrorSnackbarOpen,
        setIsLoading
      )
    ) {
      return;
    }

    const token = authInfo?.accessToken || '';
    const fingerprint = await generateFingerPrint();

    const customer: OrderCustomerCreation = {
      addressLine1: '東京都', //MEMO:ユーザーの住所は削除したが予約時は必須になっているので、全て東京で登録
      age: '',
      email: loggedInUser.email,
      name: `${loggedInUser.customFields.familyName}${loggedInUser.customFields.firstName}`,
      nameKana:
        loggedInUser.customFields.familyNameKana &&
        loggedInUser.customFields.firstNameKana
          ? `${loggedInUser.customFields.familyNameKana}${loggedInUser.customFields.firstNameKana}`
          : '未設定',
      phone: loggedInUser.phoneNumber,
      preferredContactMethod: '',
      preferredTimeToContact: '',
      user: authInfo.userId,
    };
    const items: OrderItemCreation[] = [
      {
        productId: productId || '',
        quantity: 1,
        variantId: product?.variants[0].id,
      },
    ];
    const customFields: OrderCustomFields = {
      appointmentDate: selectedDate || '',
      appointmentTime: selectedTime || '',
      cancelMessage: '',
      cancelRequestAt: '',
      cancelType: OrderCancelType.NOT_CANCEL,
      peopleCount: selectedPeople,
      productDay: product?.customFields?.days || [],
      productType:
        product?.category?.name === CategoryName.SPOT
          ? ProductType.SPOT
          : ProductType.REGULAR_PART_TIME,
      workResult: '',
      workResultAt: '',
    };
    const payload: OrderCreation = {
      customFields,
      customer,
      items,
    };

    try {
      const result = await createOrder(
        token,
        fingerprint,
        organizationId,
        product,
        payload
      );
      setOrder(result.data);

      const statusText =
        result.data.status === 'PENDING'
          ? '予約を受付ました。店舗からの返信をお待ち下さい'
          : '予約完了しました';
      setErrorSnackbarText(statusText);
      setErrorSnackbarSeverity('success');
      setErrorSnackbarOpen(true);
      setIsLoading(false);
    } catch (e) {
      setErrorSnackbarText('予約に失敗しました');
      setErrorSnackbarOpen(true);
      setIsLoading(false);
      if (isError(e)) {
        throw new Error(e.message);
      }
    }
  }, [
    authInfo,
    isDisabledOrder,
    location.pathname,
    loggedInUser,
    navigate,
    organizationId,
    product,
    productId,
    setAfterLoginRoute,
    setErrorSnackbarOpen,
    setErrorSnackbarSeverity,
    setErrorSnackbarText,
    selectedDate,
    selectedTime,
    selectedPeople,
  ]);

  return (
    <>
      <TopNavPortal
        title={_.get(product, 'organization.name')}
        onGoBack={handleGoBack}
      />
      {product && (
        <ProductDetailInfo
          product={product}
          onDateAndTimeSelected={handleDateAndTimeSelected}
          onPeopleCountChange={handlePeopleChange}
        />
      )}
      <BottomAppBar
        children={
          <LoadingButton
            variant="contained"
            color="black"
            loading={isLoading}
            disabled={!isDisabledOrder}
            onClick={handleOrderCreate}
            sx={{ flex: '1 1 0' }}
            fullWidth
          >
            {orderButtonLabel}
          </LoadingButton>
        }
      />
    </>
  );
}
