import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

import {
  HTTP_STATUS_CODE_UNAUTHORIZED,
  //  HTTP_STATUS_CODE_FORBIDDEN,
} from '@app/utils/httpStatusCode';

export const {
  REACT_APP_AUTH_SERVICE: AUTH_SERVICE,
  REACT_APP_USER_SERVICE: USER_SERVICE,
  REACT_APP_CATALOG_SERVICE: CATALOG_SERVICE,
  REACT_APP_ORGANIZATION_SERVICE: ORGANIZATION_SERVICE,
  REACT_APP_ORDER_SERVICE: ORDER_SERVICE,
  REACT_APP_NOTIFICATION_SERVICE: NOTIFICATION_SERVICE,
  REACT_APP_CHAT_SERVICE: CHAT_SERVICE,
  REACT_APP_REVIEW_SERVICE: REVIEW_SERVICE,
} = process.env;

export const authInstance = axios.create({
  baseURL: AUTH_SERVICE,
  responseType: 'json',
});

export const userInstance = axios.create({
  baseURL: USER_SERVICE,
  responseType: 'json',
});

export const catalogInstance = axios.create({
  baseURL: CATALOG_SERVICE,
  responseType: 'json',
});
export const organizationInstance = axios.create({
  baseURL: ORGANIZATION_SERVICE,
  responseType: 'json',
});

export const orderInstance = axios.create({
  baseURL: ORDER_SERVICE,
  responseType: 'json',
});

export const notificationInstance = axios.create({
  baseURL: NOTIFICATION_SERVICE,
  responseType: 'json',
});

export const chatInstance = axios.create({
  baseURL: CHAT_SERVICE,
  responseType: 'json',
});

export const reviewInstance = axios.create({
  baseURL: REVIEW_SERVICE,
  responseType: 'json',
});

//
// Token有効期限切れなど共通処理
//
const INSTANCES = [
  authInstance,
  userInstance,
  catalogInstance,
  organizationInstance,
  orderInstance,
  notificationInstance,
  chatInstance,
  reviewInstance,
];
export const setResponseInterceptor: (onUnauthorized: () => void) => void = (
  onUnauthorized
) => {
  INSTANCES.forEach((instance: AxiosInstance) => {
    instance.interceptors.response.clear();
    instance.interceptors.response.use(
      // onFulfilled
      (response: AxiosResponse) => response,

      // onRejected
      (error) => {
        if (error instanceof AxiosError) {
          const statusCode = error.response?.status;
          if (statusCode === HTTP_STATUS_CODE_UNAUTHORIZED) {
            // TODO refresh token
            onUnauthorized();
          }
        }
        throw error;
      }
    );
  });
};
export const resetResponseInterceptor = () => {
  INSTANCES.forEach((instance: AxiosInstance) => {
    instance.interceptors.response.clear();
  });
};
