import { AxiosPromise } from 'axios';
import * as _ from 'lodash';

import { catalogInstance } from './axios';

import {
  Attribute,
  Category,
  CategoryProductCount,
  LocationProductCount,
  Product,
  ProductLocation,
  ProductLocationTypeKey,
  Top,
} from '@app/types/catalog';
import { Pagination } from '@app/types/common';
import { getAuthorizationHeader } from '@app/utils/authorization';
import { filterSyntaxGen } from '@app/utils/network';

/**
 * Get product list use catalog0service
 * @param token
 * @param fingerprint
 * @param options
 * @returns
 */
export function getProducts(options?: {
  apply?: string;
  attributeIds?: string[];
  categoryIds?: string[];
  expand?: string;
  ids?: string[];
  keyword?: string;
  limit?: number;
  locationCodes?: string[];
  locationIds?: string[];
  nextLink?: string;
  orderBy?: 'updatedAt' | string;
  organizationExpand?: boolean;
  organizationId?: string | string[];
  productIds?: string[];
  variant?: {
    max: string;
    min: string;
    type: string;
  };
  variantExpand?: boolean;
  work?: {
    days: string[];
    timeFrom: string;
    timeTo: string;
    weeks: string[];
  };
}): AxiosPromise<Pagination<Top[]>> {
  if (options?.nextLink) {
    return catalogInstance.get(options?.nextLink);
  }

  const expandParams = [];
  const filterParams = [];
  const urlParams = [['$top', String(options?.limit ?? 3)]];

  if (options?.organizationExpand) {
    expandParams.push('organization');
    expandParams.push('images');
    expandParams.push('attributes');
  }
  if (options?.variantExpand) {
    expandParams.push('variants');
  }

  if (options?.ids?.length) {
    filterParams.push(`id in ${filterSyntaxGen(options?.ids)}`);
  }
  if (options?.categoryIds?.length) {
    filterParams.push(`categoryId in ${filterSyntaxGen(options?.categoryIds)}`);
  }
  if (options?.locationIds?.length) {
    filterParams.push(
      `locationIds in ${filterSyntaxGen(options?.locationIds)}`
    );
  }
  if (options?.attributeIds?.length) {
    filterParams.push(
      `attributes.attributeId in ${filterSyntaxGen(options?.attributeIds)}`
    );
  }
  if (options?.organizationId) {
    filterParams.push(`organizationId eq '${options?.organizationId}'`);
  }
  if (options?.work) {
    if (options?.work?.days.length) {
      filterParams.push(
        `customFields.days in ${filterSyntaxGen(options.work.days)}`
      );
    }
    if (options?.work?.weeks.length) {
      filterParams.push(
        `customFields.repeatWeek in [${options.work.weeks
          .map((p) => Number(p))
          .join(',')}]`
      );
    }
    const timeFrom = options?.work?.timeFrom;
    if (timeFrom) {
      filterParams.push(`customFields.startTime ge '${timeFrom}:00'`);
    }
    const timeTo = options?.work?.timeTo;
    if (timeTo) {
      filterParams.push(`customFields.endTime le '${timeTo}:00'`);
    }
  }

  const productIds = options?.productIds ?? [];
  if (!_.isEmpty(productIds)) {
    filterParams.push(`id in ${filterSyntaxGen(productIds)}`);
  }

  if (options?.keyword) {
    const keywordFilters = [
      `description co '${options.keyword}'`,
      `additionalInformation co '${options.keyword}'`,
      `customFields.workPostalCode co '${options.keyword}'`,
      `customFields.workAddress1 co '${options.keyword}'`,
      `customFields.workAddress2 co '${options.keyword}'`,
      `customFields.access co '${options.keyword}'`,
      `name co '${options.keyword}'`,
      `customFields.prRules co '${options.keyword}'`,
      `customFields.reservationNotes co '${options.keyword}'`,
      `customFields.price co '${options.keyword}'`,
      `tags co '${options.keyword}'`,
    ];
    filterParams.push(`(${keywordFilters.join(' or ')})`);
  }

  filterParams.push(`publication.status in ['ACTIVE']`);

  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  if (options?.apply) {
    urlParams.push(['$apply', options.apply]);
  }
  if (options?.orderBy) {
    urlParams.push(['$orderBy', options.orderBy]);
  }

  return catalogInstance.get<Pagination<Top[]>>(
    `/products?${new URLSearchParams(urlParams).toString()}`
  );
}

/**
 * Get product promoted by super admin
 * @param token
 * @param fingerprint
 * @param options
 * @returns
 *
 * blocks-50d5
 */
export function getPromotedProducts(options?: {
  limit?: number;
  nextLink?: string;
  order?: 'createdAt' | string;
}): AxiosPromise<Pagination<Product[]>> {
  const filterParams = [];
  const urlParams = [['$top', String(options?.limit ?? 50)]];

  // Filter by both Publication Status and isPromoted=true
  filterParams.push(`publication.status in ['ACTIVE']`, `isPromoted eq true`);

  if (options?.nextLink) {
    return catalogInstance.get(options?.nextLink);
  }

  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }

  if (options?.order) {
    urlParams.push(['$orderby', options?.order]);
  }

  // Expanding org because we need the contact details
  urlParams.push(['$expand', 'organization']);

  return catalogInstance.get<Pagination<Product[]>>(
    `/products?${new URLSearchParams(urlParams).toString()}`
  );
}

/**
 * Get one product use catalog-service
 * @param token
 * @param fingerprint
 * @param productId
 * @returns
 */
export function getProduct(productId: string): AxiosPromise<Product> {
  const expandParams = [
    'variants',
    'organization',
    'category',
    'locations',
    'attributes',
  ];
  const urlParams = [];
  urlParams.push(['$expand', expandParams.join(',')]);

  return catalogInstance
    .get(`/products/${productId}?${new URLSearchParams(urlParams).toString()}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

// catalogLocalDataService will request the data from itself
// the data comes from src/adapter/axios/mockData
/**
 * Get category tree
 * @param option can filter
 * @returns category Tree
 */
export function getCategoryTree(option?: {
  name?: string;
}): AxiosPromise<Pagination<Category[]>> {
  const filterParams = [];
  const urlParams = [];

  if (option?.name) {
    filterParams.push(`name eq '${option?.name}'`);
  }

  urlParams.push(['$filter', filterParams.join(' and ')]);
  return catalogInstance.get(
    `/category-tree?${new URLSearchParams(urlParams).toString()}`
  );
}

/**
 * Get Category list
 * @param opts can filter by parentId
 * @returns
 */
export function getCategories(opts?: { parentId?: string[] }): AxiosPromise<{
  '@nextLink': string;
  count: number;
  total: number;
  value: Category[];
}> {
  const filterParams = [];
  const urlParams = [];
  if (opts?.parentId) {
    const parentIdString = opts?.parentId.map((id) => `'${id}'`).join(',');
    filterParams.push(`parentId in [${parentIdString}]`);
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  return catalogInstance.get(
    `/categories?${new URLSearchParams(urlParams).toString()}`
  );
}

/**
 * Get Product Count for Categories
 * @param categoryIds the target Id for getting the number of products
 * @returns
 */
export function getProductCountForCategories(
  categoryIds: string[]
): AxiosPromise<{ value: CategoryProductCount[] }> {
  const categoryIdParam = categoryIds.join(',');
  const URLParam = {
    categoryIds: categoryIdParam,
  };
  return catalogInstance.get(
    `/categories:productCount?${new URLSearchParams(URLParam).toString()}`
  );
}

export function getLocationTree(options: {
  '@nextLink'?: string;
  ids?: string[];
  limit?: number;
  type?: ProductLocationTypeKey;
}): AxiosPromise<{
  '@nextLink': string;
  count: number;
  total: number;
  value: ProductLocation[];
}> {
  const expandParams = [];
  const filterParams = [];

  if (!_.isEmpty(_.get(options, 'categoryIds', []))) {
    expandParams.push(
      `category($filter=id in ${filterSyntaxGen(
        _.get(options, 'categoryIds', [])
      )})`
    );
  }
  if (!_.isEmpty(options.type)) {
    filterParams.push(
      `type in ${filterSyntaxGen([
        _.get(options, 'type') as ProductLocationTypeKey,
      ])}`
    );
  }
  if (!_.isEmpty(options.ids)) {
    filterParams.push(`id in ${filterSyntaxGen(_.get(options, 'ids', []))}`);
  }

  const nextParams = [['$top', String(options?.limit ?? 50)]];

  if (options?.['@nextLink']) {
    nextParams.push(['$nextToken', options?.['@nextLink']]);
  }

  if (filterParams.length > 0) {
    nextParams.push(['$filter', filterParams.join(',')]);
  }
  if (expandParams.length > 0) {
    expandParams.push(['$expand', expandParams.join(',')]);
  }

  return catalogInstance.get(
    `/location-tree?${new URLSearchParams(nextParams).toString()}`
  );
}

export function getLocations(options: {
  '@nextLink'?: string;
  ids?: string[];
  top?: number;
  type?: ProductLocationTypeKey;
}): AxiosPromise<{
  '@nextLink': string;
  count: number;
  total: number;
  value: ProductLocation[];
}> {
  const expandParams = [];
  const filterParams = [];

  // Category Ids
  if (!_.isEmpty(_.get(options, 'categoryIds', []))) {
    expandParams.push(
      `category($filter=id in ${filterSyntaxGen(
        _.get(options, 'categoryIds', [])
      )})`
    );
  }
  // Type Filtering
  if (!_.isEmpty(options.type)) {
    filterParams.push(
      `type in ${filterSyntaxGen([
        _.get(options, 'type') as ProductLocationTypeKey,
      ])}`
    );
  }

  if (options.ids && options.ids.length > 0) {
    const parentIdFilters = options.ids
      .map((id) => `parentId eq '${id}'`)
      .join(' or ');
    filterParams.push(`(${parentIdFilters})`);
  }

  const nextParams = [['$top', String(options?.top ?? 100)]];

  if (options?.['@nextLink']) {
    nextParams.push(['$nextToken', options?.['@nextLink']]);
  }

  if (filterParams.length > 0) {
    nextParams.push(['$filter', filterParams.join(' and ')]);
  }
  if (expandParams.length > 0) {
    expandParams.push(['$expand', expandParams.join(',')]);
  }

  return catalogInstance.get(
    `/locations?${new URLSearchParams(nextParams).toString()}`
  );
}

export function getLocationPrefectures(options: {
  '@nextLink'?: string;
  ids?: string[];
  top?: number;
  type?: ProductLocationTypeKey;
}): AxiosPromise<{
  '@nextLink': string;
  count: number;
  total: number;
  value: ProductLocation[];
}> {
  const filterParams = [];

  if (options.ids && options.ids.length > 0) {
    const idFilters = options.ids.map((id) => `id eq '${id}'`).join(' or ');
    filterParams.push(`(${idFilters})`);
  }

  const nextParams = [['$top', String(options?.top ?? 100)]];

  if (options?.['@nextLink']) {
    nextParams.push(['$nextToken', options?.['@nextLink']]);
  }

  if (filterParams.length > 0) {
    nextParams.push(['$filter', filterParams.join(' and ')]);
  }

  return catalogInstance.get(
    `/locations?${new URLSearchParams(nextParams).toString()}`
  );
}

/**
 * Get Product Count for Locations
 * @param locationIds the target Id for getting the number of products
 * @returns
 */
export function getProductCountForLocations(
  locationIds: string[]
): AxiosPromise<{ value: LocationProductCount[] }> {
  const locationIdParam = locationIds.join(',');
  const URLParam = {
    locationIds: locationIdParam,
  };
  return catalogInstance.get(
    `/locations:productCount?${new URLSearchParams(URLParam).toString()}`
  );
}

export function createProductFavorite(
  userId: string,
  productId: string,
  token: string,
  fingerprint: string
): AxiosPromise<string> {
  const url = `/products/${productId}/follow`;

  return catalogInstance
    .post(
      url,
      {
        followerId: userId,
        followerType: 'user',
      },
      {
        headers: {
          'x-nb-fingerprint': fingerprint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteProductFavorite(
  userId: string,
  productId: string,
  token: string,
  fingerprint: string
): AxiosPromise<boolean> {
  const url = `/products/${productId}/unfollow`;

  return catalogInstance
    .post(
      url,
      {
        followerId: userId,
        followerType: 'user',
      },
      {
        headers: {
          'x-nb-fingerprint': fingerprint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getFavorite(
  token: string,
  fingerprint: string,
  userId: string,
  productId: string
): AxiosPromise<{
  '@nextLink': string;
  '@previous': string;
  count: number;
  total: number;
  value: Product[];
}> {
  return catalogInstance
    .get(
      `/users/${userId}/products/follows?$filter=productId eq '${productId}'`,
      {
        headers: {
          'x-nb-fingerprint': fingerprint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getFavorites(
  token: string,
  fingerprint: string,
  userId: string,
  opts?: {
    '@nextLink'?: string;
    categoryIds?: string[];
    keyword?: string;
    limit?: number;
    locationCodes?: string[];
    locationIds?: string[];
    order?: 'createdAt' | string;
    organizationExpand?: boolean;
    productExpand?: boolean;
    productIds?: string[];
  }
): AxiosPromise<{
  '@nextLink': string;
  '@previous': string;
  count: number;
  total: number;
  value: Product[];
}> {
  const filterParams: string[] = [];
  const expandParams: string[] = [];

  if (opts?.productIds?.length) {
    filterParams.push(`productId in ['${opts?.productIds.join("','")}']`);
  }

  if (opts?.productExpand) {
    expandParams.push('product');
    filterParams.push(`publication.status in ['ACTIVE']`);
  }

  if (opts?.organizationExpand) {
    expandParams.push('organization');
    expandParams.push('images');
    expandParams.push('variant');
  }

  if (!_.isEmpty(opts?.keyword)) {
    filterParams.push(`name co '${opts?.keyword}'`);
  }

  const urlParams: Record<string, string> = _.omitBy(
    {
      $expand: expandParams.join(','),
      $filter: filterParams.join(' and '),
    },
    _.isEmpty
  );

  if (opts?.['@nextLink']) {
    const nextToken = new URLSearchParams(
      _.get(opts?.['@nextLink'].split('?'), '[1]', '')
    ).get('$nextToken');

    if (nextToken) {
      urlParams['$nextToken'] = nextToken;
    }
  }

  return catalogInstance
    .get(
      `/users/${userId}/products/follows?${new URLSearchParams(
        urlParams
      ).toString()}`,
      {
        headers: {
          'x-nb-fingerprint': fingerprint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Get attribute list
 * @param options
 * @returns
 */
export function getAttributes(options?: {
  groupName?: string;
  limit?: number;
  names?: string[];
  nextLink?: string;
}): AxiosPromise<Pagination<Attribute[]>> {
  const filterParams = [];
  const urlParams = [['$top', String(options?.limit ?? 100)]];

  if (options?.groupName) {
    filterParams.push(`groupName eq '${options.groupName}'`);
  }
  if (options?.names) {
    const namesFilter = options.names
      .map((name) => `name eq '${name}'`)
      .join(' or ');
    filterParams.push(`(${namesFilter})`);
  }

  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  urlParams.push(['$orderBy', 'order asc']);

  return catalogInstance.get<Pagination<Attribute[]>>(
    `/attributes?${new URLSearchParams(urlParams).toString()}`
  );
}
