import { AxiosPromise } from 'axios';

import { organizationInstance } from './axios';

import { FollowCreate, Organization } from '@app/types/organization';
import { getAuthorizationHeader } from '@app/utils/authorization';

export function getOrganizations(...ids: string[]) {
  const params = new URLSearchParams([
    // TODO: This parameters spec is not decided yet.
    // TODO: This possibly hits 431, so please please discuss it with library team.
    ['ids', ids.join(',')],
  ]);
  return organizationInstance
    .get(`/organizations?${params.toString()}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrganization(
  organizationId: string
): AxiosPromise<Organization> {
  return organizationInstance
    .get(`/organizations/${organizationId}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Create follow
 */
export function createFollow(
  organizationId: string,
  token: string,
  fingerPrint: string,
  payload: FollowCreate
): AxiosPromise<Organization> {
  return organizationInstance
    .post(`/organizations/${organizationId}/follow`, payload, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
