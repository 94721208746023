import {
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  MenuItem,
  Popover,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { reverse } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  getMessagesByTopic,
  markMessageAsRead,
} from '@app/adapter/chat-service';
import { ChatInputForm } from '@app/components/Chat/ChatInputForm';
import { MessageItem } from '@app/components/Chat/MessageItem';
import { useDeleteLogic } from '@app/components/Chat/useDeleteLogic';
import { TopNavPortal } from '@app/components/TopNav/TopNavPortal';
import { userAuthInfoSelector } from '@app/domain/app';
import { chatTopicsState, messagesState } from '@app/domain/chat';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { Message, MessageReadStatus } from '@app/types/chat';
import { formatRelativeDate } from '@app/utils/date';
import { isError } from '@app/utils/error';

export function ChatDetail() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { topicId } = useParams<{ topicId: string }>();
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const [messages, setMessages] = useRecoilState(messagesState);
  const [chatTopics] = useRecoilState(chatTopicsState);

  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [nextLink, setNextLink] = useState<string>('');

  const {
    messageToDelete,
    anchorPosition,
    handleContextMenu,
    handleCloseContextMenu,
    handleDeleteMessage,
    handleTouchEnd,
    handleTouchStart,
  } = useDeleteLogic(messages, setMessages);

  const loadMoreMessages = async () => {
    await fetchMessages(nextLink);
  };

  const selectedChatTopic = chatTopics.value.find(
    (topic) => topic.id === topicId
  );

  const fetchMessages = useCallback(
    async (next?: string) => {
      if (!authInfo || !topicId) return;

      try {
        setIsLoading(true);
        const result = await getMessagesByTopic(
          authInfo.accessToken,
          authInfo.fingerPrint,
          topicId,
          {
            expand: 'user',
            nextLink: next,
            orderBy: 'createdAt desc',
          }
        );
        const resultValues = reverse(result.data.value);
        setMessages(next ? [...resultValues, ...messages] : resultValues);
        setNextLink(result.data['@nextLink']);
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
        setErrorSnackbarText(`メッセージの取得に失敗しました`);
        setErrorSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    },
    [
      authInfo,
      messages,
      setMessages,
      setErrorSnackbarOpen,
      setErrorSnackbarText,
      topicId,
    ]
  );

  const displayMessage = useCallback(
    async (message: Message) => {
      setMessages([...messages, message]);
    },
    [messages, setMessages]
  );

  useEffect(() => {
    void fetchMessages();
    async function execute() {
      await fetchMessages();
    }
    const intervalId = setInterval(() => {
      void execute();
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markLatestMessageAsRead = useCallback(
    async (message: Message) => {
      if (!authInfo || !topicId) return;
      try {
        if (message.readStatus === MessageReadStatus.UNREAD) {
          await markMessageAsRead(
            authInfo.accessToken,
            authInfo.fingerPrint,
            message.id,
            authInfo.userId
          );
        }
      } catch (error) {
        console.error('Error marking the latest message as read:', error);
      }
    },
    [authInfo, topicId]
  );

  useEffect(() => {
    if (!authInfo) return;
    const latestReceive = reverse([...messages]).find(
      (m) => m.senderId !== authInfo.userId
    );
    if (latestReceive) {
      void markLatestMessageAsRead(latestReceive);
    }
  }, [authInfo, messages, markLatestMessageAsRead]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <>
      <Container>
        <TopNavPortal
          title={selectedChatTopic?.name}
          onGoBack={() => navigate(-1)}
        />
        <Stack spacing={2} pt={3}>
          {nextLink && (
            <Box textAlign="center">
              <Button
                disabled={isLoading}
                onClick={loadMoreMessages}
                sx={{
                  backgroundColor: theme.palette.text.primary,
                  borderRadius: '20px',
                  color: theme.palette.neutral.white,
                }}
              >
                <Typography variant="body2">やり取りを読み込む</Typography>
              </Button>
            </Box>
          )}
          {isLoading && (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
          {
            messages.reduce<{
              elements: JSX.Element[];
              prevDate: string | null;
            }>(
              (acc, message, index) => {
                const formattedDate = formatRelativeDate(message.createdAt);
                if (acc.prevDate !== formattedDate) {
                  acc.elements.push(
                    <Box key={index} textAlign="center">
                      <Chip
                        label={
                          <Typography variant="body2">
                            {formattedDate}
                          </Typography>
                        }
                        sx={{ color: theme.palette.neutral.greyDark }}
                      />
                    </Box>
                  );
                  acc.prevDate = formattedDate;
                }

                acc.elements.push(
                  <MessageItem
                    key={message.id}
                    message={message}
                    onContextMenu={handleContextMenu}
                    onTouchEnd={handleTouchEnd}
                    onTouchStart={handleTouchStart}
                  />
                );

                return acc;
              },
              { elements: [], prevDate: null }
            ).elements
          }
          <div ref={messagesEndRef} />
        </Stack>
      </Container>
      <Toolbar sx={{ minHeight: '100px' }}></Toolbar>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          bottom: 0,
          top: 'auto',
        }}
      >
        <Toolbar sx={{ minHeight: '100px', px: 0 }}>
          <ChatInputForm topicId={topicId} onSent={displayMessage} />
        </Toolbar>
      </AppBar>
      <Popover
        open={Boolean(anchorPosition)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        onClose={handleCloseContextMenu}
      >
        <MenuItem
          onClick={() => {
            if (messageToDelete) {
              void handleDeleteMessage(messageToDelete);
            }
            handleCloseContextMenu();
          }}
        >
          削除する
        </MenuItem>
      </Popover>
    </>
  );
}
