import { Image as ImageIcon } from '@mui/icons-material';
import { Avatar, Box, Card, Stack, Typography, useTheme } from '@mui/material';
import { ReactElement, useMemo } from 'react';

import { CardPartTitle } from './CardPartTitle';

import { Product } from '@app/types/catalog';
import { unescapeHtml } from '@app/utils/pattern';

interface ResultItemCardProps {
  favoriteSearch?: boolean;
  onClick?: () => void;
  product: Product;
  showOrganizationInfo: boolean;
}

export function ResultItemCard({
  onClick,
  product,
  showOrganizationInfo,
}: ResultItemCardProps): ReactElement {
  const theme = useTheme();

  const organization = useMemo(() => {
    return typeof product.organization === 'object'
      ? { ...product.organization }
      : null;
  }, [product.organization]);

  function formatDate(isoDateString?: string) {
    if (!isoDateString) return '';
    const [year, month, day] = isoDateString.slice(0, 10).split('-');
    return `${year}年${month}月${day}日`;
  }

  return (
    <Card
      sx={{
        borderRadius: 0,
        cursor: onClick ? 'pointer' : 'default',
      }}
      data-e2e="searchResult-listItem"
      onClick={onClick}
    >
      {showOrganizationInfo && (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            backgroundColor: theme.palette.neutral.lightYellow,
            p: 1,
          }}
        >
          <Box>
            {organization && organization.iconImageUri ? (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '50px',
                  ml: 'auto',
                }}
              >
                <img
                  alt={organization.name}
                  src={organization.iconImageUri}
                  style={{ width: 60 }}
                />
              </Box>
            ) : (
              <Avatar
                color="primary"
                variant="square"
                sx={{
                  bgcolor: 'secondary.main',
                  height: 50,
                  ml: 'auto',
                  width: 50,
                }}
              >
                <ImageIcon sx={{ color: 'primary.main', opacity: 0.5 }} />
              </Avatar>
            )}
          </Box>
          <Box>
            <Typography variant="body1">
              {unescapeHtml(organization?.name || '')}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {(organization?.customFields?.addressByCity || '') +
                (organization?.addressLine3 || '') +
                unescapeHtml(organization?.customFields?.addressLine4 || '')}
            </Typography>
          </Box>
        </Stack>
      )}

      <Stack spacing={2} sx={{ p: 2 }} direction="row">
        <Stack spacing={1}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {unescapeHtml(product.name)}
          </Typography>
          <Typography variant="body1">
            募集期間：{formatDate(product.publication.since)}〜
            {formatDate(product.publication.until)}
          </Typography>
          <Box sx={{ width: 1 }}>
            {organization && <CardPartTitle product={product} />}
          </Box>
        </Stack>

        <Stack spacing={1} direction="row">
          <Box>
            {product && product.images && product.images.length > 0 ? (
              <Box sx={{ ml: 'auto' }}>
                <img
                  alt={product.images[product.images.length - 1].id.toString()}
                  src={product.images[product.images.length - 1].url}
                  style={{ width: 106 }}
                />
              </Box>
            ) : organization && organization.iconImageUri ? (
              <Box sx={{ ml: 'auto' }}>
                <img
                  alt={organization.name}
                  src={organization.iconImageUri}
                  style={{ width: 106 }}
                />
              </Box>
            ) : null}
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
}
