import AddIcon from '@mui/icons-material/Add';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import React from 'react';

type TemplateModalProps = {
  insertTemplate: (template: string) => void;
  isOpen: boolean;
  onClose: () => void;
};

export const TemplateModal: React.FC<TemplateModalProps> = ({
  isOpen,
  onClose,
  insertTemplate,
}) => {
  const theme = useTheme();
  const [selectedTemplate, setSelectedTemplate] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTemplate((event.target as HTMLInputElement).value);
  };

  const handleInsertTemplate = () => {
    insertTemplate(selectedTemplate);
    setSelectedTemplate('');
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid
        sx={{
          backgroundColor: theme.palette.neutral.white,
          borderRadius: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          padding: '30px',
          width: '80%',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600, pb: 4 }}>
          テンプレートを選択
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="template"
            name="template"
            value={selectedTemplate}
            onChange={handleChange}
          >
            <FormControlLabel
              value="採用についての回答"
              control={<Radio />}
              label="採用についての回答"
            />
            <FormControlLabel
              value="お礼メッセージ"
              control={<Radio />}
              label="お礼メッセージ"
            />
            <FormControlLabel
              value="お詫びメッセージ"
              control={<Radio />}
              label="お詫びメッセージ"
            />
            <FormControlLabel
              value="あいさつメッセージ"
              control={<Radio />}
              label="あいさつメッセージ"
            />
          </RadioGroup>
          <Grid sx={{ alignItems: 'center', display: 'flex', mt: 2 }}>
            <Button sx={{ color: theme.palette.text.primary }}>
              <AddIcon sx={{ color: theme.palette.neutral.greyDark, ml: -1 }} />
              <Typography sx={{ pl: 1 }}>新規テンプレート</Typography>
            </Button>
          </Grid>
        </FormControl>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ fontSize: '0.9rem', marginRight: '10px' }}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleInsertTemplate}
            sx={{ fontSize: '0.9rem' }}
          >
            次へ
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
