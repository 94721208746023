import {
  LocationOn as LocationOnIcon,
  ContentCopy as ContentCopyIcon,
  CalendarToday as CalendarTodayIcon,
  Description as DescriptionIcon,
  Instagram as InstagramIcon,
  LocalDining as LocalDiningIcon,
  Paid as PaidIcon,
  Place as PlaceIcon,
  ReportGmailerrorred as ReportGmailerrorredIcon,
} from '@mui/icons-material';
import {
  Box,
  Container,
  Divider,
  Stack,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
  Tooltip,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ProductCalendar } from './ProductCalendar';
import { TimeSelection } from './TimeSelection';

import { DetailInfoCard } from '@app/components/Product/DetailInfoCard';
import { Loading } from '@app/components/Shared/Loading';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { Attribute, Product, ProductLocationType } from '@app/types/catalog';
import { Order } from '@app/types/order';
import { unescapeHtml } from '@app/utils/pattern';

interface ProductDetailInfoProps {
  attributes?: Attribute[];
  onDateAndTimeSelected?: (date: string | null, time: string | null) => void;
  onPeopleCountChange?: (peopleCount: number) => void;
  orders?: Order[] | null;
  product: Product;
}

export function ProductDetailInfo({
  attributes,
  onDateAndTimeSelected,
  onPeopleCountChange,
  product,
  orders,
}: ProductDetailInfoProps): ReactElement {
  const location = useLocation();
  const currentPath = location.pathname;
  const setSnackbar = useSetSnackbar();
  // カレンダーの現在の月の状態
  const [currentMonth, setCurrentMonth] = useState('');
  // カレンダーに選択された日付と時間
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  // 選択された予約人数の状態
  const [selectedPeople, setSelectedPeople] = useState<number | null>(null);
  const [markedDates, setMarkedDates] = useState<string[]>([]);

  // 予約人数選択肢を生成
  const generatePeopleOptions = (): ReactElement[] => {
    const options: ReactElement[] = [
      <MenuItem key="placeholder" value="">
        選択してください
      </MenuItem>,
    ];
    if (
      product.customFields?.minBookingPeople !== undefined &&
      product.customFields?.maxBookingPeople !== undefined
    ) {
      for (
        let i = product.customFields.minBookingPeople;
        i <= product.customFields.maxBookingPeople;
        i++
      ) {
        options.push(
          <MenuItem key={i} value={i}>
            {i} 名
          </MenuItem>
        );
      }
    }
    return options;
  };

  // 予約人数の変更を処理する関数
  const handlePeopleChange = (event: SelectChangeEvent<number>) => {
    const newPeopleCount = Number(event.target.value);
    setSelectedPeople(newPeopleCount);
    if (onPeopleCountChange) {
      onPeopleCountChange(newPeopleCount);
    }
  };

  const addressFull = [
    product?.locations.find((l) => l.type === ProductLocationType.PREFECTURE)
      ?.name,
    product?.locations.find((l) => l.type === ProductLocationType.CITY)?.name,
    product?.customFields?.workAddress1,
    product?.customFields?.workAddress2,
  ]
    .filter((i) => i)
    .join('');

  const openMap = () => {
    const encodedAddress = encodeURIComponent(addressFull);
    // デバイスの判定
    const userAgent = navigator.userAgent.toLowerCase();
    let mapUrl;
    if (/iphone|ipad|ipod/.test(userAgent)) {
      // iOSはApple Maps
      mapUrl = `http://maps.apple.com/maps?q=${encodedAddress}`;
    } else if (/android/.test(userAgent)) {
      // AndroidはGoogle Maps
      mapUrl = `http://maps.google.com/maps?q=${encodedAddress}`;
    } else {
      // Google MapsのWebバージョン
      mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    }
    window.open(mapUrl, '_blank');
  };

  // 現在の月を YYYY/MM 形式で取得
  useEffect(() => {
    const today = new Date();
    const formattedMonth = `${today.getFullYear()}/${(
      '0' +
      (today.getMonth() + 1)
    ).slice(-2)}`;
    setCurrentMonth(formattedMonth);
  }, []);

  const handleDateChange = (newDate: string | null) => {
    setSelectedDate(newDate);
    if (onDateAndTimeSelected) {
      onDateAndTimeSelected(newDate, selectedTime);
    }
  };

  const handleTimeChange = (newTime: string | null) => {
    setSelectedTime(newTime);
    if (onDateAndTimeSelected) {
      onDateAndTimeSelected(selectedDate, newTime);
    }
  };

  useEffect(() => {
    const today = new Date();
    const formattedMonth = `${today.getFullYear()}/${(
      '0' +
      (today.getMonth() + 1)
    ).slice(-2)}`;
    const formattedToday = format(today, 'yyyy-MM-dd');
    setCurrentMonth(formattedMonth);

    let markedDates = product.customFields?.days || [];
    if (!product.customFields?.canBookToday) {
      markedDates = markedDates.filter((date) => date !== formattedToday);
    }

    setMarkedDates(markedDates);
  }, [product.customFields?.days, product.customFields?.canBookToday]);

  // 現在の日付と選択された日付のフォーマット
  const currentDateFormatted = format(new Date(), 'yyyy-MM-dd');
  const selectedDateFormatted = selectedDate
    ? format(new Date(selectedDate), 'yyyy-MM-dd')
    : '';

  const contentPart = (
    title: string,
    items: { label: string; value?: string | ReactElement }[],
    icon: React.ReactElement
  ): ReactElement => {
    const data = items.filter((i) => i.value);
    return (
      <>
        {data.length > 0 && (
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {React.cloneElement(icon, {
                className: 'material-symbols-outlined',
              })}

              <Typography fontWeight={500}>{title}</Typography>
            </Stack>
            <Divider sx={{ borderColor: 'text.primary' }} />
            <Stack spacing={2}>
              {data.map(({ label, value }, index) => (
                <Stack
                  key={index}
                  direction="column"
                  alignItems="left"
                  spacing={1}
                  flexWrap="wrap"
                >
                  <Typography fontWeight={500}>{label}</Typography>
                  {typeof value === 'string'
                    ? value.split('\\n').map((v, index) => (
                        <Typography key={index} fontWeight={300}>
                          {v}
                        </Typography>
                      ))
                    : value && value}
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </>
    );
  };

  return (
    <>
      {!product ? (
        <Loading />
      ) : (
        <Box>
          <DetailInfoCard product={product} />
          <Container sx={{ mb: 10, pb: 10, pt: 1 }}>
            <Stack spacing={3}>
              {currentPath.startsWith('/products') && (
                <Grid>
                  <Grid display="flex" sx={{ pb: 2 }}>
                    <CalendarTodayIcon />
                    <Typography fontWeight={500} sx={{ pl: 1 }}>
                      予約日を選択
                    </Typography>
                  </Grid>
                  <Divider sx={{ borderColor: 'text.primary', mb: 2 }} />
                  <ProductCalendar
                    month={currentMonth} // 現在の月を指定
                    value={selectedDate} // 選択されている日付
                    onChange={handleDateChange}
                    markedDate={markedDates} //選択可能な日付
                  />
                  {product.customFields?.startTime &&
                    product.customFields?.endTime && (
                      <Grid sx={{ pt: 5 }}>
                        <Typography fontWeight={500} sx={{ pb: 1 }}>
                          予約時間
                        </Typography>
                        <TimeSelection
                          startTime={product.customFields.startTime}
                          endTime={product.customFields.endTime}
                          onChange={handleTimeChange}
                          currentDate={currentDateFormatted}
                          selectedDate={selectedDateFormatted}
                        />
                      </Grid>
                    )}
                  {product.customFields?.minBookingPeople &&
                    product.customFields?.maxBookingPeople && (
                      <Grid sx={{ pt: 2 }}>
                        <Typography fontWeight={500} sx={{ pb: 1 }}>
                          予約人数
                        </Typography>
                        <FormControl fullWidth>
                          <InputLabel id="people-select-label">
                            予約人数
                          </InputLabel>
                          <Select
                            labelId="people-select-label"
                            id="people-select"
                            value={
                              selectedPeople === null ? '' : selectedPeople
                            }
                            label="予約人数"
                            onChange={handlePeopleChange}
                          >
                            {generatePeopleOptions()}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                </Grid>
              )}
              {currentPath.startsWith('/orders') && (
                <Grid>
                  {orders &&
                    orders.length > 0 &&
                    contentPart(
                      '予約内容',
                      [
                        {
                          label: '予約日',
                          value: orders[0].customFields?.appointmentDate,
                        },
                        {
                          label: '予約時間',
                          value: orders[0].customFields?.appointmentTime,
                        },
                        {
                          label: '予約人数',
                          value: orders[0].customFields?.peopleCount
                            ? `${orders[0].customFields.peopleCount}名`
                            : '',
                        },
                      ],
                      <DescriptionIcon />
                    )}
                </Grid>
              )}

              {contentPart(
                '金額',
                [
                  {
                    label: '',
                    value:
                      product.customFields.price != null &&
                      !isNaN(Number(product.customFields.price))
                        ? `${new Intl.NumberFormat('en-US').format(
                            Number(product.customFields.price)
                          )} 円/人`
                        : `${unescapeHtml(
                            product.customFields.price || ''
                          )} 円/人`,
                  },
                ],
                <PaidIcon />
              )}

              {contentPart(
                'プラン内容',
                [
                  {
                    label: '',
                    value: unescapeHtml(product.description),
                  },
                ],
                <LocalDiningIcon />
              )}
              {contentPart(
                'PR条件',
                [
                  {
                    label: '',
                    value: unescapeHtml(product.customFields?.prRules),
                  },
                ],
                <InstagramIcon />
              )}
              {contentPart(
                '予約時の注意事項',
                [
                  {
                    label: '',
                    value: unescapeHtml(product.customFields?.reservationNotes),
                  },
                ],
                <ReportGmailerrorredIcon />
              )}
              {contentPart(
                'その他情報',
                [
                  {
                    label: '',
                    value: unescapeHtml(product.additionalInformation),
                  },
                ],
                <DescriptionIcon />
              )}
              {contentPart(
                '来店場所',
                [
                  {
                    label: '店舗名',
                    value: unescapeHtml(
                      _.get(product, 'organization.name') || ''
                    ),
                  },
                  {
                    label: '住所',
                    value: (
                      <>
                        {product?.customFields?.workPostalCode && (
                          <Typography fontWeight={300}>
                            {product?.customFields?.workPostalCode}
                          </Typography>
                        )}
                        <Typography fontWeight={300} component="div">
                          {product?.locations?.find(
                            (i) => i.type === ProductLocationType.PREFECTURE
                          )?.name || ''}
                          {product?.locations?.find(
                            (i) => i.type === ProductLocationType.CITY
                          )?.name || ''}
                          <>
                            <Typography component="span">
                              {`${product?.customFields?.workAddress1 || ''}${
                                product?.customFields?.workAddress2 || ''
                              }`}
                            </Typography>
                            <Tooltip title="コピー" placement="top">
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                  void navigator.clipboard
                                    .writeText(addressFull)
                                    .then(() => {
                                      setSnackbar(
                                        true,
                                        'コピーしました',
                                        'success'
                                      );
                                    });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="地図" placement="top">
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={openMap}
                              >
                                <LocationOnIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        </Typography>
                      </>
                    ),
                  },
                  {
                    label: 'アクセス',
                    value: unescapeHtml(product.customFields?.access || ''),
                  },
                ],
                <PlaceIcon />
              )}
            </Stack>
          </Container>
        </Box>
      )}
    </>
  );
}
