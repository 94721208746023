import { Search } from '@mui/icons-material';
import { Stack, OutlinedInput, useTheme, SxProps, Theme } from '@mui/material';

interface SearchBoxProps {
  defaultKeyword?: string;
  onSearch(keyword: string): void;
  sx?: SxProps<Theme>;
}
export function SearchBox(props: SearchBoxProps) {
  const { onSearch, defaultKeyword, sx } = props;
  const theme = useTheme();
  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
      const keyword = event.target.value;
      onSearch(keyword);
    }
  };
  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={sx}>
      <Search sx={{ color: theme.palette.neutral.green }} />
      <OutlinedInput
        onKeyUp={handleKeyUp}
        defaultValue={defaultKeyword || ''}
        placeholder="キーワードで検索"
        color="success"
        fullWidth
        inputProps={{
          sx: {
            p: 1,
          },
        }}
        sx={{
          backgroundColor: 'secondary.main',
          borderRadius: '20px',
        }}
      />
    </Stack>
  );
}
