import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Dialog as BaseDialog,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactElement, useCallback } from 'react';

import { BottomAppBar } from '@app/components/Shared/BottomAppBar';

interface DialogProps {
  action?: ReactElement;
  content?: ReactElement;
  onClose: () => void;
  open: boolean;
  title?: string;
}

export function Dialog({
  action,
  content,
  onClose,
  open,
  title,
}: DialogProps): ReactElement {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = useCallback((): void => {
    onClose();
  }, [onClose]);

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      fullScreen={isFullScreen}
      fullWidth
      maxWidth={isFullScreen ? false : 'xs'}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography fontSize={'1.125rem'} fontWeight={700}>
          {title}
        </Typography>
        <IconButton color="inherit" onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box>{content}</Box>
      {action && (
        <>
          {isFullScreen ? (
            <BottomAppBar children={action} />
          ) : (
            <Box px={2} display="flex" minHeight="62px" alignItems="center">
              {action}
            </Box>
          )}
        </>
      )}
    </BaseDialog>
  );
}
