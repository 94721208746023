import { atom, selector } from 'recoil';

import { getCategories } from '@app/adapter/catalog-service';
import {
  Attribute,
  Category,
  CategoryStatus,
  Product,
  ProductLocation,
  ProductType,
  ProductTypeKey,
  SearchCondition,
  SearchResultProducts,
} from '@app/types/catalog';

/**
 * blocks-1d31
 *
 * Fetch and store active categories.
 *
 * @example
 * ```
 * const categoriesLoadable = useRecoilValueLoadable(activeCategoriesSelector);
 * if (categoriesLoadable.state === 'hasError') {
 *   throw 'Error';
 * }
 * if (categoriesLoadable.state === 'hasValue') {
 *   console.log('Ready to go');
 * }
 * ```
 */
export const activeCategoriesSelector = selector<Category[]>({
  get: async () => {
    const {
      data: { value },
    } = await getCategories();
    // Create an active categories list.
    const filter = (categories: Category[]): Category[] => {
      const filtered: Category[] = [];
      for (const category of categories) {
        if (category.status !== CategoryStatus.ACTIVE) continue;
        const newCategory = { ...category };
        if (newCategory.children) {
          newCategory.children = filter(newCategory.children);
        }
        filtered.push(newCategory);
      }
      return filtered;
    };
    return filter(value);
  },
  key: 'activeCategoriesSelector',
});

export const categoryState = atom<Category[]>({
  default: [],
  key: 'categoryState',
});

export const locationRegionsState = atom<ProductLocation[]>({
  default: [],
  key: 'locationRegionsState',
});

export const locationPrefectureState = atom<ProductLocation[]>({
  default: [],
  key: 'locationPrefectureState',
});

export const attributesState = atom<Attribute[]>({
  default: [],
  key: 'attributesState',
});

export const pickupAttributesState = atom<Attribute[]>({
  default: [],
  key: 'pickupAttributesState',
});

export const searchResultProductsState = atom<SearchResultProducts>({
  default: {
    data: [],
    nextLink: '',
    scrollY: 0,
    total: 0,
  },
  key: 'searchResultProductsState',
});

export const searchConditionState = atom<SearchCondition | null>({
  default: null,
  key: 'searchConditionState',
});

export const searchProductsState = atom<Product[]>({
  default: [],
  key: 'searchProductsState',
});

export const searchProductTotalState = atom<number>({
  default: 0,
  key: 'searchProductTotalState',
});

export const searchProductTypeState = atom<ProductTypeKey>({
  default: ProductType.REGULAR_PART_TIME,
  key: 'searchProductTypeState',
});
