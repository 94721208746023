import { Button, Typography, useTheme } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

import { CategoryIcon } from '@app/components/Category/CategoryIcon';

export interface CategoryIconButtonProps {
  description?: string;
  icon: {
    size?: OverridableStringUnion<'small' | 'inherit' | 'medium' | 'large'>;
    type: string;
    value: string;
  };
  name: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function CategoryIconButton(props: CategoryIconButtonProps) {
  const { name, description, icon, onClick } = props;
  const theme = useTheme();
  return (
    <Button
      variant="outlined"
      fullWidth
      sx={{
        borderColor: theme.palette.neutral.green,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        justifyItems: 'center',
        padding: '14px 8px',
      }}
      onClick={onClick}
    >
      <CategoryIcon variation={icon.value} size={icon.size} type={icon.type} />
      <Typography
        component="span"
        variant="subtitle2"
        whiteSpace="pre-wrap"
        sx={{
          color: 'text.primary',
          position: 'relative',
        }}
      >
        {name}
      </Typography>
      {description && (
        <Typography component="span" variant="subtitle2">
          {description}
        </Typography>
      )}
    </Button>
  );
}
