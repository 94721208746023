import { FilePresentRounded as FilePresentRoundedIcon } from '@mui/icons-material';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactElement } from 'react';

type Props = {
  name?: string;
  size: number;
};

export function MessageTypeFile({ name, size }: Props): ReactElement {
  const theme = useTheme();

  return (
    <Chip
      variant="outlined"
      label={
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack
            sx={{
              backgroundColor: theme.palette.neutral.greyDark,
              borderRadius: '5px',
              p: '10px',
            }}
          >
            <FilePresentRoundedIcon
              sx={{
                color: theme.palette.neutral.white,
                fontSize: '1.188rem',
              }}
            />
          </Stack>
          <Typography variant="body2" component="div">
            <Box sx={{ color: theme.palette.text.primary }}>
              {name || '無題'}
            </Box>
            <Box sx={{ color: theme.palette.neutral.greyDark }}>
              {`${(size / 1024).toFixed(2)}KB`}
            </Box>
          </Typography>
        </Stack>
      }
      sx={{ minHeight: '60px' }}
    />
  );
}
