import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Select,
  Stack,
  MenuItem,
  ListItemText,
} from '@mui/material';
import _ from 'lodash';
import { ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { resetResponseInterceptor } from '@app/adapter/axios';
import { resendVerificationEmail } from '@app/adapter/user-service';
import { BirthdaySelector } from '@app/components/Shared/BirthdaySelector';
import { FormRequireLabel } from '@app/components/Shared/FormRequiredLabel';
import { SocialMediaURL } from '@app/components/Shared/SocialMediaURL';
import {
  useClearAuthStateAndStorage,
  loggedInUserState,
  userAuthInfoSelector,
} from '@app/domain/app';
import { userUpdateState } from '@app/domain/user';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { UserUpdateForm } from '@app/schemas/user';
import { theme } from '@app/theme';
import { UserCustomFields, UserUpdate } from '@app/types/user';
import { socialMediaOptions } from '@app/utils/constants';
import { getValidateSocialMediaUrlsMessage } from '@app/utils/socialMediaValidator';

export function Edit(): ReactElement {
  const navigate = useNavigate();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const userInfo = useRecoilValue(userAuthInfoSelector);
  const setSnackbar = useSetSnackbar();
  const [updateSharedState, setUpdateSharedState] =
    useRecoilState(userUpdateState);
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { isValid },
  } = useForm<UserUpdate>({
    defaultValues: UserUpdateForm.defaultValues,
    mode: 'onChange',
    resolver: UserUpdateForm.resolver,
  });

  // 初期値をセットする
  useEffect(() => {
    // キャッシュ値にある入力情報もしくは登録情報を参照する
    const userValues = updateSharedState.customFields?.familyName
      ? updateSharedState
      : loggedInUser;
    const defaultValues = UserUpdateForm.defaultValues;
    // プロパティ単位で値をセットする
    (Object.keys(defaultValues) as (keyof UserUpdate)[]).forEach((key) => {
      // キャッシュ値がなければデフォルト値を参照する
      const value = _.get(userValues, key) || defaultValues[key];
      if (key === 'customFields') {
        // カスタムフィールドの場合
        (
          Object.keys(defaultValues.customFields) as (keyof UserCustomFields)[]
        ).forEach((customKey) => {
          // キャッシュ値がなければデフォルト値を参照する
          const cValue =
            _.get(value, customKey) || defaultValues.customFields[customKey];
          setValue(`${key}.${customKey}`, cValue);
        });
      } else if (typeof value !== 'object') {
        setValue(key, value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: UserUpdate) => {
    const urls = data.customFields.socialMediaUrl || [];
    const selectedPlatforms = data.customFields.usedSocialMediaPlatforms || [];

    const validationMessage = getValidateSocialMediaUrlsMessage(
      urls,
      selectedPlatforms
    );

    if (validationMessage) {
      setError('customFields.socialMediaUrl', {
        message: validationMessage as string,
        type: 'domainMatch',
      });
      return;
    }

    setUpdateSharedState(data);
    navigate('/profile/edit-confirm');
  };

  // 会員登録しない場合はlogout状態にする
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();
  const handleClickSkipRegistration = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    clearAuthStateAndStorage();
    resetResponseInterceptor();
    navigate('/home');
  };

  const handleResendVerificationEmail = () => {
    if (!userInfo?.userId || !userInfo?.accessToken) return;

    resendVerificationEmail(
      userInfo.userId,
      userInfo.accessToken,
      userInfo.fingerPrint
    )
      .then(() => {
        setSnackbar(true, '認証メールを再送信しました。', 'success');
      })
      .catch((error) => {
        setSnackbar(true, 'メールの再送信に失敗しました。', 'error');
      });
  };

  return (
    <Container fixed>
      {!loggedInUser && (
        <Typography variant="body2" textAlign="center" mt={2}>
          会員登録せずに求人を確認したい方は
          <a href="/" onClick={handleClickSkipRegistration}>
            こちら
          </a>
          へ
        </Typography>
      )}
      <Typography
        variant="h5"
        fontWeight={700}
        textAlign="center"
        sx={{ my: 4 }}
      >
        インフルエンサー情報登録
      </Typography>
      {loggedInUser?.emailVerified === false && (
        <>
          <Typography
            variant="body1"
            textAlign="center"
            color={theme.palette.warning.main}
            sx={{ my: 3 }}
            fontWeight={'bold'}
          >
            メール認証が完了していないようです。
            下記ボタンをクリックして、認証メールを再度送信することが出来ます。
          </Typography>
          <Button
            variant="outlined"
            fullWidth
            onClick={handleResendVerificationEmail}
            sx={{
              '&:hover': {
                backgroundColor: '#f5f5f5',
                borderColor: theme.palette.warning.dark,
              },
              backgroundColor: '#ffffff',
              borderColor: theme.palette.warning.main,
              color: theme.palette.warning.main,
              mb: 4,
            }}
          >
            認証メールを再送信する
          </Button>
        </>
      )}
      <Box mb={3}>
        インフルエンサー情報をご記入のうえ送信してください。
        <Box>
          <Typography component="span" color="error">
            *
          </Typography>
          は必須事項です
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Box width={1}>
                <FormRequireLabel label="姓" require={true} />
                <Controller
                  name="customFields.familyName"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'姓を入力'}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box width={1}>
                <FormRequireLabel label="名" require={true} />
                <Controller
                  name="customFields.firstName"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'名を入力'}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <FormRequireLabel label="生年月日" require={true} />
            <BirthdaySelector control={control} name="customFields.birthday" />
          </Grid>

          <Grid item xs={12}>
            <FormRequireLabel label="連絡先電話番号" require={true} />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="tel"
                  error={!!error}
                  helperText={error?.message}
                  margin="dense"
                  placeholder="連絡先電話番号（ハイフンなし）で入力"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <FormRequireLabel label="SNS名" require={true} />
            <Controller
              name="customFields.socialMediaName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  margin="dense"
                  placeholder="SNS名を入力"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <FormRequireLabel label="利用SNS" require={true} />
            <Controller
              name="customFields.usedSocialMediaPlatforms"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <Select
                    {...field}
                    labelId="social-media-select-label"
                    multiple
                    value={field.value ?? []}
                    renderValue={(selected) => selected.join(', ')}
                    margin="dense"
                    error={!!error}
                  >
                    {socialMediaOptions.map((value, index) => (
                      <MenuItem key={index} value={value}>
                        <Checkbox checked={field.value?.includes(value)} />{' '}
                        <ListItemText primary={value} />
                      </MenuItem>
                    ))}
                  </Select>
                  {!!error && <FormHelperText>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <FormRequireLabel label="利用SNSのURL" require={true} />
            <SocialMediaURL
              control={control}
              name="customFields.socialMediaUrl"
            />
          </Grid>

          <Grid item xs={12}>
            <FormRequireLabel label="特記事項" />
            <Controller
              name="customFields.notes"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  margin="dense"
                  rows={2}
                  placeholder="特記事項を入力"
                  multiline
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              disabled={!isValid}
            >
              送信
            </Button>
          </Grid>
        </Grid>
      </form>
      {!loggedInUser && (
        <Typography variant="body2" textAlign="center" mt={3}>
          会員登録せずに求人を確認したい方は
          <a href="/" onClick={handleClickSkipRegistration}>
            こちら
          </a>
          へ
        </Typography>
      )}
    </Container>
  );
}
