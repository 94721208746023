import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getAttributes } from '@app/adapter/catalog-service';
import {
  createNewTopic,
  getTopicSubscriptions,
  getUserChatTopicListWithFilter,
} from '@app/adapter/chat-service';
import { ProductDetailInfo } from '@app/components/Product/ProductDetailInfo';
import { BottomAppBar } from '@app/components/Shared/BottomAppBar';
import { TopNavPortal } from '@app/components/TopNav/TopNavPortal';
import { userAuthInfoSelector } from '@app/domain/app';
import { getOrder } from '@app/domain/network-actions';
import { attributesState } from '@app/domain/search';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { LoadableState } from '@app/types/common';
import { Order } from '@app/types/order';
import { isError } from '@app/utils/error';

export function OrderDetail(): ReactElement {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const [attributesSharedState, setAttributesSharedState] =
    useRecoilState(attributesState);
  const [order, setOrder] = useState<Order>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );

  const product = useMemo(() => {
    return typeof order?.lineItems[0].product === 'object'
      ? { ...order.lineItems[0].product, organization: order.organization }
      : null;
  }, [order]);

  const fetchOrder = useCallback(async () => {
    const userId = authInfo?.userId;
    const accessToken = authInfo?.accessToken;
    if (
      !userId ||
      !accessToken ||
      !orderId ||
      loadable === LoadableState.LOADING
    ) {
      return;
    }
    setLoadable(LoadableState.LOADING);
    try {
      const result = await getOrder(accessToken, userId, orderId);
      setOrder(result.data);
      setLoadable(LoadableState.HAS_VALUE);
    } catch (e) {
      setLoadable(LoadableState.HAS_ERROR);
      setErrorSnackbarText('予約情報が取得できませんでした');
      setErrorSnackbarOpen(true);
      if (isError(e)) {
        throw new Error(e.message);
      }
    }
  }, [authInfo, loadable, orderId, setErrorSnackbarOpen, setErrorSnackbarText]);

  const fetchAttributes = useCallback(async () => {
    if (attributesSharedState.length) return;
    try {
      const result = await getAttributes();
      setAttributesSharedState(result.data.value);
    } catch (err) {
      setErrorSnackbarText('ジャンルの取得に失敗しました');
      setErrorSnackbarOpen(true);
    }
  }, [
    setAttributesSharedState,
    attributesSharedState,
    setErrorSnackbarText,
    setErrorSnackbarOpen,
  ]);

  useEffect(() => {
    void fetchOrder();
    void fetchAttributes();
    // eslint-disable-next-line
  }, []);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const organizationName =
    typeof order?.organization === 'object' && order?.organization?.name
      ? order.organization.name
      : 'Unknown';

  const organizationOwnerId =
    typeof order?.organization === 'object' &&
    order?.organization?.customFields.ownerUserId
      ? order.organization.customFields.ownerUserId
      : 'Unknown';

  // メッセージボタン押下時にTopicがあればチャット画面へ、なければTopicとサブスク作成した上でチャット画面へ
  const handleClickOpenChat = async () => {
    if (!authInfo) return;

    setIsLoading(true);
    try {
      const response = await getUserChatTopicListWithFilter(
        authInfo.accessToken,
        authInfo.fingerPrint,
        authInfo.userId,
        { expand: 'user' }
      );
      const topics = response.data.value;

      const subscriptionsPromises = topics.map((topic) =>
        getTopicSubscriptions(
          authInfo.accessToken,
          authInfo.fingerPrint,
          topic.id
        )
      );
      const subscriptionsResponses = await Promise.all(subscriptionsPromises);
      const allSubscriptions = subscriptionsResponses.flatMap(
        (response) => response.value
      );

      const matchingTopic = topics.find((topic) =>
        allSubscriptions.some((sub) => sub.userId === organizationOwnerId)
      );

      if (matchingTopic) {
        navigate(`/chats/${matchingTopic.id}`);
      } else {
        const newTopicResponse = await createNewTopic(
          authInfo.accessToken,
          authInfo.fingerPrint,
          authInfo.userId,
          organizationOwnerId,
          organizationName
        );
        navigate(`/chats/${newTopicResponse.data.id}`);
      }
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <TopNavPortal title="プラン詳細" onGoBack={handleGoBack} />
      {product && (
        <ProductDetailInfo
          product={product}
          attributes={attributesSharedState}
          orders={order ? [order] : null}
        />
      )}
      <BottomAppBar
        children={
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingButton
              variant="outlined"
              color="primary"
              loading={isLoading}
              fullWidth
              onClick={handleClickOpenChat}
              sx={{ flex: '1 1 0' }}
            >
              メッセージを送る
            </LoadingButton>
          </Grid>
        }
      />
    </>
  );
}
