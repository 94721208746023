import {
  Order,
  OrderCancelType,
  OrderCancelTypeKey,
  OrderStatus,
  OrderStatusKey,
} from '@app/types/order';

export const getCancelType = (status?: OrderStatusKey): OrderCancelTypeKey => {
  if (status === OrderStatus.PENDING) {
    return OrderCancelType.DEMAND_STATUS_PENDING;
  } else if (status === OrderStatus.ACCEPTED) {
    return OrderCancelType.DEMAND_STATUS_ACCEPTED;
  }
  return OrderCancelType.NOT_CANCEL;
};

export const canOrderCancel = (order?: Order): boolean => {
  if (
    order?.status === OrderStatus.PENDING ||
    order?.status === OrderStatus.ACCEPTED
  ) {
    return true;
  }
  return false;
};

export const disableOrderButton = (order?: Order): boolean => {
  if (
    order?.status === OrderStatus.CANCELED ||
    order?.status === OrderStatus.CLOSED ||
    order?.status === OrderStatus.WAITING ||
    order?.status === OrderStatus.PROCESSING
  ) {
    return true;
  }
  return false;
};

export const canOrderWorkResult = (order?: Order): boolean => {
  if (order?.status === OrderStatus.ACCEPTED) {
    return true;
  }
  return false;
};

export const canOrderWorkWaiting = (order?: Order): boolean => {
  if (order?.status === OrderStatus.PROCESSING) {
    return true;
  }
  return false;
};
