import { Container, Typography, Box, Link } from '@mui/material';

const marginBottom2Style = {
  mb: 2,
};

const marginBottom5Style = {
  mb: 5,
};

export function PrivacyPolicyPage() {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 7, textAlign: 'center' }}>
        <Typography
          variant="h1"
          gutterBottom
          sx={{ fontSize: '2rem', fontWeight: 'bold' }}
        >
          プライバシーポリシー
        </Typography>

        <Box sx={{ textAlign: 'left' }}>
          <Typography paragraph sx={{ mb: 8, mt: 10 }}>
            株式会社F.lab（以下「当社」）は、当社のサービス利用者（以下「利用者」）の個人情報に関し、以下のとおりプライバシーポリシーを定め、
            個人情報保護の仕組みを構築し、全メンバーに個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
          </Typography>
          <Typography paragraph sx={marginBottom2Style}>
            1.
            個人情報を取得するに当たっては、次の目的の達成に必要な限度において個人情報を取得致します。そのために必要な措置を講じます。
          </Typography>
          <Box sx={{ mb: 3, ml: 2 }}>
            <Typography paragraph sx={marginBottom2Style}>
              (1) 本件サービスへの会員手続き
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (2) 利用者へのサービス提供
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (3) 利用者からの問い合わせ対応
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (4) 利用者への連絡
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (5)
              郵送、電話、電子メール等の方法により、当社又は当社グループ会社の商品、サービスのご案内
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (6)
              個人を識別することができない形式に加工した上で、統計データを作成するなど当社サービスの利用促進のため
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (7) 外部サービス連携機能利用時における本人確認のため
            </Typography>
          </Box>
          <Typography paragraph sx={marginBottom5Style}>
            2.
            保有する個人情報を適切な方法で管理し、利用者の同意、その他法令で認められる場合を除き、第三者に開示・提供致しません。
          </Typography>
          <Typography paragraph sx={marginBottom5Style}>
            3.
            保有する個人情報を利用目的に応じた必要な範囲内において、正確、かつ、最新の状態で管理し、個人情報の漏えい、滅失又は毀損などのおそれに対して、合理的な安全対策を講じ、予防並びに是正に努めます。
          </Typography>
          <Typography paragraph sx={marginBottom5Style}>
            4.
            より良いサービスを提供するため個人情報の取り扱いを外部へ委託することがあります。その場合、漏えいや第三者への提供を行わない等を契約により義務づけ、委託先に対する適切な管理を実施致します。
          </Typography>
          <Typography paragraph sx={marginBottom5Style}>
            5.
            保有する個人情報についての苦情・相談及び開示、削除、訂正の請求は、当社のお問合わせ窓口に連絡頂くことにより、これに対応致します。
          </Typography>
          <Typography paragraph sx={marginBottom5Style}>
            6.
            個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守致します。
          </Typography>

          <Typography paragraph sx={{ mt: 5, my: 8 }}>
            お問い合わせ
            <br />
            当社の個人情報の取扱に関するお問い合わせは下記までご連絡ください。
            <br />
            株式会社 F . l a b
            <br />
            〒1 0 7 - 0 0 5 2 東京都港区赤坂 3 - 1 1 - 1 5 V o r t 赤坂見附 4 階
            <br />M A I L :{' '}
            <Link href="mailto:info@flabinc.com">info@flabinc.com</Link>
          </Typography>
          <Typography paragraph sx={{ mb: 8 }}>
            制定日：2023 年 12 月 12 日
            <br />
            株式会社 F . l a b
            <br />
            代表取締役 藤田貴道
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
