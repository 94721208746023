import { AppBar, Toolbar } from '@mui/material';
import { ReactElement } from 'react';

interface BottomAppBarProps {
  children?: ReactElement;
}

export function BottomAppBar({ children }: BottomAppBarProps): ReactElement {
  return (
    <>
      <Toolbar sx={{ minHeight: '66px' }}></Toolbar>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          bottom: 0,
          py: 1,
          top: 'auto',
        }}
      >
        <Toolbar
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: '62px',
          }}
        >
          {children}
        </Toolbar>
      </AppBar>
    </>
  );
}
