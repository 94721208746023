import { User } from '@app/types/user';

const TYPE_ID_DEMANDER = '000';

export function isDemanderUser(typeId: string) {
  return typeId === TYPE_ID_DEMANDER;
}

export function isUserInfoRegistered(user: User | null): boolean {
  return !!user?.customFields?.familyName;
}

export const isValidUrlFormat = (url: string, domain: string) => {
  const pattern = new RegExp(
    `^https?://(.*\\.)?${domain.replace('.', '\\.')}/.*$`
  );
  return pattern.test(url);
};
