import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Control, FieldValues, Controller, Path } from 'react-hook-form';

interface BirthdaySelectorProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

const yearOptions = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 101 }, (i, index) => currentYear - index);
};

export function BirthdaySelector<T extends FieldValues>({
  name,
  control,
}: BirthdaySelectorProps<T>): ReactElement {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            value={
              field.value ? String(new Date(field.value).getFullYear()) : ''
            }
            margin="dense"
            error={!!error}
            fullWidth
            onChange={(e) => {
              if (field.value) {
                const birthday = new Date(field.value);
                birthday.setFullYear(Number(e.target.value));
                field.onChange(birthday.toISOString());
              }
            }}
          >
            {yearOptions().map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <Typography>年</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            value={field.value ? String(new Date(field.value).getMonth()) : ''}
            margin="dense"
            error={!!error}
            fullWidth
            onChange={(e) => {
              if (field.value) {
                const birthday = new Date(field.value);
                birthday.setMonth(Number(e.target.value));
                field.onChange(birthday.toISOString());
              }
            }}
          >
            {Array.from({ length: 12 }).map((i, index) => (
              <MenuItem key={index} value={index}>
                {index + 1}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <Typography>月</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            value={field.value ? String(new Date(field.value).getDate()) : ''}
            margin="dense"
            error={!!error}
            fullWidth
            onChange={(e) => {
              if (field.value) {
                const birthday = new Date(field.value);
                birthday.setDate(Number(e.target.value));
                field.onChange(birthday.toISOString());
              }
            }}
          >
            {Array.from({ length: 31 }).map((i, index) => (
              <MenuItem key={index} value={index + 1}>
                {index + 1}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <Typography>日</Typography>
    </Stack>
  );
}
