import { LoadingButton } from '@mui/lab';
import {
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Link as MUILink,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilState } from 'recoil';

import { authViaSocialNetwork } from '@app/adapter/auth-via-social-service';
import { getUser } from '@app/adapter/user-service';
import { SocialLoginButton } from '@app/components/Shared/SocialLoginButton';
import { TopNavPortal } from '@app/components/TopNav/TopNavPortal';
import {
  afterLoginRouteSelector,
  loggedInUserState,
  userAuthInfoSelector,
} from '@app/domain/app';
import { handleLogin } from '@app/domain/network-actions';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { chatClient, subscribeToStream } from '@app/socket/chat-socket';
import { SocialPlatforms } from '@app/types/user';
import { isError } from '@app/utils/error';
import { isDemanderUser } from '@app/utils/user';

/**
 * @todo Real Validation, Wow eg) emailAddress
 */
export function Login(): ReactElement {
  const navigate = useNavigate();
  const setUserAuthInfoState = useSetRecoilState(userAuthInfoSelector);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const [afterLoginRoute, setAfterLoginRoute] = useRecoilState(
    afterLoginRouteSelector
  );
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      email: '',
      fingerprint: 'fingerprint',
      password: '',
    },
    mode: 'all',
  });
  const [isDisableSocial, setIsDisableSocial] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (data: { email: string; password: string }) => {
      try {
        setIsLoading(true);
        const loginData = await handleLogin(data.email, data.password);
        const user = await getUser(
          loginData.userId,
          loginData.accessToken,
          loginData.fingerprint
        );
        setLoggedInUser(user.data);

        if (!isDemanderUser(user.data.typeId)) {
          throw new Error('このユーザータイプはログイン出来ません');
        }
        setUserAuthInfoState({
          accessToken: loginData.accessToken,
          fingerPrint: loginData.fingerprint,
          userId: loginData.userId,
        });

        // Initialize chat client
        await chatClient.init(loginData.accessToken, loginData.fingerprint);
        subscribeToStream(chatClient, loginData.userId);

        if (afterLoginRoute) {
          navigate(afterLoginRoute);
          setAfterLoginRoute(null);
        } else {
          navigate('/home');
        }
      } catch (error: unknown) {
        let errorMessage = 'ログインに失敗しました';
        if (isError(error)) {
          if (error.message === 'email or password is wrong') {
            errorMessage = 'メールアドレスまたはパスワードが間違っています';
          } else {
            errorMessage += `, ${error.message}`;
          }
        }
        setErrorSnackbarText(errorMessage);
        setErrorSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    },
    [
      afterLoginRoute,
      navigate,
      setAfterLoginRoute,
      setErrorSnackbarOpen,
      setErrorSnackbarText,
      setLoggedInUser,
      setUserAuthInfoState,
    ]
  );

  const loginViaSocial = async (type: string) => {
    try {
      setIsDisableSocial(true);
      const authSocialResponse = await authViaSocialNetwork(type);
      const redirectUrl = authSocialResponse.data.redirectUrl;
      window.location.href = redirectUrl;
    } catch (error) {
      setSnackbar(true, '登録に失敗しました');
    }
  };

  return (
    <Container>
      <TopNavPortal title="ログイン" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={600}
                >
                  メールアドレス
                </Typography>
              </FormLabel>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={error ? true : undefined}
                    helperText={
                      error ? 'メールアドレスを入力してください' : undefined
                    }
                    margin="dense"
                    placeholder="メールアドレスを入力"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={600}
                >
                  パスワード
                </Typography>
              </FormLabel>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="password"
                    error={error ? true : undefined}
                    helperText={
                      error ? 'パスワードを入力してください' : undefined
                    }
                    margin="dense"
                    placeholder="パスワードを入力"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} direction="column">
              <Typography
                component="div"
                align="center"
                variant="body2"
                sx={{ mb: 1 }}
              >
                <MUILink
                  component={Link}
                  to="/send_reset_password_email"
                  color="textSecondary"
                  underline="none"
                >
                  パスワードを忘れた方
                </MUILink>
              </Typography>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                loading={isLoading}
                disabled={!formState.isValid}
              >
                ログイン
              </LoadingButton>

              <Typography
                color="textPrimary"
                variant="inherit"
                sx={{ paddingX: '16px', textAlign: 'center' }}
              >
                OR
              </Typography>

              <Box
                component={'div'}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: {
                    md: 'row',
                    xs: 'column',
                  },
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <SocialLoginButton
                  platform={SocialPlatforms.Instagram}
                  text="Instagramでログイン"
                  disabled={isDisableSocial}
                  onClick={() => loginViaSocial('instagram')}
                />
                <SocialLoginButton
                  platform={SocialPlatforms.Google}
                  text="Googleでログイン"
                  disabled={isDisableSocial}
                  onClick={() => loginViaSocial('google')}
                  sx={{ mx: 2 }}
                />
                <SocialLoginButton
                  platform={SocialPlatforms.Line}
                  text="Lineでログイン"
                  disabled={isDisableSocial}
                  onClick={() => loginViaSocial('line')}
                />
                <SocialLoginButton
                  platform={SocialPlatforms.Facebook}
                  text="Facebookでログイン"
                  disabled={isDisableSocial}
                  onClick={() => loginViaSocial('facebook')}
                  sx={{ mx: 2 }}
                />
                <SocialLoginButton
                  platform={SocialPlatforms.Twitter}
                  text="Xでログイン"
                  disabled={isDisableSocial}
                  onClick={() => loginViaSocial('x')}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <Box sx={{ mt: 3 }}>
        <Divider sx={{ mb: 3 }} />
        <Typography
          component="div"
          align="center"
          color="textSecondary"
          variant="body2"
          sx={{ mb: 1 }}
        >
          まだ会員登録をされていない方
        </Typography>
        <Button
          fullWidth
          color="neutral"
          variant="outlined"
          onClick={() => navigate('/register')}
        >
          <Typography color="textPrimary" variant="inherit">
            新規会員登録
          </Typography>
        </Button>
      </Box>
      <Typography textAlign="center" mt={5}>
        ログインせずにプランを確認したい方は<Link to="/home">こちら</Link>へ
      </Typography>
    </Container>
  );
}
function setSnackbar(arg0: boolean, arg1: string) {
  throw new Error('Function not implemented.');
}
