import CheckIcon from '@mui/icons-material/Check';
import TagIcon from '@mui/icons-material/Tag';
import { Stack, Typography, Box, useTheme } from '@mui/material';
import { ReactElement } from 'react';

import { Product } from '@app/types/catalog';

interface CardPartTitleProps {
  product: Product;
}

/*
  ジャンルとタグ表示コンポーネント
*/
export function CardPartTitle({ product }: CardPartTitleProps): ReactElement {
  const theme = useTheme();

  return (
    <Stack direction="column" spacing={1} alignItems="flex-start">
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {product.attributes.slice(0, 2).map((attr, index) => (
          <Box
            key={index}
            bgcolor={theme.palette.neutral.greenLight}
            borderRadius="8px"
            display="flex"
            alignItems="center"
            padding="0.4rem"
            marginRight="0.5rem"
            marginBottom="0.1rem"
          >
            <CheckIcon
              style={{
                color: theme.palette.neutral.green,
                fontSize: '0.8rem',
                marginRight: '0.4rem',
              }}
            />
            <Typography
              style={{
                color: theme.palette.neutral.green,
                fontSize: '0.75rem',
              }}
            >
              {attr.value}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {product.tags?.map((tags, index) => (
          <Box
            key={index}
            bgcolor={theme.palette.neutral.lightYellow}
            borderRadius="8px"
            display="flex"
            alignItems="center"
            padding="0.4rem"
            marginRight="0.5rem"
            marginTop="0.5rem"
          >
            <TagIcon
              style={{
                color: theme.palette.text.primary,
                fontSize: '0.8rem',
                marginRight: '0.4rem',
              }}
            />
            <Typography
              style={{
                color: theme.palette.text.primary,
                fontSize: '0.75rem',
              }}
            >
              {tags}
            </Typography>
          </Box>
        ))}
      </Box>
    </Stack>
  );
}
