import { Button, Grid, Stack } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userAuthInfoSelector } from '@app/domain/app';

export function HomeCarousel(): ReactElement {
  const navigate = useNavigate();
  const authInfo = useRecoilValue(userAuthInfoSelector);
  return (
    <Grid container>
      <Grid item xs={12} sx={{ my: 1 }}>
        <Stack direction="column" sx={{ mt: 2, mx: 4 }}>
          {!authInfo && (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => navigate('/register')}
            >
              無料で会員登録をする
            </Button>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
