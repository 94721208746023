import {
  Box,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';

interface BottomMenuProps {
  menuItems: { text: string; to: string }[];
}

export function BottomMenu(prop: BottomMenuProps): ReactElement {
  const { menuItems } = prop;
  const currentYear = new Date().getFullYear();

  const handleNavigation = (to: string) => {
    window.open(to, '_blank');
  };

  return (
    <Box sx={{ bgcolor: 'secondary.main' }}>
      <MenuList>
        {menuItems.map(({ text, to }, index) => (
          <MenuItem key={index} onClick={() => handleNavigation(to)}>
            <ListItemText>{text}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
      <Typography color="textSecondary" sx={{ p: 2 }}>
        © {currentYear} Tieup All rights reserved.
      </Typography>
    </Box>
  );
}
