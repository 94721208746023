import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
  Unstable_Grid2 as Grid,
  useTheme,
} from '@mui/material';
import {
  addMonths,
  endOfMonth,
  format,
  isSameDay,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getCloneDate } from '@app/utils/date';

const ChangeButton = styled(Button)(({ theme }) => ({
  border: `solid 1px ${theme.palette.grey[400]}`,
  minHeight: '32px',
  minWidth: '32px',
  padding: '4px',
}));

interface CalendarProps {
  onChangeValue?: (value: string[]) => void;
  startDate?: string;
  value?: string[];
}

export function Calendar(props: CalendarProps) {
  const theme = useTheme();
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState<Date>(
    startOfMonth(getCloneDate(today))
  );
  const [selected, setSelected] = useState<string[]>(props.value || []);
  const monthOptions = Array.from({ length: 6 }, (i, index) => {
    const date = addMonths(getCloneDate(today).setDate(1), index);
    return {
      label: format(date, 'yyyy年MM年'),
      value: getCloneDate(date),
    };
  });

  useEffect(() => {
    setSelected(props.value || []);
  }, [props.value]);

  const days = useMemo(() => {
    const endOfMonthDay = endOfMonth(selectedMonth).getDate();
    return [
      ...Array.from(Array(selectedMonth.getDay()).keys()).map(() => 0), //開始日の曜日まで空日付で埋める
      ...Array.from(Array(endOfMonthDay).keys(), (x) => x + 1), //末日までの日付配列
    ];
  }, [selectedMonth]);

  const isSelected = useCallback(
    (day: number) => {
      const date = getCloneDate(selectedMonth).setDate(day);
      return !!(day && selected.some((d) => isSameDay(date, new Date(d))));
    },
    [selectedMonth, selected]
  );

  const changeSelected = useCallback(
    (day: number) => {
      const date = getCloneDate(selectedMonth).setDate(day);
      const value = isSelected(day)
        ? selected.filter((d) => !isSameDay(date, new Date(d)))
        : [...selected, format(date, 'yyyy/MM/dd')];
      setSelected(value);
      props.onChangeValue && props.onChangeValue(value);
    },
    [isSelected, props, selected, selectedMonth]
  );

  return (
    <Box>
      <Grid container>
        <Grid display="flex" justifyContent="center" alignItems="center">
          <ChangeButton
            variant="outlined"
            size="small"
            disabled={isSameDay(monthOptions[0].value, selectedMonth)}
            onClick={() => setSelectedMonth(subMonths(selectedMonth, 1))}
          >
            <ChevronLeftIcon fontSize="small" color="action" />
          </ChangeButton>
        </Grid>
        <Grid xs display="flex" alignItems="center" justifyContent="center">
          <Select
            size="small"
            value={format(selectedMonth, 'yyyy/MM/dd')}
            variant="standard"
            IconComponent={ExpandMoreIcon}
            onChange={(e) => setSelectedMonth(new Date(e.target.value))}
            sx={{ '::before': { borderBottom: 'none' }, width: '124px' }}
          >
            {monthOptions.map((month, index) => (
              <MenuItem key={index} value={format(month.value, 'yyyy/MM/dd')}>
                {month.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid display="flex" justifyContent="center" alignItems="center">
          <ChangeButton
            variant="outlined"
            size="small"
            disabled={isSameDay(monthOptions.slice(-1)[0].value, selectedMonth)}
            onClick={() => setSelectedMonth(addMonths(selectedMonth, 1))}
          >
            <ChevronRightIcon fontSize="small" color="action" />
          </ChangeButton>
        </Grid>
      </Grid>
      <Stack mt={1}>
        <Grid container alignItems="center" columns={7} height={'34px'}>
          {['日', '月', '火', '水', '木', '金', '土'].map((week) => (
            <Grid
              xs={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              key={week}
            >
              <Typography color={theme.palette.grey[500]}>{week}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid container alignItems="center" columns={7}>
          {days.map((day, index) => {
            return (
              <Grid
                xs={1}
                height="34px"
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => changeSelected(day)}
              >
                <IconButton
                  disableRipple
                  sx={{
                    bgcolor: isSelected(day)
                      ? theme.palette.primary.main
                      : undefined,
                    height: '34px',
                    width: '34px',
                  }}
                >
                  <Typography
                    color={
                      isSelected(day)
                        ? theme.palette.common.white
                        : theme.palette.text.primary
                    }
                  >
                    {day ? day : ''}
                  </Typography>
                </IconButton>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Box>
  );
}
