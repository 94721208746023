import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { UserUpdate } from '@app/types/user';

export const UserUpdateDefaultValues: UserUpdate = {
  customFields: {
    birthday: new Date('1980-01-01').toISOString(),
    familyName: '',
    firstName: '',
    notes: '',
    orderAfterFiles: [],
    orderMatchedFiles: [],
    socialMediaName: '',
    socialMediaUrl: [],
    usedSocialMediaPlatforms: [],
  },
  phoneNumber: '',
};

const UserUpdateFormSchema = z.object({
  customFields: z.object({
    birthday: z.string().min(1, { message: '生年月日を入力してください' }),
    familyName: z.string().min(1, { message: '姓を入力してください' }),
    firstName: z.string().min(1, { message: '名を入力してください' }),
    notes: z.string(),
    orderAfterFiles: z.array(
      z.object({
        fileUrl: z.string(),
        name: z.string(),
      })
    ),
    orderMatchedFiles: z.array(
      z.object({
        fileUrl: z.string(),
        name: z.string(),
      })
    ),
    socialMediaName: z.string().min(1, { message: 'SNS名を入力してください' }),
    socialMediaUrl: z
      .array(z.string().url())
      .nonempty('利用SNSのURLを入力してください'),
    usedSocialMediaPlatforms: z
      .array(z.string())
      .nonempty('利用SNSを入力してください'),
  }),
  phoneNumber: z
    .string()
    .min(1, { message: '連絡先電話番号を入力してください' })
    .regex(/^0[0-9]{9,12}$/, {
      message: '連絡先電話番号はハイフンなしの数字を入力してください',
    }),
});

export const UserUpdateForm = {
  defaultValues: UserUpdateDefaultValues,
  resolver: zodResolver(UserUpdateFormSchema),
  schema: UserUpdateFormSchema,
} as const;
