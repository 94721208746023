import {
  AttachFile as AttachFileIcon,
  CancelOutlined as CancelOutlinedIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, InputBase, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactElement, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { postChatMessage, uploadBlob } from '@app/adapter/chat-service';
import { MessageTypeFile } from '@app/components/Chat/MessageTypeFile';
import { TemplateModal } from '@app/components/Chat/TemplateModal';
import { userAuthInfoSelector } from '@app/domain/app';
import {
  errorSnackbarOpenState,
  errorSnackbarSeverityState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { Message, MessageCreation, MessageTypeId } from '@app/types/chat';

export interface FileInfo {
  blob: Blob;
  info: File;
  url: string;
}

type Props = {
  onSent?: (message: Message) => void;
  topicId?: string;
};

export function ChatInputForm({ onSent, topicId }: Props): ReactElement {
  const theme = useTheme();
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setErrorSnackbarSeverity = useSetRecoilState(
    errorSnackbarSeverityState
  );
  const [message, setMessage] = useState<string>('');
  const [file, setFile] = useState<FileInfo | null>(null);
  const [isComposing, setIsComposing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [templateMessage, setTemplateMessage] = useState('');

  const canSend = useMemo(() => {
    return !!message || !!file;
  }, [file, message]);

  const resetFile = () => {
    if (file) {
      URL.revokeObjectURL(file.url);
      setFile(null);
    }
  };

  const sendMessage = async () => {
    if (!authInfo || !userAuthInfoState || !topicId || !canSend) {
      return;
    }
    try {
      setIsLoading(true);
      const payload: MessageCreation = {
        content: message,
        title: '',
        topicId,
        typeId: MessageTypeId.MESSAGE,
      };

      if (file) {
        const attachment = await uploadBlob(
          topicId,
          authInfo.accessToken,
          authInfo.fingerPrint,
          file.blob
        );
        resetFile();

        payload.title = JSON.stringify({
          id: attachment.id,
          name: file.info.name,
          size: file.info.size,
        });
        payload.content = attachment.id || '';
        payload.typeId = file.info.type.startsWith('image/')
          ? MessageTypeId.IMAGE
          : MessageTypeId.FILE;
      }

      if (payload.content) {
        const response = await postChatMessage(
          authInfo.accessToken,
          authInfo.fingerPrint,
          userAuthInfoState.userId,
          payload
        );
        onSent?.(response.data);
        setMessage('');
      }
    } catch (error) {
      setErrorSnackbarSeverity('error');
      setErrorSnackbarText('メッセージの送信に失敗しました');
      setErrorSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      resetFile();
      setFile({
        blob: new Blob([selectedFile], { type: selectedFile.type }),
        info: selectedFile,
        url: URL.createObjectURL(selectedFile),
      });
    }
  };

  const handleCloseTemplateModal = (templateMsg?: string) => {
    if (
      templateMsg &&
      typeof templateMsg === 'string' &&
      templateMsg.trim() !== ''
    ) {
      setMessage(templateMsg);
    }
    setTemplateMessage('');
    setIsOpen(false);
  };

  const insertTemplate = (templateText: string) => {
    const newTemplateMessage = templateMessage + templateText;
    handleCloseTemplateModal(newTemplateMessage);
  };

  return (
    <>
      <Stack width={1} sx={{ px: 2 }}>
        {file ? (
          <Box pt={1}>
            <Box display="inline-block" sx={{ position: 'relative' }}>
              {file.info.type.startsWith('image/') ? (
                <img
                  src={file.url}
                  alt={file.info.name}
                  style={{
                    height: '80px',
                    width: '80px',
                  }}
                />
              ) : (
                <MessageTypeFile
                  name={file.info.name || '無題'}
                  size={file.info.size || 0}
                />
              )}
              <IconButton
                onClick={() => resetFile()}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  p: 0,
                  position: 'absolute',
                  right: '-10px',
                  top: '-6px',
                }}
              >
                <CancelOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <InputBase
            value={message}
            placeholder="メッセージを入力"
            minRows={1}
            maxRows={2}
            multiline
            fullWidth
            onChange={(e) => setMessage(e.target.value)}
            onCompositionStart={() => setIsComposing(true)}
            onCompositionEnd={() => setIsComposing(false)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey && !isComposing) {
                event.preventDefault();
                void sendMessage();
              }
            }}
            sx={{ minHeight: '50px' }}
          />
        )}
        <Stack direction="row">
          <Stack direction="row" width={1}>
            {/*
            テンプレートの優先順位下がった為非表示
            Userごとの一覧取得APIの実装待ち
            <Button
              onClick={handleOpen}
              variant="outlined"
              sx={{
                borderColor: theme.palette.neutral.greyDark,
                borderRadius: '30px',
                color: theme.palette.neutral.greyDark,
                ml: 2,
                p: '5px 7px',
              }}
            >
              テンプレート
            </Button>
            */}
            <IconButton
              component="label"
              color="primary"
              aria-label="upload file"
            >
              <AttachFileIcon sx={{ color: theme.palette.neutral.greyDark }} />
              <input
                type="file"
                accept="image/*,Application/pdf"
                hidden
                onChange={handleChangeFile}
              />
            </IconButton>
          </Stack>
          <LoadingButton
            disabled={!canSend}
            loading={isLoading}
            onClick={sendMessage}
            sx={{ minWidth: '43px', p: 0 }}
          >
            <SendIcon />
          </LoadingButton>
        </Stack>
      </Stack>
      <TemplateModal
        isOpen={isOpen}
        onClose={handleCloseTemplateModal}
        insertTemplate={insertTemplate}
      />
    </>
  );
}
