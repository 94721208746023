import { Container, Typography, Box } from '@mui/material';

const boxStyle = {
  mb: 3,
  ml: 2,
};

const marginBottom2Style = {
  mb: 2,
};

const marginBottom5Style = {
  mb: 5,
};

const TitleTypographyStyle = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  mb: 1,
};

const TypographyBoldStyle = {
  fontWeight: 'bold',
  mb: 1,
};

export function TermsPage() {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 7, textAlign: 'center' }}>
        <Typography
          variant="h1"
          gutterBottom
          sx={{ fontSize: '2rem', fontWeight: 'bold' }}
        >
          ＜TieUp利用規約＞・ユーザー向け
        </Typography>

        <Box sx={{ mb: 2, textAlign: 'left' }}>
          <Typography paragraph sx={{ mb: 12, mt: 10 }}>
            株式会社F.lab以下「当社」といいます。）は、当社が提供するサービスである「TieUp」
            （以下「本件サービス」といいます。）の利用について、以下のとおり利用規約を定めます（以下「本規約」といいます。）。
            TieUpの利用者（以下「ユーザー会員」といいます。）は、予め本規約に同意した上で、TieUpを利用するものとします。
          </Typography>

          <Typography paragraph sx={TitleTypographyStyle}>
            1.TieUp の目的
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom5Style}>
              TieUpは、店舗会員（飲食店、美容サービス提供店舗など）が、PR支援を条件にTieUpユーザー会員限定の特別サービスプランを掲載し、
              ユーザーに活用していただくことを主な目的としています。
              ユーザーはプラン条件に指定されたPR支援（外部SNSへの店舗会員・サービスの紹介投稿）を行うものとします。
            </Typography>
          </Box>
          <Typography paragraph sx={TitleTypographyStyle}>
            2. 会員登録手続
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom5Style}>
              ユーザー会員は、当社所定の手続に従い、ユーザー会員の登録手続を行うことができるものとします。
              当社は、ユーザー会員ではないお客様または当社所定の会員登録手続を完了していないお客様とで、本件サービスにおいて提供するサービスを異ならせることがあります。
              提供されるサービスの内容等は当社が任意に決定し、又は随時変更ができるものとします。
            </Typography>
          </Box>
          <Typography paragraph sx={TitleTypographyStyle}>
            3. セキュリティについて
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom5Style}>
              ユーザー会員登録については、現在最も信頼性の高いインターネット上の暗号通信技術SSL（Secure
              Socket
              Layer）を使用し、送信される情報は第三者に見られることのないよう暗号化されています。
            </Typography>
          </Box>
          <Typography paragraph sx={TitleTypographyStyle}>
            4. ログインアカウント等の登録・管理
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.
              ユーザー会員は、ログインアカウント及びパスワード（以下「ログインアカウント等」といいます。）の登録・管理等をご⾃⾝の責任において⾏うものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              2.
              ユーザー会員の管理不⼗分、使⽤上の過誤、第三者の使⽤等により⽣じた損害について、当社は⼀切の責任を負わないものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              3.
              ログインアカウント等にかかるサービスを利⽤したことにより⽣じた損害（ユーザー会員の管理不十分、使用上の過誤、第三者の使用等により生じた損害を含みますがこれに限られません）及び/又は当該サービス運営者とユーザー会員の間に⽣じたトラブル等について、当社の過失（重過失を除きます）による債務不履行責任又は不法行為責任が認められる場合は、当社は通常生ずべき損害（逸失利益その他特別の事情によって生じた損害を除きます）の範囲内でのみ損害賠償責任を負うものとします。ただし、当該損害又は当該トラブル等が当社の故意又は重過失に起因する場合は、この限りではありません。
            </Typography>
          </Box>
          <Typography paragraph sx={TitleTypographyStyle}>
            5. 本件サービスの利用について
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={TypographyBoldStyle}>
              （1）TieUpの転用・転売の禁止
            </Typography>
            <Box sx={boxStyle}>
              <Typography paragraph sx={marginBottom2Style}>
                1.
                ユーザー会員は、当社が提供するTieUpについて、その全部あるいは一部を問わず、営業活動その他の営利を目的とした行為（TieUp類似サービスへの勧誘を含む。）
                又はそれに準ずる行為やそのための準備行為を目的として、利用又はアクセスしてはならないものとします。
                また、その他、宗教活動、政治活動などの目的での利用又はアクセスも行ってはならないものとします。
              </Typography>
              <Typography paragraph sx={marginBottom5Style}>
                2.
                TieUpへ投稿された評価コメントを無断転載・無断利用することは禁止します。ただし、当該投稿をした本人は除きます。
              </Typography>
            </Box>
            <Typography paragraph sx={TypographyBoldStyle}>
              （2）TieUpの変更・停止
            </Typography>
            <Box sx={boxStyle}>
              <Typography paragraph sx={marginBottom2Style}>
                1.
                当社はユーザー会員へご提供している本件サービスを当社の都合により変更することがございます。
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                2.
                災害・事故、その他緊急事態が発⽣した際には、本件サービスを停⽌する場合がありますので、予めご了承ください。
              </Typography>
              <Typography paragraph sx={marginBottom5Style}>
                3.
                事前にユーザー会員の承諾を得ることなく本件サービスを変更・停⽌等したことにより、ユーザー会員⼜は第三者が受けた損害について、
                当社の過失（重過失を除きます）による債務不履行責任又は不法行為責任が認められる場合は、
                当社は通常生ずべき損害（逸失利益その他特別の事情によって生じた損害を除きます）の範囲内でのみ損害賠償責任を負うものとします。
                ただし、当該損害が当社の故意又は重過失に起因する場合はこの限りではありません。
              </Typography>
            </Box>
            <Typography paragraph sx={TypographyBoldStyle}>
              （3）本件サービスの利用に必要となる設備
            </Typography>
            <Box sx={boxStyle}>
              <Typography paragraph sx={marginBottom5Style}>
                ユーザー会員が本件サービスをご利用になるためには、webにアクセスしていただく必要があります。
                なおwebへのアクセスについては、ユーザー会員は自らの責任と費用で必要な機器、ソフトウェアを適切に準備、操作していただく必要があり、
                当社はユーザー会員がwebにアクセスするための準備、方法などについては一切関与いたしておりません。
              </Typography>
            </Box>
            <Typography paragraph sx={TypographyBoldStyle}>
              （4）PR支援投稿及び著作権等
            </Typography>
            <Box sx={boxStyle}>
              <Typography paragraph sx={marginBottom2Style}>
                1.
                ユーザー会員が外部SNSに投稿を行う際には、当該SNSの利用規約にしたがうものとします。
              </Typography>
              <Typography paragraph sx={marginBottom5Style}>
                2.
                投稿内容・画像の著作権に関する取扱いは当該SNSの規約に準ずるものとします。
              </Typography>
            </Box>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            6. 免責事項
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              本件サービスは、ユーザー会員ご自身の責任においてご利用いただきます。また、以下の各号につき予めご了承いただくものとします。
            </Typography>
            <Typography paragraph sx={TypographyBoldStyle}>
              （1）店舗会員情報
            </Typography>
            <Box sx={boxStyle}>
              <Typography paragraph sx={marginBottom2Style}>
                当社は、掲載された店舗会員に関する情報については、正確性、完全性についてはいかなる保証もいたしません。
                ご予約時、⼜はお出かけの前に、住所・営業時間・定休⽇などを電話等の⽅法により直接お店に確認されることをお勧めいたします。
                また、掲載された店舗会員に関する情報によってユーザー会員に⽣じた損害や、ユーザー会員同⼠のトラブル等について、一切の責任を負いません。
                掲載された店舗会員の情報が正しくない場合は「info@flabinc.com」へご連絡ください。
              </Typography>
            </Box>
            <Typography paragraph sx={TypographyBoldStyle}>
              （2）店舗会員への予約と来店
            </Typography>
            <Box sx={boxStyle}>
              <Typography paragraph sx={marginBottom5Style}>
                当社は本件サービスを通して店舗会員の情報掲載と予約機能機能を提供するものであり、店舗会員とユーザー会員とのトラブル等については一切の責任を負いません。
                トラブルについては店舗会員とユーザー会員とで解決を図るものとし、当社は関与いたしません。
              </Typography>
            </Box>
            <Typography paragraph sx={TypographyBoldStyle}>
              （3）外部SNSへの投稿
            </Typography>
            <Box sx={boxStyle}>
              <Typography paragraph sx={marginBottom2Style}>
                1. 来店後、当社所定の期間内に外部SNSへ投稿してください。
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                2.
                当社は、外部SNSに掲載されたPR内容に関していかなる保証もいたしません。ユーザー会員のご判断でご利⽤ください。
              </Typography>
              <Typography paragraph sx={marginBottom5Style}>
                3.
                掲載された⼝コミによって⽣じたユーザー会員の損害（ユーザー会員が作成した各種コンテンツによるコンピュータ・ウィルス感染被害なども含みます）や、
                ユーザー会員同⼠及びユーザー会員、店舗会員間のトラブル等について、当社の過失（重過失を除きます）による債務不履行責任又は不法行為責任が認められる場合は、
                当社は通常生ずべき損害（逸失利益その他特別の事情によって生じた損害を除きます）の範囲内でのみ損害賠償責任を負うものとします。
                ただし、当該損害又は当該トラブル等が、当社の故意又は重過失に起因する場合は、この限りではありません。
              </Typography>
            </Box>
            <Typography paragraph sx={TypographyBoldStyle}>
              （2）リンク先サイト
            </Typography>
            <Box sx={boxStyle}>
              <Typography paragraph sx={marginBottom2Style}>
                1.
                当社は、本件サービスからリンクされた第三者が運営するサイトに関して、正確性、安全性についていかなる保証もいたしません。
                ユーザー会員のご判断でご利⽤ください。
              </Typography>
              <Typography paragraph sx={marginBottom5Style}>
                2.
                リンク先で⽣じた損害や、ユーザー会員同⼠及びユーザー会員、店舗会員間のトラブル等について、当社の過失（重過失を除きます）による
                債務不履行責任又は不法行為責任が認められる場合は、当社は通常生ずべき損害（逸失利益その他特別の事情によって生じた損害を除きます）
                の範囲内でのみ損害賠償責任を負うものとします。ただし、当該損害又は当該トラブル等が、当社の故意又は重過失に起因する場合は、この限りではありません。
              </Typography>
            </Box>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            7. 著作権、財産権その他の権利
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.
              本件サービスに含まれているコンテンツ及び個々の情報､商標､画像､広告､デザイン等に関する著作権､商標権その他の知的財産権、
              及びその他の財産権は全て当社又は正当な権利者に帰属しています｡
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              2.
              本件サービス及び関連して使用されている全てのソフトウェアは､知的財産権に関する法令等により保護されている財産権を含んでいます｡
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              3.
              ユーザー会員は､当社若しくは著作権その他の知的財産権及びその他の財産権を有する第三者から利用･使用を許諾されている場合､
              又は､法令により権利者からの許諾なく利用若しくは使用することを許容されている場合を除き、本件サービス及び本件サービスの内容について
              複製､編集､改変､掲載､転載､公衆送信､配布､販売､提供､翻訳・翻案その他あらゆる利用又は使用を行ってはなりません｡
            </Typography>
            <Typography paragraph sx={marginBottom5Style}>
              4.
              ユーザー会員が前各号に反する行為によって被った損害については､当社は一切の責任を負わないものとします｡
              また､ユーザー会員がこれらの行為によって利益を得た場合､当社はその利益相当額を請求できる権利を有するものとします｡
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            8. ステルスマーケティングでないことの明確化
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.
              ユーザー会員は本件サービスの利用に関連する投稿には、景品表示法及びこれに基づくガイドライン、基準等に従い、SNSやメディア上に、広告、PR、プロモーション、タイ
              アップ、協賛等、契約者及び広告代理店からの依頼を受けて商品やサービスの提供があった旨を、明確に表示して下さい。
            </Typography>
            <Typography paragraph sx={marginBottom5Style}>
              2.
              ユーザー会員が本条に違反したことによる店舗会員トラブルについて、当社の過失（重過失を除きます）による債務不履行責任又は不法行為責任が認められる場合は、
              当社は通常生ずべき損害（逸失利益その他特別の事情によって生じた損害を除きます）の範囲内でのみ損害賠償責任を負うものとします。
              ただし、当該損害又は当該トラブル等が、当社の故意又は重過失に起因する場合は、この限りではありません。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            9. 禁止行為
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={TypographyBoldStyle}>
              （1）ユーザー会員が本件サービスを利用するに際して、次の行為を行うことを禁止します。
            </Typography>
            <Box sx={boxStyle}>
              <Typography paragraph sx={marginBottom2Style}>
                ［1］法令上又は本規約若しくはガイドライン上特に認められている場合を除き、本件サービスの提供する情報を当社の事前の同意なく、
                複写、若しくはその他の方法により再生、複製、送付、譲渡、頒布、配布、転売、又はこれらの目的で使用するために保管すること
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［2］本規約又に違反すること
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［3］公序良俗に反すること
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［4］違法行為・犯罪的行為・重大な危険行為に結びつくこと又はこれらを助長すること
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［5］当社、他のユーザー会員又は第三者の知的財産権（著作権、意匠権、実用新案権、商標権、特許権、ノウハウが含まれますがこれに限定されません）を侵害すること
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［6］他のユーザー会員又は第三者の権利又は利益を違法に侵害し、又はそのおそれがあること
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［7］SNS等のへの投稿内容のPR条件への違反、予約後の無断キャンセル又は当日もしくは直前のキャンセルその他店舗会員への迷惑行為があり、当社から是正を求められても改善しないこと。
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［8］本件サービスの運営を妨げること、又は当社の信用を毀損すること
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［9］ユーザー会員登録時に虚偽の申告をすること
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［10］同一人物が複数のユーザー会員登録を行うこと
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［11］ID及びパスワードの不正使用又は他のユーザー会員又は第三者に使用させること
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［12］面識のない異性との出会い等を目的として本件サービスの機能を利用すること
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ［13］その他、当社が不適切と判断すること
              </Typography>
            </Box>
            <Typography paragraph sx={marginBottom5Style}>
              （2）ユーザー会員により前項各号に該当する⾏為⼜は本件サービスの趣旨・⽬的に照らして不適切であると当社が判断する⾏為がなされた場合、
              当社は当該ユーザー会員に対して、本件サービスの利⽤の停⽌その他当社が適切と判断する措置（以下「利⽤停⽌措置」といいます。）をとることができるものとします｡
              なお、利⽤停⽌措置はユーザー会員の帰責性の有無にかかわらず当社の裁量・判断に基づき⾏うことができるものとし、利⽤停⽌措置を⾏った理由については､その如何を問わずユーザー会員に対して⼀切お答えいたしかねます｡
              加えて、利⽤停⽌措置に起因してユーザー会員に⽣じた損害については､当社の過失（重過失を除きます）による債務不履行責任又は不法行為責任が認められる場合は、
              当社は通常生ずべき損害（逸失利益その他特別の事情によって生じた損害を除きます）の範囲内でのみ損害賠償責任を負うものとします。
              ただし、当該損害が、当社の故意又は重過失に起因する場合は、この限りではありません。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            10. 退会手続
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.
              ユーザー会員が退会を希望する場合は、当社の所定の退会手続を行うものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              2.
              ユーザー会員は、退会手続を行った場合、当社でご利用いただいていたユーザー会員のアカウントに関する一切の権利、特典を失うものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              3.
              ユーザー会員は、退会手続によっても、自らの投稿した評価コメント内容は削除されないことを予め承諾するものとします。
            </Typography>
            <Typography paragraph sx={marginBottom5Style}>
              4.
              ユーザー会員が退会手続を完了した場合、又は、当社とユーザー会員との間の本規約に基づく契約が終了した場合であっても、4.ログインアカウント等の登録・管理、5.本件サービスの利用について、6.免責事項、7.
              著作権、財産権その他の権利、8.禁止行為、11.準拠法及び裁判管轄はなお有効とします。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            11. 本規約の変更
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.
              当社は、本規約を変更できるものとし、当社が任意に定めた効力発生日から変更後の本規約の効力が発生するものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              2.
              前項の場合、当社は、効力発生日の1週間以上前の相当な時期までに、ユーザー会員に対して、本規約の変更の内容及び効力発生日を通知いたします。
              但し、当該変更によるユーザー会員の不利益の程度が軽微であると当社が判断した場合、その期間を短縮することができるものとします。
            </Typography>
            <Typography paragraph sx={marginBottom5Style}>
              3.
              前項の規定は、本規約の変更がユーザー会員の一般の利益に適合する場合には適用しないものとします。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            11. 準拠法及び裁判管轄
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom5Style}>
              本規約は、日本法に基づき解釈されるものとし、本規約に関して訴訟の必要が生じた場合には、訴額に応じて東京簡易裁判所または東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <Typography paragraph sx={{ mt: 10, my: 8 }}>
          最終改正：2023年 12 月 11日
          <br />
          株式会社F.lab
          <br />
          東京都港区赤坂3-11-15 赤坂見附4階
          <br />
          代表取締役 藤田貴道
        </Typography>
      </Box>
    </Container>
  );
}
