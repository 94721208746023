import { Avatar, Box, CircularProgress, Typography } from '@mui/material';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getAvatarUploadUrl, updateAvatar } from '@app/adapter/user-service';
import { loggedInUserState, userAuthInfoSelector } from '@app/domain/app';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';

export function Profile(): ReactElement {
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const [isChangingAvatar, setIsChangingAvatar] = useState(false);

  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);

  const onAvatarClick = () => {
    avatarInputRef?.current?.click();
  };

  const onAvatarSelect = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!userAuthInfoState || !loggedInUser) {
        setErrorSnackbarText('Sign in required');
        setErrorSnackbarOpen(true);
        return;
      }

      if (event?.target?.files && event.target.files[0]) {
        const file = event.target.files[0];
        setIsChangingAvatar(true);

        try {
          const res = await getAvatarUploadUrl(
            userAuthInfoState.userId,
            file.type,
            file.size,
            userAuthInfoState.accessToken,
            userAuthInfoState.fingerPrint
          );
          const signedUrl = res.data;
          const uploadResponse = await fetch(signedUrl, {
            body: file,
            method: 'PUT',
          });
          const url = uploadResponse.url.split('?')[0];
          await updateAvatar(
            userAuthInfoState.userId,
            url,
            userAuthInfoState.accessToken,
            userAuthInfoState.fingerPrint
          );
          setLoggedInUser({ ...loggedInUser, avatar: url });
        } catch (error) {
          let message = '';
          if (error instanceof Error) message = error.message;
          setErrorSnackbarText(message);
          setErrorSnackbarOpen(true);
        }
        setIsChangingAvatar(false);
      }
    },
    [
      loggedInUser,
      setErrorSnackbarOpen,
      setErrorSnackbarText,
      setLoggedInUser,
      userAuthInfoState,
    ]
  );

  return loggedInUser ? (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box position="relative" sx={{ marginY: 1 }}>
        <Avatar
          src={loggedInUser.avatar}
          sx={{ cursor: 'pointer', height: 72, width: 72 }}
          onClick={onAvatarClick}
        />
        {isChangingAvatar && (
          <Box
            position="absolute"
            sx={{
              backgroundColor: 'rgba(0,0,0,0.38)',
              borderRadius: '50%',
              height: 72,
              left: 0,
              top: 0,
              width: 72,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress color="inherit" sx={{ color: 'white' }} />
          </Box>
        )}
      </Box>
      <input
        hidden
        ref={avatarInputRef}
        type="file"
        accept="image/jpeg, image/jpg, image/png, image/webp"
        onChange={onAvatarSelect}
        onClick={(event) => (event.currentTarget.value = '')}
      />
      <Typography variant="subtitle1">{loggedInUser.name}</Typography>
      <Typography variant="body2">{loggedInUser.email}</Typography>
    </Box>
  ) : (
    <div>Sign in required</div>
  );
}
