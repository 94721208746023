import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { getCategoryTree } from '@app/adapter/catalog-service';
import { Calendar } from '@app/components/Shared/Calendar';
import { Dialog } from '@app/components/Shared/Dialog';
import { Loading } from '@app/components/Shared/Loading';
import { categoryState, searchConditionState } from '@app/domain/search';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { CategoryName, CategoryParentName } from '@app/types/catalog';
import { LoadableState } from '@app/types/common';
import { getSearchResultUrl } from '@app/utils/catalog';
import { weeks } from '@app/utils/date';

export interface ConditionProps {
  days?: string[];
  timeFrom?: string;
  timeTo?: string;
  weeks?: string[];
}
interface DateTimeModalProps {
  category: string;
  condition?: ConditionProps;
  onClickSearch?: (conditions: ConditionProps) => void;
  onClose: () => void;
  open: boolean;
}

export function DateTimeModal({
  category,
  condition,
  onClickSearch,
  onClose,
  open,
}: DateTimeModalProps): ReactElement {
  const navigate = useNavigate();
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setConditionState = useSetRecoilState(searchConditionState);
  const [categoriesSharedState, setCategoriesSharedState] =
    useRecoilState(categoryState);
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const { control, getValues, setValue, reset } = useForm<ConditionProps>({
    defaultValues: {
      days: [],
      timeFrom: '',
      timeTo: '',
      weeks: [],
    },
  });

  const fetchCategories = useCallback(async () => {
    if (categoriesSharedState.length || loadable === LoadableState.LOADING)
      return;
    setLoadable(LoadableState.LOADING);
    try {
      const result = await getCategoryTree();
      setCategoriesSharedState(result.data.value);
      setLoadable(LoadableState.HAS_VALUE);
    } catch (err) {
      setLoadable(LoadableState.HAS_ERROR);
      setErrorSnackbarText('タイプの取得に失敗しました');
      setErrorSnackbarOpen(true);
    }
  }, [
    categoriesSharedState,
    loadable,
    setCategoriesSharedState,
    setErrorSnackbarOpen,
    setErrorSnackbarText,
  ]);

  useEffect(() => {
    if (open) {
      (Object.keys(getValues()) as Array<keyof ConditionProps>).forEach(
        (key) => {
          setValue(key, _.get(condition, key) || getValues(key));
        }
      );
      void fetchCategories();
    }
    // eslint-disable-next-line
  }, [open]);

  const categoryId = useMemo(() => {
    return (
      categoriesSharedState
        .find((v) => v.name === CategoryParentName.JOB_TYPE)
        ?.children.find((c) => c.name === category)?.id || ''
    );
  }, [categoriesSharedState, category]);

  const handleClose = useCallback((): void => {
    onClose();
  }, [onClose]);

  const handleClickSearch = useCallback(() => {
    const conditions = getValues();
    if (onClickSearch) {
      onClickSearch(conditions);
      return;
    }
    setConditionState(null);
    navigate(
      getSearchResultUrl({
        categoryId,
        work: {
          days: category === CategoryName.SPOT ? conditions.days : [],
          timeFrom: conditions.timeFrom,
          timeTo: conditions.timeTo,
          weeks: conditions.weeks,
        },
      })
    );
    handleClose();
  }, [
    category,
    categoryId,
    getValues,
    handleClose,
    navigate,
    onClickSearch,
    setConditionState,
  ]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={`${
        category === CategoryName.SPOT ? '日付' : '曜日'
      }・時間から探す`}
      content={
        <Container>
          {loadable === LoadableState.LOADING ? (
            <Loading />
          ) : (
            <Stack spacing={2}>
              {category === CategoryName.SPOT && (
                <Calendar
                  value={getValues('days')}
                  onChangeValue={(i) => setValue('days', i)}
                />
              )}
              <Box>
                <Typography fontWeight={500}>曜日で指定する</Typography>
                {weeks.map((week, index) => {
                  const value = week.value.toString();
                  return (
                    <FormControlLabel
                      key={index}
                      label={week.label}
                      control={
                        <Controller
                          name="weeks"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value?.includes(value)}
                              onChange={(e) => {
                                const selected = [
                                  ...(field.value || []).filter(
                                    (c) => c !== value
                                  ),
                                ];
                                if (e.target.checked) selected.push(value);
                                field.onChange(selected);
                                setValue('weeks', selected);
                              }}
                            />
                          )}
                        />
                      }
                    />
                  );
                })}
              </Box>
              <Box>
                <Typography fontWeight={500} pb={1}>
                  時間で指定する
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Controller
                    name="timeFrom"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} margin="dense" fullWidth>
                        <MenuItem value=""></MenuItem>
                        {Array.from({ length: 24 }, (data, index) => (
                          <MenuItem
                            key={index}
                            value={`${index.toString().padStart(2, '0')}:00`}
                          >
                            {`${index}:00`}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Typography>〜</Typography>
                  <Controller
                    name="timeTo"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} margin="dense" fullWidth>
                        <MenuItem value=""></MenuItem>
                        {Array.from({ length: 97 }, (data, index) => (
                          <MenuItem
                            key={index}
                            value={`${index.toString().padStart(2, '0')}:00`}
                          >
                            {`${index}:00`}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Stack>
              </Box>
              <Box textAlign="center">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => reset()}
                >
                  条件をクリアする
                </Button>
              </Box>
            </Stack>
          )}
        </Container>
      }
      action={
        <Stack direction="row" spacing={2} width={1}>
          <Button
            color="inherit"
            variant="outlined"
            fullWidth
            onClick={handleClose}
          >
            キャンセル
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            fullWidth
            onClick={handleClickSearch}
          >
            検索
          </Button>
        </Stack>
      }
    />
  );
}
