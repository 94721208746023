import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link as MUILink,
  Stack,
  TextField,
  Typography,
  useTheme,
  Grid,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { getUser, register } from '@app/adapter/user-service';
import { BirthdaySelector } from '@app/components/Shared/BirthdaySelector';
import { FormRequireLabel } from '@app/components/Shared/FormRequiredLabel';
import { SocialMediaURL } from '@app/components/Shared/SocialMediaURL';
import {
  afterLoginRouteSelector,
  loggedInUserState,
  userAuthInfoSelector,
} from '@app/domain/app';
import { handleLogin } from '@app/domain/network-actions';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { RegistrationForm, IRegistrationForm } from '@app/schemas/registration';
import { socialMediaOptions } from '@app/utils/constants';
import { isError } from '@app/utils/error';
import { getValidateSocialMediaUrlsMessage } from '@app/utils/socialMediaValidator';

export function Register(): ReactElement {
  const navigate = useNavigate();
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const setUserAuthInfoState = useSetRecoilState(userAuthInfoSelector);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const [afterLoginRoute, setAfterLoginRoute] = useRecoilState(
    afterLoginRouteSelector
  );

  const { control, setError, handleSubmit, formState } =
    useForm<IRegistrationForm>({
      defaultValues: { ...RegistrationForm.defaultValues },
      mode: 'all',
      resolver: RegistrationForm.resolver,
    });

  const onSubmit = useCallback(
    async (data: IRegistrationForm) => {
      if (isLoading) return;

      setIsLoading(true);
      const {
        email,
        passwords: { password },
        phoneNumber,
        customFields: {
          usedSocialMediaPlatforms,
          familyName,
          firstName,
          socialMediaName,
        },
      } = data;
      try {
        const urls = data.customFields.socialMediaUrl || [];
        const selectedPlatforms =
          data.customFields.usedSocialMediaPlatforms || [];
        const validationMessage = getValidateSocialMediaUrlsMessage(
          urls,
          selectedPlatforms
        );
        if (validationMessage) {
          setError('customFields.socialMediaUrl', {
            message: validationMessage as string,
            type: 'domainMatch',
          });
          return;
        }

        await register({
          customFields: {
            familyName,
            firstName,
            socialMediaName,
            usedSocialMediaPlatforms,
          },
          email,
          password,
          phoneNumber,
        });

        const loginData = await handleLogin(
          data.email,
          data.passwords.password
        );
        const user = await getUser(
          loginData.userId,
          loginData.accessToken,
          loginData.fingerprint
        );
        setLoggedInUser(user.data);

        setUserAuthInfoState({
          accessToken: loginData.accessToken,
          fingerPrint: loginData.fingerprint,
          userId: loginData.userId,
        });

        if (afterLoginRoute) {
          navigate(afterLoginRoute);
          setAfterLoginRoute(null);
        }
        navigate('/');
      } catch (error) {
        let errorMsg = '登録できませんでした';
        if (isError(error)) {
          if (error.message.includes('has been registered in system')) {
            errorMsg = '既にこのメールアドレスは登録済みです';
          }
        }
        setErrorSnackbarText(errorMsg);
        setErrorSnackbarOpen(true);
        if (isError(error)) {
          throw new Error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      isLoading,
      setLoggedInUser,
      setUserAuthInfoState,
      afterLoginRoute,
      navigate,
      setError,
      setAfterLoginRoute,
      setErrorSnackbarText,
      setErrorSnackbarOpen,
    ]
  );

  return (
    <Container>
      <Stack spacing={3} sx={{ px: 2, py: 3 }}>
        <Typography
          variant="h5"
          fontWeight={600}
          textAlign="center"
          sx={{ py: 1 }}
        >
          新規会員登録
        </Typography>
        <Typography>
          会員登録することで、予約や検索条件の保存が出来るようになります
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <FormControl variant="outlined" fullWidth>
            <FormRequireLabel label="メールアドレス" require={true} />
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error ? true : false}
                  helperText={error?.message}
                  margin="dense"
                  placeholder="メールアドレスを入力"
                />
              )}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <FormRequireLabel label="パスワード" require={true} />
            <Controller
              name="passwords.password"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="password"
                  error={error ? true : false}
                  helperText={error?.message}
                  margin="dense"
                  placeholder="パスワードを入力"
                  autoComplete="new-password"
                />
              )}
            />
            <Typography
              sx={{ color: theme.palette.grey[600], textAlign: 'left' }}
            >
              8文字以上、英数字含む（記号を除く）
            </Typography>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <FormRequireLabel label="パスワードを再入力" require={true} />
            <Controller
              name="passwords.confirmPassword"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="password"
                  error={error ? true : false}
                  helperText={error?.message}
                  margin="dense"
                  placeholder="パスワードを再入力"
                />
              )}
            />
          </FormControl>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Box width={1}>
                <FormRequireLabel label="姓" require={true} />
                <Controller
                  name="customFields.familyName"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'姓を入力'}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box width={1}>
                <FormRequireLabel label="名" require={true} />
                <Controller
                  name="customFields.firstName"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'名を入力'}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <FormRequireLabel label="生年月日" require={true} />
            <BirthdaySelector control={control} name="customFields.birthday" />
          </Grid>
          <Grid item xs={12}>
            <FormRequireLabel label="SNS名" require={true} />
            <Controller
              name="customFields.socialMediaName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  margin="dense"
                  placeholder="SNS名を入力"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormRequireLabel label="連絡先電話番号" require={true} />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="tel"
                  error={!!error}
                  helperText={error?.message}
                  margin="dense"
                  placeholder="連絡先電話番号（ハイフンなし）で入力"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormRequireLabel label="利用SNS" require={true} />
            <Controller
              name="customFields.usedSocialMediaPlatforms"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <Select
                    {...field}
                    labelId="social-media-select-label"
                    multiple
                    value={field.value ?? []}
                    renderValue={(selected) => selected.join(', ')}
                    margin="dense"
                    error={!!error}
                  >
                    {socialMediaOptions.map((value, index) => (
                      <MenuItem key={index} value={value}>
                        <Checkbox checked={field.value?.includes(value)} />{' '}
                        <ListItemText primary={value} />
                      </MenuItem>
                    ))}
                  </Select>
                  {!!error && <FormHelperText>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormRequireLabel label="利用SNSのURL" require={true} />
            <SocialMediaURL
              control={control}
              name="customFields.socialMediaUrl"
            />
          </Grid>
          <Stack>
            <Controller
              name="userAgreement"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={error ? true : false}>
                  <FormControlLabel
                    label={
                      <Typography component="div" variant="body2">
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/terms"
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography
                            component="span"
                            color="primary"
                            variant="body2"
                          >
                            利用規約
                          </Typography>
                        </Link>
                        に同意
                      </Typography>
                    }
                    control={<Checkbox {...field} />}
                  />
                  {error ? (
                    <FormHelperText sx={{ ml: 0 }}>
                      {error.message}
                    </FormHelperText>
                  ) : undefined}
                </FormControl>
              )}
            />
            <Controller
              name="privacyPolicy"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={error ? true : false}>
                  <FormControlLabel
                    label={
                      <Typography component="div" variant="body2">
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/privacy-policy"
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography
                            component="span"
                            color="primary"
                            variant="body2"
                          >
                            プライバシーポリシー
                          </Typography>
                        </Link>
                        に同意
                      </Typography>
                    }
                    control={<Checkbox {...field} />}
                  />
                  {error ? (
                    <FormHelperText sx={{ ml: 0 }}>
                      {error.message}
                    </FormHelperText>
                  ) : undefined}
                </FormControl>
              )}
            />
          </Stack>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={!formState.isValid || isLoading}
          >
            {isLoading ? '登録中...' : '登録'}
          </Button>
        </Stack>
      </form>
      <Box sx={{ my: 3 }}>
        <Divider sx={{ mb: 3 }} />
        <Typography component="div" align="center" variant="body2">
          すでに会員の方は
          <MUILink component={Link} to="/login" underline="none">
            ログイン
          </MUILink>
          へ
        </Typography>
      </Box>
    </Container>
  );
}
