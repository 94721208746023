import BackHandIcon from '@mui/icons-material/BackHand';
import Face3Icon from '@mui/icons-material/Face3';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HotelIcon from '@mui/icons-material/Hotel';
import KebabDiningIcon from '@mui/icons-material/KebabDining';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import SpaIcon from '@mui/icons-material/Spa';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import StoreIcon from '@mui/icons-material/Store';
import { Icon } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

import { DynamicMaterialIcon } from '@app/components/Shared/DynamicMaterialIcon';
import { CategoryIconType } from '@app/types/catalog';

export interface CategoryIconProps {
  size?: OverridableStringUnion<'small' | 'inherit' | 'medium' | 'large'>;
  type: string;
  variation: string;
}

export function CategoryIcon(props: CategoryIconProps) {
  const { size, type, variation } = props;

  if (type === CategoryIconType.MATERIAL_SYMBOLS) {
    return (
      <Icon
        baseClassName="material-symbols-outlined"
        color="success"
        fontSize={size || 'medium'}
      >
        {variation}
      </Icon>
    );
  } else if (type === CategoryIconType.PLAN_LIST) {
    return (
      <FormatListBulletedIcon color="success" fontSize={size || 'medium'} />
    );
  } else if (type === CategoryIconType.BEER_ICON) {
    return <SportsBarIcon color="success" fontSize={size || 'medium'} />;
  } else if (type === CategoryIconType.RICE_ICON) {
    return <RiceBowlIcon color="success" fontSize={size || 'medium'} />;
  } else if (type === CategoryIconType.RESTAURANT_ICON) {
    return <RestaurantIcon color="success" fontSize={size || 'medium'} />;
  } else if (type === CategoryIconType.BISTRO_ICON) {
    return <SoupKitchenIcon color="success" fontSize={size || 'medium'} />;
  } else if (type === CategoryIconType.ASIA_ICON) {
    return <KebabDiningIcon color="success" fontSize={size || 'medium'} />;
  } else if (type === CategoryIconType.NAIL_ICON) {
    return <BackHandIcon color="success" fontSize={size || 'medium'} />;
  } else if (type === CategoryIconType.SPA_ICON) {
    return <SpaIcon color="success" fontSize={size || 'medium'} />;
  } else if (type === CategoryIconType.HAIR_ICON) {
    return <Face3Icon color="success" fontSize={size || 'medium'} />;
  } else if (type === CategoryIconType.LOCAL_DINING_ICON) {
    return <LocalDiningIcon color="success" fontSize={size || 'medium'} />;
  } else if (type === CategoryIconType.STORE_ICON) {
    return <StoreIcon color="success" fontSize={size || 'medium'} />;
  } else if (type === CategoryIconType.MASSAGE_ICON) {
    return <HotelIcon color="success" fontSize={size || 'medium'} />;
  }
  return (
    <DynamicMaterialIcon
      variation={variation}
      color="success"
      fontSize={size || 'medium'}
    />
  );
}
