import { Box, Stack, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';

import { CardPartOrganization } from '@app/components/Product/CardPartOrganization';
import { CardPartTitle } from '@app/components/Product/CardPartTitle';
import { Product } from '@app/types/catalog';
import { unescapeHtml } from '@app/utils/pattern';

interface DetailInfoCardProps {
  product: Product;
}

export function DetailInfoCard({ product }: DetailInfoCardProps): ReactElement {
  const organizationInfo = useMemo(() => {
    return typeof product.organization === 'object'
      ? { ...product.organization }
      : null;
  }, [product.organization]);

  return (
    <Box>
      {product.images && product.images.length > 0 ? (
        <img
          alt="Product"
          src={product.images[0].url}
          style={{ width: '100%' }}
        />
      ) : organizationInfo?.iconImageUri ? (
        <img
          alt={organizationInfo.name}
          src={organizationInfo.iconImageUri}
          style={{ width: '100%' }}
        />
      ) : null}
      <Stack spacing={3} sx={{ p: 2 }}>
        {organizationInfo && (
          <CardPartOrganization organization={organizationInfo} />
        )}
        <Box>
          <Typography variant="h6" color="text">
            {unescapeHtml(product.name)}
          </Typography>
        </Box>
        {organizationInfo && <CardPartTitle product={product} />}
      </Stack>
    </Box>
  );
}
