import { Product, ProductVariant } from './catalog';
import { ItemPrice } from './ItemPrice';
import { Organization } from './organization';
import { User } from './user';

enum CancelReason {
  DECLINED = 'DECLINED',
  INVENTORY = 'INVENTORY',
  OTHER = 'OTHER',
  PENDING = 'PENDING',
}

export const OrderStatus = {
  ACCEPTED: 'ACCEPTED', //来店予定
  CANCELED: 'CANCELED', //不採用、キャンセル、辞退
  CLOSED: 'CLOSED', //投稿済み
  PENDING: 'PENDING', //予約時
  PROCESSING: 'PROCESSING', //投稿待ち
  WAITING: 'WAITING', //投稿確認待ち
};
export type OrderStatusKey = (typeof OrderStatus)[keyof typeof OrderStatus];

export enum OrderWorkResult {
  BAD = 'bad',
  GOOD = 'good',
}

export const OrderCancelType = {
  DEMAND_STATUS_ACCEPTED: 2, //インフルエンサーがACCEPTEDで辞退
  DEMAND_STATUS_PENDING: 1, //インフルエンサーがPENDINGで辞退
  NOT_CANCEL: 0,
  SUPPLY_STATUS_ACCEPTED: 3, //店舗がACCEPTEDでキャンセル
  SUPPLY_STATUS_NOT_ACCEPTED: 99, //店舗による不採用
  TIME_UP: 98, //時間切れによる自動不採用
} as const;
export type OrderCancelTypeKey =
  (typeof OrderCancelType)[keyof typeof OrderCancelType];

type Resource = {
  uri: string;
};
export type CartResource = Resource;
export type CheckoutResource = Resource;

export interface Order {
  cancelReason: CancelReason | null;
  canceledAt: Date | null;
  cart: CartResource | null;
  checkout: CheckoutResource | null;
  closedAt: Date | null;
  createdAt: Date;
  currency: string;
  customFields: OrderCustomFields | null;
  customer: Customer;
  id: string;
  lineItems: LineItem[];
  organization: Organization | string;
  status: string;
  subtotalPrice: number;
  totalPrice: number;
  totalTax: number;
  updatedAt: Date;
}

export interface OrderCustomFields {
  appointmentDate: string;
  appointmentTime: string;
  cancelMessage: string;
  cancelRequestAt: string;
  cancelType: OrderCancelTypeKey;
  peopleCount: number;
  productDay: string[];
  productType: string;
  snsUrl?: string;
  workResult: string;
  workResultAt: string;
}

export type Customer = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  age: string;
  email: string;
  name: string;
  nameKana: string;
  phone: string;
  preferredContactMethod: string;
  preferredTimeToContact: string;
  user: User | string | null;
};

export type LineItem = {
  price: ItemPrice;
  product: Product | string;
  productName: string;
  quantity: number;
  sku: string;
  variant: ProductVariant | string;
  variantTitle: string;
};

export interface OrderItemCreation {
  productId: string;
  quantity: number;
  variantId?: string;
}

export interface OrderCustomerCreation {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  age?: string;
  email: string;
  name: string;
  nameKana: string;
  phone: string;
  preferredContactMethod?: string;
  preferredTimeToContact?: string;
  user?: string;
}

export interface OrderCreation {
  customFields?: OrderCustomFields | null;
  customer: OrderCustomerCreation;
  items: OrderItemCreation[];
  status?: string;
}

export type OrderPageState = {
  organizationId?: string;
  product: Product;
};

export interface OrderUpdate {
  canceledAt?: Date;
  customFields?: OrderCustomFields | null;
  otherReason?: string;
  status?: string;
}
