import { Box, Container, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { getVerifyToken, verifyEmailToken } from '@app/domain/network-actions';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
  errorSnackbarSeverityState,
} from '@app/domain/top-nav';

export function WaitingForEmailVerify() {
  const navigate = useNavigate();
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setErrorSnackbarSeverity = useSetRecoilState(
    errorSnackbarSeverityState
  );
  const verifyRun = useRef(false);

  useEffect(() => {
    const token = getVerifyToken();
    if (_.isEmpty(token) || verifyRun.current) return;

    verifyEmailToken(token)
      .then((data) => {
        if (data.status === 204) {
          setErrorSnackbarSeverity('success');
          setErrorSnackbarText(
            'メールアドレスを認証しました。ログインしてください'
          );
          setErrorSnackbarOpen(true);
          navigate('/login');
        }
      })
      .catch((error) => {
        setErrorSnackbarSeverity('error');
        setErrorSnackbarText('メールアドレスの認証に失敗しました。');
        setErrorSnackbarOpen(true);
      });
    verifyRun.current = true;
  }, [
    navigate,
    setErrorSnackbarOpen,
    setErrorSnackbarSeverity,
    setErrorSnackbarText,
  ]);

  return (
    <Container>
      <Box px={1} py={4}>
        <Stack spacing={4}>
          <Typography variant="h5" fontWeight={700} textAlign="center">
            入力ありがとうございます。
          </Typography>
          <Typography>ご登録はまだ完了しておりません。</Typography>
          <Typography>
            ご登録いただいたメールアドレス宛に本登録手続き用メールを送信しました。
          </Typography>
          <Typography>
            メール本文に記述されているURLをクリックして頂き、本登録手続き画面より手続きを行なってください。
          </Typography>
        </Stack>
      </Box>
    </Container>
  );
}
