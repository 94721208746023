import {
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as _ from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { loggedInUserState } from '@app/domain/app';
import {
  Order,
  OrderCustomFields,
  OrderStatus,
  OrderUpdate,
  OrderWorkResult,
} from '@app/types/order';
import { SocialPlatforms, User, socialMediaDomains } from '@app/types/user';
import { isValidUrlFormat } from '@app/utils/user';

const workResults = [
  { icon: 'thumb_up', label: 'いいね', value: OrderWorkResult.GOOD },
  { icon: 'thumb_down', label: 'いまいち', value: OrderWorkResult.BAD },
] as const;

interface WorkResultModalProps {
  onClose: () => void;
  onSubmit?: (
    organizationId: string,
    orderId: string,
    payload: OrderUpdate,
    isFollow: boolean
  ) => void;
  open: boolean;
  order?: Order;
  snsUrl?: string;
}

interface FormErrors {
  [key: string]:
    | {
        message: string;
        type: string;
      }
    | undefined;
}

const validateSnsUrl = (
  snsUrls: string[],
  selectedPlatforms: SocialPlatforms[]
) => {
  const validDomains: string[] = selectedPlatforms.reduce((acc, platform) => {
    const domains = socialMediaDomains[platform] || [];
    return acc.concat(domains);
  }, [] as string[]);

  const isAllUrlsValid = snsUrls.every((url) =>
    validDomains.some((domain) => isValidUrlFormat(url, domain))
  );

  return (
    isAllUrlsValid ||
    '入力されたURLが選択したSNSのドメインと一致しないか、形式が正しくありません。'
  );
};

const getSocialPlatformKey = (value: string): SocialPlatforms | undefined => {
  const entries = Object.entries(SocialPlatforms).find(
    ([, val]) => val === value
  );
  return entries
    ? SocialPlatforms[entries[0] as keyof typeof SocialPlatforms]
    : undefined;
};

const resolveFormValues = (
  values: OrderUpdate,
  loggedInUser: User | null
): { errors: FormErrors; values: OrderUpdate } => {
  const errors: FormErrors = {};

  if (!values.customFields) {
    return { errors, values };
  }

  const snsUrls = values.customFields.snsUrl
    ? values.customFields.snsUrl.split(',').map((url) => url.trim())
    : [];
  const selectedPlatforms =
    loggedInUser?.customFields?.usedSocialMediaPlatforms || [];

  const selectedPlatformsEnum = selectedPlatforms
    .map((platform) => getSocialPlatformKey(platform))
    .filter((x): x is SocialPlatforms => x !== undefined);

  const validationMessage = validateSnsUrl(snsUrls, selectedPlatformsEnum);

  if (validationMessage !== true) {
    errors['customFields.snsUrl'] = {
      message: validationMessage,
      type: 'domainMatch',
    };
  } else if (snsUrls.length === 0) {
    errors['customFields.snsUrl'] = {
      message: 'SNSのURLは必須です',
      type: 'required',
    };
  }

  return { errors, values };
};

export function ReviewModals({
  onClose,
  onSubmit,
  open,
  order,
}: WorkResultModalProps): ReactElement {
  const theme = useTheme();
  const [isFollow, setIsFollow] = useState<boolean>(false);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const {
    control,
    formState,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
  } = useForm<OrderUpdate>({
    defaultValues: {
      customFields: {
        ...order?.customFields,
        snsUrl: '',
      },
      status: order?.status,
    },
    mode: 'onChange',
    resolver: async (values) => resolveFormValues(values, loggedInUser),
  });

  const snsUrl = watch('customFields.snsUrl');

  const selectedPlatforms = useMemo(() => {
    if (!loggedInUser) {
      return [];
    }
    return loggedInUser.customFields?.usedSocialMediaPlatforms || [];
  }, [loggedInUser]);

  useEffect(() => {
    const urls = snsUrl ? snsUrl.split(',').map((url) => url.trim()) : [];
    const selectedPlatformsEnum = selectedPlatforms.map(
      (platform) => SocialPlatforms[platform as keyof typeof SocialPlatforms]
    );

    const validationMessage = validateSnsUrl(urls, selectedPlatformsEnum);

    if (validationMessage !== true) {
      setError('customFields.snsUrl', {
        message: validationMessage as string,
        type: 'domainMatch',
      });
    } else {
      clearErrors('customFields.snsUrl');
    }
  }, [snsUrl, selectedPlatforms, setError, clearErrors]);

  const handleClose = useCallback((): void => {
    onClose();
    reset();
    setIsFollow(false);
  }, [onClose, reset]);

  const onFormSubmit = useCallback(
    async (data: OrderUpdate) => {
      const organizationId = _.get(order?.organization, 'id');
      const orderId = order?.id;
      if (!organizationId || !orderId) {
        return;
      }

      const payload: OrderUpdate = {
        customFields: {
          ...(data.customFields as OrderCustomFields),
          snsUrl: data.customFields?.snsUrl,
          workResultAt: new Date().toISOString(),
        },
        status: OrderStatus.WAITING,
      };

      if (onSubmit) {
        onSubmit(organizationId, orderId, payload, isFollow);
      }
    },
    [isFollow, onSubmit, order]
  );

  const resultIcon = (icon: string, value: string): ReactElement => {
    const workResult = watch('customFields.workResult');
    return (
      <IconButton
        sx={{
          backgroundColor:
            workResult === value
              ? 'primary.main'
              : theme.palette.neutral.greyLight,
          color:
            workResult === value
              ? theme.palette.neutral.greyLight
              : 'primary.main',
          height: 80,
          width: 80,
        }}
      >
        <Icon baseClassName="material-symbols-rounded" fontSize="large">
          {icon}
        </Icon>
      </IconButton>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Typography align="center">店舗を評価してください</Typography>
        <Stack spacing={1} sx={{ alignItems: 'center', mt: 2 }}>
          <Controller
            name="customFields.workResult"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <RadioGroup
                {...field}
                sx={{ flexDirection: 'row' }}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {workResults.map(({ label, value, icon }, index) => (
                  <FormControlLabel
                    key={index}
                    value={value}
                    control={
                      <Radio
                        icon={resultIcon(icon, value)}
                        checkedIcon={resultIcon(icon, value)}
                        checked={field.value === value}
                      />
                    }
                    label={
                      <Typography sx={{ fontWeight: 500 }}>{label}</Typography>
                    }
                    labelPlacement="bottom"
                  />
                ))}
              </RadioGroup>
            )}
          />
          <Controller
            name="customFields.snsUrl"
            control={control}
            rules={{ required: 'SNSのURLは必須です' }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                placeholder="投稿したSNSのURLを入力"
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
          <Button
            color="black"
            variant="contained"
            fullWidth
            disabled={!formState.isValid}
            onClick={handleSubmit(onFormSubmit)}
          >
            送信
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
